<template>
  <div>
    <el-form :model="step2ruform" :rules="rules" label-width="150px" ref="step2form">
      <div>
					<el-form-item label="目标推送客户：">
						<el-select :disabled="disabled" v-model="step2ruform.TargetMemberType" placeholder="请选择一个目标"
							style="margin:0px 5px;width:300px" @change="changeTargetMemberType">
							<el-option v-for="v in customerlist" :key="v.value" :value="v.value" :label="v.label"></el-option>
              <el-option v-if="allconfigmsg.AutoMarketingTaskIsOpenTagSelect" :value="5" label="按客户标签选择"></el-option>
              <el-option v-if="allconfigmsg.AutoMarketingTaskIsOpenMeetConditions&&step2ruform.PushRuleType!=3" :value="6" label="满足指定条件的客户（下单次数、金额等条件）"></el-option>
						</el-select>
					</el-form-item>
					<div v-if="step2ruform.TargetMemberType==2">
						<el-form-item label="会员身份选择：">
							<el-radio-group :disabled="disabled" v-model="ismember" @change="chooseismember">
								<el-radio :label="true">会员</el-radio>
								<el-radio :label="false">非会员</el-radio>
							</el-radio-group>
							<div v-if="ismember">
								<div class="grayfont">若未选中任一会员级别，系统默认推送给全部会员</div>
								<el-checkbox-group :disabled="disabled" v-model="choosememberlist" @change="checkmember">
									<el-checkbox v-for="v in allmemberlist" :key="v.Id" :label="v.Id">{{v.VipRoleName}}
									</el-checkbox>
								</el-checkbox-group>
							</div>
						</el-form-item>
					</div>
					<div v-if="step2ruform.TargetMemberType==3">
						<el-form-item label="推手身份选择：">
							<el-radio-group :disabled="disabled" v-model="isdriver" @change="choosedriver">
								<el-radio :label="true">推手</el-radio>
								<el-radio :label="false">非推手</el-radio>
							</el-radio-group>
							<div v-if="isdriver">
								<div class="grayfont">若未选中任一推手级别，系统默认推送给全部推手</div>
								<el-checkbox-group :disabled="disabled" v-model="choosedriverlist" @change="checkdriver">
									<el-checkbox v-for="v in alldriverlist" :key="v.Id" :label="v.Id">
										{{v.DistributRoleName}}</el-checkbox>
								</el-checkbox-group>
							</div>
						</el-form-item>
					</div>
					<div v-if="step2ruform.TargetMemberType==4">
						<el-form-item label="新老客户选择：">
							<el-radio-group :disabled="disabled" v-model="isnew" @change="changeisnew">
								<el-radio :label="true">新客户</el-radio>
								<el-radio :label="false">老客户</el-radio>
							</el-radio-group>
							<div class="grayfont">
								新客户：从商城创建至今，<span style="color:#f56c6c">从未</span>在商城内支付成功过订单的客户
							</div>
							<div class="grayfont">
								老客户：从商城创建至今，<span style="color:#f56c6c">至少</span>在商城内支付成功过<span
									style="color:#f56c6c">一次</span>订单的客户
							</div>
						</el-form-item>
					</div>
					<div v-if="step2ruform.TargetMemberType==5">
						<el-form-item label="选择标签："
							:prop="'AutoMarketingTaskConfigDetailList.'+0+'.AutoMarketingTaskConfigDetailRelationIdList'"
							:rules="newrules.selected">
							<div class="flexRow" style="align-items:flex-start">
								<div class="flexRow" style="max-width:70%;flex-wrap:wrap;margin-top:5px">
									<el-tag v-for="(v,i) in selected" :key="v.TagDetailId" 
										style="margin:0px 5px 5px 5px" :closable="!disabled" @close="closetagitem(i)">{{v.Name}}
									</el-tag>
								  <el-button type="text" style="margin-left:10px" @click="dialogTag=true" v-if="!disabled">选择标签</el-button>
								</div>
							</div>
						</el-form-item>
						<el-form-item label="匹配规则：">
							<el-radio-group :disabled="disabled" v-model="step2ruform.AutoMarketingTaskConfigDetailList[0].IsMatchAll">
								<el-radio :label="false">包含上述任一标签即可</el-radio>
								<el-radio :label="true">包含上述所有标签</el-radio>
							</el-radio-group>
						</el-form-item>
					</div>
					<div v-if="step2ruform.TargetMemberType==6">
						<el-form-item label="条件设置：">
							<el-link href="https://jusnn6k8al.feishu.cn/docs/doccn7iDYkIyvAEho58bBt4tk9g" target="_blank" style="line-height:1.5"
               type="primary">查看示例</el-link>
						</el-form-item>
						<div class="tag-wraper" v-if="tagshow">
							<tag ref="tagMod" :disabled="disabled" :defaultList="step2ruform.AutoMarketingTaskConfigDetailList"></tag>
						</div>
					</div>
				</div>
    </el-form>

    <tagging :dialogTag="dialogTag" :selected="selected" @close="dialogTag=false" @confirm="confirmtagging"
			:tagkey="'TagDetailId'" title="选择标签"></tagging>
  </div>
</template>

<script>
import tagging from "@/views/Customer/components/tagging"
import tag from "@/views/Customer/autoAddTag/components/tag"
import {getTemplateParam} from '@/api/sv1.0.0.js';
export default {
  components: {
    tagging,
    tag
  },
  inject:['memberlist','driverlist','configmsg'],
  props: {
    ruform:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    rules:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    disabled:{
      type:Boolean,
      default:false,
    }
  },
  watch:{
    ruform(){
      this.step2ruform = JSON.parse(JSON.stringify(this.ruform))
      // console.log(this.step2ruform.AutoMarketingTaskConfigDetailList)
      if(this.$route.query.Id){
        this.Initialize().then((res)=>{
           this.tagshow = true
          //  console.log(this.tagshow,12)
         })
      }else{
        this.tagshow = true
      }
    },
    'ruform.PushRuleType'(val){//第三步选择客户行为触发时 不展示满足指定客户的选项
      this.step2ruform.PushRuleType = val
      // console.log(this.step2ruform.PushRuleType)
      // if(val==3){
      //   this.customerlist = this.allcustomerlist.filter((v)=>{
      //     return v.value!=6
      //   })
      // }else{
      //   this.customerlist = this.allcustomerlist
      // }
      // console.log(this.customerlist)
    },
  },
  created(){

  },
  beforeMount () {
    // console.log(this.allconfigmsg)
    this.step2ruform = JSON.parse(JSON.stringify(this.ruform))
    this.customerlist = this.allcustomerlist
    if(this.$route.query.templateId||this.$route.params.TagDetailId){
      // setTimeout(()=>{
         this.Initialize().then((res)=>{
      // console.log(this.step2ruform.TargetMemberType,'TargetMemberType ')
          //  console.log(this.step2ruform.AutoMarketingTaskConfigDetailList)
           this.tagshow = true
          //  console.log(this.tagshow,12)
         })
      // },500)
    }else{
      this.tagshow = true
    }
  },
  computed: {
    allmemberlist(){
      return this.memberlist()
    },
    alldriverlist(){
      return this.driverlist()
    },
    allconfigmsg(){
      return this.configmsg()
    },
  },
  data () {
    return {
      step2ruform:{},
      ismember:true,
      isdriver:true,
      isnew:true,
      choosememberlist: [],
      choosedriverlist:[],
      customerlist:[],
      allcustomerlist: [
        {
						value: 1,
						label: '全部客户'
					},
					{
						value: 2,
						label: '按会员身份选择（会员、非会员）'
					},
					{
						value: 3,
						label: '按推手身份选择（推手、非推手）'
					},
					{
						value: 4,
						label: '按新老客户选择（新客户、老客户）'
					},
					// {
					// 	value: 5,
					// 	label: '按客户标签选择'
					// },
					// {
					// 	value: 6,
					// 	label: '满足指定条件的客户（下单次数、金额等条件）'
					// },
			],

      dialogTag:false,
      selected: [],

      newrules:{
        selected:[{
						required: true,
						message: '请选择标签',
						trigger: 'blur'
					}, ],
      },

      tagshow:false
    }
  },
  methods: {
    Initialize(){
      // console.log(this.step2ruform.TargetMemberType,'TargetMemberType ')
      return new Promise((res,rej)=>{
        if(this.step2ruform.TargetMemberType==2){
          this.choosememberlist = this.step2ruform.AutoMarketingTaskConfigDetailList[0].AutoMarketingTaskConfigDetailRelationIdList.map((v)=>{
            return v.RelationId
          })
          // console.log(this.choosememberlist,'123')
          let idlist = this.allmemberlist.map((v)=>{
            return v.Id
          })
          if(this.$route.query.templateId==8){
            this.choosememberlist = idlist.filter((v)=>{
              return v!=0
            })
          }
          if(this.choosememberlist[0]==0){
            this.ismember = false
            this.choosememberlist = []
          }else{
            this.ismember = true
          }
        }else if(this.step2ruform.TargetMemberType==3){
          this.choosedriverlist = this.step2ruform.AutoMarketingTaskConfigDetailList[0].AutoMarketingTaskConfigDetailRelationIdList.map((v)=>{
            return v.RelationId
          })
          let idlist = this.alldriverlist.map((v)=>{
            return v.Id
          })
          if(this.$route.query.templateId==17){//除了非推手和最高等级的推手不选
            if(window.localStorage.getItem('mlmzDistributionModel')==1){//代理模式 id越低等级越高
              this.choosedriverlist = idlist.filter((x)=>{
                return x!=0 && x!=Math.min(...idlist)
              })
            }else{
              this.choosedriverlist = idlist.filter((x)=>{
                return x!=0 && x!=Math.max(...idlist)
              })
            }
          }else if(this.$route.query.templateId==18){//除了非推手不选
            this.choosedriverlist = idlist.filter((x)=>{
              return x!=0
            })
          }

          if(this.choosedriverlist[0]==0){
            this.isdriver = false
            this.choosedriverlist = []
          }else{
            this.isdriver = true
          }
        }else if(this.step2ruform.TargetMemberType==4){
          this.isnew = this.step2ruform.AutoMarketingTaskConfigDetailList[0].AutoMarketingTaskConfigDetailRelationIdList[0].RelationId==1?false:true
        }else if(this.step2ruform.TargetMemberType==5 && this.step2ruform.AutoMarketingTaskConfigDetailList[0].AutoMarketingTaskConfigDetailRelationIdList.length){
          this.selected = this.step2ruform.AutoMarketingTaskConfigDetailList[0].AutoMarketingTaskConfigDetailRelationIdList.map((v)=>{
            v.TagDetailId = Number(v.RelationId)
            v.Name = v.RelationValue
            return v
          })
          // console.log(this.selected,66)
        }else if(this.step2ruform.TargetMemberType==6){
          if(this.$route.query.templateId){
            //

          }else{
            this.step2ruform.AutoMarketingTaskConfigDetailList = this.step2ruform.AutoMarketingTaskConfigDetailList.map((v)=>{
              v.TagRuleConfigId = v.AutoMarketingTaskConfigId
              v.TagRuleConfigDetailRelationIdList = v.AutoMarketingTaskConfigDetailRelationIdList
              return v
            }) 
          }
          // console.log(this.step2ruform.AutoMarketingTaskConfigDetailList)
        }
        res()
      })
    },
    async validation(){
      return new Promise((res,rej)=>{
        this.$refs['step2form'].validate((valid) => {
          if (valid) {
            let list = []
            if(this.step2ruform.TargetMemberType==6){
              // list.push(this.$refs.tagMod.validForm())
              this.$refs.tagMod.validForm().then((suc)=>{
                // console.log(suc)
                if(!suc||!suc.length){
                  rej('请先完善第2步任务基本信息的配置')
                }else{
                  // console.log(suc)
                  this.step2ruform.AutoMarketingTaskConfigDetailList = suc.map((v)=>{
                    v.AutoMarketingTaskConfigId = v.TagRuleConfigId
                    v.AutoMarketingTaskConfigDetailRelationIdList = v.TagRuleConfigDetailRelationIdList
                    if(v.EndTime){
                      v.EndTime = this.formatNextDate(v.EndTime.split(' ')[0])
                    }
                    return v
                  })
                  // console.log(this.step2ruform.AutoMarketingTaskConfigDetailList,'111')
                  res(this.step2ruform)
                }
              }).catch((err)=>{
                console.log(err)
                rej('请先完善第2步任务基本信息的配置')
              })
            }else{
              this.step2ruform.AutoMarketingTaskConfigDetailList = this.getAutoMarketingTaskConfigDetailList()
              res(this.step2ruform)
            }
          } else {
            rej('请先完善第2步任务基本信息的配置')
            // rej(false)
          }
        });
      })
    },
    getAutoMarketingTaskConfigDetailList(){
      
      if (this.step2ruform.TargetMemberType === 1) return [];
      
      //按会员身份
      if (this.step2ruform.TargetMemberType === 2){
        
        var list = []
        if (this.ismember){
          list = this.choosememberlist.map(item=>{
            return {
              RelationId: item
            }
          });
          if(!list.length){
            list = [{RelationId: -1}]
          }
        }else{
          list = [{RelationId: 0}];
        }
        
        return [{
          AutoMarketingTaskConfigId: 0,
          Type: 2,
          AutoMarketingTaskConfigDetailRelationIdList: list
        }]
      }
      
      //按推手身份
      if (this.step2ruform.TargetMemberType === 3){
        
        var list = []
        if (this.isdriver){
          list = this.choosedriverlist.map(item=>{
            return {
              RelationId: item
            }
          });
          if(!list.length){
            list = [{RelationId: -1}]
          }
        }else{
          list = [{RelationId: 0}];
        }
        
        return [{
          AutoMarketingTaskConfigId: 0,
          Type: 3,
          AutoMarketingTaskConfigDetailRelationIdList: list
        }]
      }
      
      //按新老客户
      if (this.step2ruform.TargetMemberType === 4){
        
        var list = []
        if (this.isnew){
          list = [{RelationId: 0}];
        }else{
          list = [{RelationId: 1}];
        }
        
        return [{
          AutoMarketingTaskConfigId: 0,
          Type: 100,
          AutoMarketingTaskConfigDetailRelationIdList: list
        }]
      }
      
      //按客户标签
      if (this.step2ruform.TargetMemberType === 5){
        
        var list = this.selected.map(item=>{
          return {
            RelationId: item.TagDetailId
          }
        })
        
        return [{
          AutoMarketingTaskConfigId: 0,
          Type: 600,
          IsMatchAll: this.step2ruform.AutoMarketingTaskConfigDetailList[0].IsMatchAll,
          AutoMarketingTaskConfigDetailRelationIdList: list
        }]
      }
      
    },
    getStartTime(){
      //推送规则是：定时仅推送一次； 并且时间类型是：定时推送
      if (this.ruform.PushRuleType === 1 && this.ruform.AutoMarketingTaskConfigPushRuleOnce.PushTimeType === 1){
        return this.ruform.AutoMarketingTaskConfigDetailList[0].StartTime;
      }
      if (this.ruform.PushRuleType === 2 || this.ruform.PushRuleType === 3){
        return this.daterange[0]
      }
      
      return ''
    },
    getEndTime(){
      var time = ''
      if (this.ruform.PushRuleType === 2 || this.ruform.PushRuleType === 3){
        time = this.daterange[1];
      }
      if (time){
        return this.formatNextDate(time.split(' ')[0])
      }
      return ''
    },
    //切换目标推送客户
    changeTargetMemberType(val) {
      if(val!=6){
        this.step2ruform.AutoMarketingTaskConfigDetailList = [{
          AutoMarketingTaskConfigId:0,
          Type:'',
          StartTime:'',
          EndTime:'',
          TimeType:0,
          DynamicTimeType:1,
          ConditionType:1,
          Num:'',
          MaxNum:'',
          OverDayNum:'',
          ProductRangeType:0,
          PageRangeType:0,
          ActivityBehaviorType:0,
          IsMatchAll:false,
          AutoMarketingTaskConfigDetailRelationIdList:[],
        }]
      }else{
        this.step2ruform.AutoMarketingTaskConfigDetailList = []
      }
      this.ismember = true
      this.choosememberlist = []
      this.isdriver = true
      this.choosedriverlist = []
      this.isnew = true
      this.selected = []
      // this.isalltag = false
      if (val == 1) {
        this.step2ruform.AutoMarketingTaskConfigDetailList[0].Type = ''
      } else if (val == 2) {
        this.step2ruform.AutoMarketingTaskConfigDetailList[0].Type = 2
      } else if (val == 3) {
        this.step2ruform.AutoMarketingTaskConfigDetailList[0].Type = 3
      } else if (val == 4) {
        this.step2ruform.AutoMarketingTaskConfigDetailList[0].Type = 100
      } else if (val == 5) {
        this.step2ruform.AutoMarketingTaskConfigDetailList[0].Type = 600
        // if(!this.allconfigmsg.AutoMarketingTaskIsOpenTagSelect){
        //   this.step2ruform.TargetMemberType=1
        //   window.open('https://jusnn6k8al.feishu.cn/docs/doccn6jFh0hOBZG3eyO99wQ9QKf')
        // }
      } else if (val == 6) {
        // if(!this.allconfigmsg.AutoMarketingTaskIsOpenMeetConditions){
        //   this.step2ruform.TargetMemberType=1
        //   // this.$emit('changeTargetMemberType',this.step2ruform.TargetMemberType)
        //   window.open('https://jusnn6k8al.feishu.cn/docs/doccn9rVHdjbLV6u1EMLBrgFdvd')
        // }
      }
      // console.log(this.step2ruform.TargetMemberType,'123')
      this.$emit('changeTargetMemberType',this.step2ruform.TargetMemberType)
      this.$refs.step2form.clearValidate()
    },
    //选中非会员清空会员选项
    chooseismember(val) {
      if (!val) {
        this.step2ruform.AutoMarketingTaskConfigDetailList[0].AutoMarketingTaskConfigDetailRelationIdList = []
        this.step2ruform.AutoMarketingTaskConfigDetailList[0].AutoMarketingTaskConfigDetailRelationIdList.push({
          RelationId: 0
        })
        this.choosememberlist = []
      }
    },
    //选中会员 赋值给ruform
    checkmember(val) {
      this.step2ruform.AutoMarketingTaskConfigDetailList[0].AutoMarketingTaskConfigDetailRelationIdList = val.map((
        v) => {
          let data = {
            RelationId: v
          }
          return data
        })
    },
    //选择新老客户
    changeisnew(val) {
      this.step2ruform.AutoMarketingTaskConfigDetailList[0].AutoMarketingTaskConfigDetailRelationIdList = []
      this.step2ruform.AutoMarketingTaskConfigDetailList[0].AutoMarketingTaskConfigDetailRelationIdList.push({
        RelationId: val ? 0 : 1
      })
    },
    //选中非推手清空推手选项
    choosedriver(val) {
      if (!val) {
        this.step2ruform.AutoMarketingTaskConfigDetailList[0].AutoMarketingTaskConfigDetailRelationIdList = []
        this.step2ruform.AutoMarketingTaskConfigDetailList[0].AutoMarketingTaskConfigDetailRelationIdList.push({
          RelationId: 0
        })
        this.choosedriverlist = []
      }
    },
    //选中推手 赋值给ruform
    checkdriver(val) {
      this.step2ruform.AutoMarketingTaskConfigDetailList[0].AutoMarketingTaskConfigDetailRelationIdList = val.map((
        v) => {
          let data = {
            RelationId: v
          }
          return data
        })
    },
    //选择标签 确定
    confirmtagging(list) {
      // console.log(list)
      this.dialogTag = false
      this.selected = list
      this.step2ruform.AutoMarketingTaskConfigDetailList[0].AutoMarketingTaskConfigDetailRelationIdList = list.map((v) => {
        let data = {
          RelationId: v.TagDetailId,
          RelationValue: v.Name
        }
        return data
      })
    },
    //删除标签
    closetagitem(i) {
      // console.log(this.step2ruform.AutoMarketingTaskConfigDetailList[0].AutoMarketingTaskConfigDetailRelationIdList,i)
      this.selected.splice(i,1)
      this.step2ruform.AutoMarketingTaskConfigDetailList[0].AutoMarketingTaskConfigDetailRelationIdList = this.selected
      // this.step2ruform.AutoMarketingTaskConfigDetailList[0].AutoMarketingTaskConfigDetailRelationIdList.splice(i, 1)
      // this.selected = 
    },



    
    getgetTemplateParam(){
      return new Promise(async (res,rej)=>{
        try{
          let type = 0
          if(this.$route.query.templateId == 7){
            type = 1
          }else if(this.$route.query.templateId == 9){
            type = 2
          }else if(this.$route.query.templateId == 17){
            type = 3
          }
          // console.log(type)
          let result = await getTemplateParam({
            TemplateType:type
          })
          res(result)
        }catch{
          rej()
        }
        
      })  
    },
  }
}
</script>

<style lang = "less" scoped>
  .grayfont {
		font-size: 12px;
		color: #999999;
		line-height: 1.5;
	}
  .flexRow{
    display: flex;
    align-items: center;
  }
  .tag-wraper {
		height: 600px;
		display: flex;
		margin-left: 70px;
	}
</style>