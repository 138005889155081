<template>
  <div>
    <el-form :model="step4ruform" :rules="rules" ref="step4ruform" label-width="150px">
      <div>
					<div>
						<div class="flexRow" style="margin:0px 0px 10px 42px">
							<div class="bluebeginning"></div>
							<div style="margin-left:5px">推送权益</div>
						</div>
						<el-form-item :key="'couponslistbox'" label="赠送优惠券：">
							<el-form-item label-width="0px" prop="couponslist"	:rules="newrules.couponslist" :key="'couponslist'">
								<el-switch :disabled="disabled" v-model="step4ruform.IsOpenGiftCoupon"></el-switch>
								<el-button v-if="step4ruform.IsOpenGiftCoupon&&!disabled" type="text" style="margin-left:10px"
									@click="couponsdialogshow=true">选择优惠券</el-button>
								<div>
									<div class="grayfont">1.给目标推送客户同时赠送优惠券</div>
									<div class="grayfont">2.若优惠券设置领取次数限制，客户达到限制次数后将不再发放</div>
									<div class="grayfont">3.若优惠券剩余数量小于赠送数量，优惠券将赠送失败。请关注优惠券剩余数量并及时补充。</div>
								</div>
							</el-form-item>
							<div style="margin-top:20px" v-if="step4ruform.IsOpenGiftCoupon">
								<couponstable :disabled="disabled" ref="couponstable" :list="step4ruform.couponslist" @delshop="delshop"></couponstable>
							</div>
						</el-form-item>
						<el-form-item label="优惠券赠送方式：" v-if="step4ruform.IsOpenGiftCoupon">
							<el-radio :disabled="disabled" v-model="step4ruform.GiftCouponWay" :label="1">直接发放到客户的账户中，无需客户领取</el-radio>
							<br>
							<!-- <el-radio :disabled="disabled" v-model="step4ruform.GiftCouponWay" :label="2">客户进入商城手动领取</el-radio> -->
						</el-form-item>
						<el-form-item label="赠送抽奖次数：">
							<el-form-item label-width="0px" prop="activelist" :rules="newrules.activelist">
								<el-switch :disabled="disabled" v-model="step4ruform.IsOpenGiftWheelSurf"></el-switch>
								<el-button v-if="step4ruform.IsOpenGiftWheelSurf&&!disabled" type="text" style="margin-left:10px"
									@click="selectactiveDialog=true">{{step4ruform.activelist&&step4ruform.activelist.length?'重新':''}}选择抽奖活动</el-button>
								<div>
									<div class="grayfont">1.给目标推送客户同时赠送抽奖次数</div>
									<div class="grayfont">2.每个自动营销任务，1个客户仅赠送1遍</div>
								</div>
							</el-form-item>
							<div style="margin-top:20px;" v-if="step4ruform.IsOpenGiftWheelSurf">
								<activityTable :disabled="disabled" style="margin-left:-20px" ref="activityTable" :GiftdetailData="step4ruform.activelist"></activityTable>
							</div>
						</el-form-item>
					</div>
					<div style="margin-left:50px">
						<div class="flexRow">
							<div class="bluebeginning"></div>
							<div style="margin-left:5px">推送渠道和内容</div>
						</div>
            <el-form-item label-width="0px" prop="AutoMarketingTaskConfigMessageList" :rules="rules.channel">
              <div class="grayfont" style="margin:10px 0px">推送渠道可勾选多个，为避免过度营销，给客户造成打扰，请尽可能减少多个渠道同时推送的频次</div>
            </el-form-item>
						<div class="pushnotification">
							<div class="graybox">
								<el-checkbox :disabled="!allconfigmsg.IsInstallQidianHelper||disabled" v-model="isuseqywx"></el-checkbox>
								<div style="width:100%">
									<div class="titfont">企业微信</div>
									<div class="grayfont" style="margin:5px 0px 0px 10px;height:50px;">
										给员工创建企业微信客户群发任务，接收到任务的员工可在企业微信端点击一键发送，发送给目标推送客户；根据企业微信规定，客户每天可收到1条不同员工发送的群发消息
										<span style="color:#409eff;cursor: pointer;" @click="jumpother(2)">
											点击查看企业群发规则
										</span>
									</div>
									<div v-if="allconfigmsg.IsInstallQidianHelper">
										<div class="configurationbox" v-if="!qywx.Content " @click="editqywx">
											<i class="iconsty el-icon-plus"></i>
											<div class="iconfont">配置客户群发内容</div>
										</div>
										<div v-else>
											<div class="haveqywxhtml">
												<div class="confont" style="padding:10px;">
													{{qywx.Content}}
												</div>
												<div class="flexRow haveqywxlist"	v-if="qywx.QyWeixinAttachmentInfoList&&qywx.QyWeixinAttachmentInfoList.length">
													<span	v-if="qywx.QyWeixinAttachmentInfoList.some((v)=>{return v.Type==1})">【图片】</span>
													<span v-if="qywx.QyWeixinAttachmentInfoList.some((v)=>{return v.Type==2})">【链接】</span>
													<span	v-if="qywx.QyWeixinAttachmentInfoList.some((v)=>{return v.Type==3})">【小程序】</span>
												</div>
											</div>
											<div style="text-align:right">
												<el-button :disabled="disabled&&step4ruform.State!=1" type="text" @click="qywxvisible=true">编辑</el-button>
											</div>
										</div>
									</div>
									<div class="configurationbox" v-else>
										<div class="iconfont">商城还未安装企店助手，无法使用该渠道</div>
										<el-button type="text" @click="$router.push({path:'/saleChannel/qyWechat'})">去安装</el-button>
										<div class="grayfont">若你已安装，<el-button type="text" @click="init">点击此处刷新数据</el-button>
										</div>
									</div>
								</div>
							</div>
							<div class="graybox" style="height:100%">
								<el-checkbox :disabled="(allconfigmsg.SMSCount<=0&&!sms.Content)||disabled" v-model="isusesms"></el-checkbox>
								<div>
									<div class="titfont">短信</div>
									<div class="grayfont" style="margin:5px 0px 0px 10px;height:50px">
										使用短信通知，将扣除商城短信余额，余额不足以扣费时，将不再继续推送。(当前短信剩余可发放数量：
                    <span style="color:#f56c6c">{{allconfigmsg.SMSCount}}条</span>)。
										<span style="color:#409eff;cursor: pointer;" @click="jumpother(3)">
											点击查看短信群发内容规则
										</span>
									</div>
									<div v-if="sms.Content">
										<div>
											<div class="haveqywxhtml">
												<div class="confontsmshtml" style="padding:10px">
													{{sms.HeadSign}}{{sms.Content}}{{sms.Tail}}
												</div>
											</div>
											<div style="text-align:right">
												<el-button :disabled="disabled&&step4ruform.State!=1" type="text" @click="smsvisible=true">编辑</el-button>
											</div>
										</div>
									</div>
                  <div v-else>
                    <div class="configurationbox"  v-if="allconfigmsg.SMSCount<=0">
                      <div class="iconfont">当前商城剩余短信0条，请先充值短信包 </div>
                      <el-button type="text" @click="$router.push({path:'/smsaccount/smsaccountindex'})">去充值</el-button>
                      <div class="grayfont">若你已安装，<el-button type="text" @click="init">点击此处刷新数据</el-button>
                      </div>
                    </div>
                    <div class="configurationbox"  v-else @click="editsms">
                      <i class="iconsty el-icon-plus"></i>
                      <div class="iconfont">配置短信内容</div>
                    </div>
                  </div>
								</div>
							</div>
              <div class="graybox" style="height:100%">
								<el-checkbox :disabled="(program&&String(program.MicroProgramType)!='0')||disabled" v-model="isusewsapp"></el-checkbox>
								<div>
									<div class="titfont">小程序订阅消息</div>
									<div class="grayfont" style="margin:5px 0px 0px 10px;height:50px">
										客户需要订阅消息，才可给对应客户推送内容；消息按次订阅。
										<span style="color:#409eff;cursor: pointer;" @click="jumpother(4)">
											点击查看《小程序模板消息能力调整通知》
										</span>
									</div>
									<div v-if="program&&String(program.MicroProgramType)=='0'">
										<div>
											<div class="haveqywxhtml" style="background:rgb(251,253,255)">
                        <div style="padding:16px">
                          <div style="programtitle">活动开始提醒</div>
                          <div class="programmsg">
                            活动名称：{{wxapp.WxAppSubscribeMessageAttachInfo&&wxapp.WxAppSubscribeMessageAttachInfo.FirstText}}
                          </div>
                          <div class="programmsg">
                            开始时间：{{wxapp.WxAppSubscribeMessageAttachInfo&&wxapp.WxAppSubscribeMessageAttachInfo.SecondText}}
                          </div>
                          <div class="programmsg">
                            温馨提示：{{wxapp.WxAppSubscribeMessageAttachInfo&&wxapp.WxAppSubscribeMessageAttachInfo.ThirdText}}
                          </div>
                        </div>
                        <div class="line"></div>
                        <div class="haveqywxhtml" style="background:rgb(251,253,255);padding:10px 16px;margin:0px;border:none">
                          <div class="programmsg" style="margin:0px">
                            通知链接至：{{wxapp.WxAppSubscribeMessageAttachInfo&&wxapp.WxAppSubscribeMessageAttachInfo.LinkName}}
                          </div>
                        </div>
											</div>
											<div style="text-align:right">
												<el-button :disabled="disabled&&step4ruform.State!=1" type="text" @click="wxappEdit">编辑</el-button>
											</div>
										</div>
									</div>
                  <div v-else>
                    <div class="configurationbox">
                      <div class="iconfont">商城还未绑定小程序，无法使用该通知 </div>
                      <el-button type="text" @click="$router.push({path:'/setting/wxAppManage'})">去绑定</el-button>
                      <div class="grayfont">若你已绑定，<el-button type="text" @click="getDataList">点击此处刷新数据</el-button>
                      </div>
                    </div>
                  </div>
								</div>
							</div>
						</div>
					</div>
				</div>
    </el-form>

    <!-- 选择优惠券 -->
		<couponsdialog :visible.sync="couponsdialogshow" @confirm="getcouponslist" @close="couponsdialogshow = false"
			:thelist="step4ruform.couponslist">
    </couponsdialog>

    <el-dialog title="选择活动" :visible.sync="selectactiveDialog" width="1100px" class="dialog">
			<turntableActivities :visible.sync="selectactiveDialog"
				:checkShowId="(step4ruform.activelist&&step4ruform.activelist.length)?step4ruform.activelist[0].RelationId:0"
				:selectActivedata="step4ruform.activelist" @getActivetList="getActivetList" v-if="selectactiveDialog">
			</turntableActivities>
		</el-dialog>

    <!-- 企业微信群发 -->
		<qywxconfiguration v-if="qywxvisible" :qywxvisible="qywxvisible" @closeqywx="qywxvisible=false"
			@confirmhtml="confirmhtml" :List="qywx.QyWeixinAttachmentInfoList" :Welcome="qywx.Content">
    </qywxconfiguration>

		<!-- 短信 -->
		<smsconfiguration v-if="smsvisible" :smsvisible="smsvisible" @closesms="smsvisible=false"
			@confirmsms="confirmsms" :Welcome="sms">
    </smsconfiguration>

    <!-- 小程序订阅 -->
    <program v-if="wxappShow" :wxappShow="wxappShow" @close="wxappShow=false" @confirm="confirmwxapp" :msg="wxapp"
      :program="program">
    </program>
  </div>
</template>

<script>
import {
  autoMarketingTaskConfiginit
} from "@/api/sv3.0.0"
import apiList from '@/api/other';
import {
	mallFreeVersionConfigInfo
} from "@/api/goods"

import couponstable from "../couponstable"
import couponsdialog from "../couponsdialog"
import activityTable from '../activityTable.vue';
import turntableActivities from '../turntableActivities.vue';
import qywxconfiguration from "../qywxconfiguration/qywxconfiguration"
import smsconfiguration from "../smsconfiguration/smsconfiguration"
import program from "../program/program"
import config from "@/config"
export default {
  components: {
    couponstable,
    couponsdialog,
    activityTable,
    turntableActivities,
    qywxconfiguration,
    smsconfiguration,
    program
  },
  inject:['group','brand','memberlist','driverlist','configmsg'],
  props: {
    ruform:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    rules:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    disabled:{
      type:Boolean,
      default:false,
    },
  },
  watch:{
    ruform(){
      this.step4ruform = JSON.parse(JSON.stringify(this.ruform))
      this.getwsappInfo()
      // console.log(this.$route.query)
      // if(this.$route.query.Id){
        this.Initialize()
      // }
    },
  },
  created () {
    this.step4ruform = JSON.parse(JSON.stringify(this.ruform))
    this.getwsappInfo()
    if(this.$route.query.templateId){
      this.Initialize()
    }
  },
  data () {
    var checkcouponslist = (rule, value, callback) => {
      // console.log(this.step4ruform.couponslist,11)
      if(this.step4ruform.IsOpenGiftCoupon){
        if(!this.step4ruform.couponslist || !this.step4ruform.couponslist.length){
          callback(new Error('请选择赠送的优惠券'))
        }else{
          callback()
        }
      }else{
        callback()
      }
    };
    var checkcactivelist = (rule, value, callback) => {
      // console.log(value,11)
      if(this.step4ruform.IsOpenGiftWheelSurf){
        if(!value || !value.length){
          callback(new Error('请选择抽奖活动'))
        }else{
          callback()
        }
      }else{
        callback()
      }
    };
    return {
      step4ruform:{},
      couponsdialogshow:false,
      selectactiveDialog:false,

      isuseqywx:false,
      isusesms:false,
      isusewsapp:false,

      qywxvisible:false,
      smsvisible:false,

      newrules:{
        couponslist:[{
          required: true,
          validator: checkcouponslist,
          trigger: 'blur'
        }],
        activelist:[{
          required: true,
          validator: checkcactivelist,
          trigger: 'blur'
        },],
      },
      state:null,
      goUrls: config.GO_URL,
      program:{},
      wxappShow:false,
    }
  },
  computed: {
    allgroupList(){
      return this.group()
    },
    allbrandList(){
      return this.brand()
    },
    allmemberlist(){
      return this.memberlist()
    },
    alldriverlist(){
      return this.driverlist()
    },
    allconfigmsg(){
      // console.log(this.configmsg(),666)
      return this.configmsg()
    },
    qywx(){
      let msg = {}
      if(this.step4ruform.AutoMarketingTaskConfigMessageList.some(v =>{return v.Type==1})){
        msg =  this.step4ruform.AutoMarketingTaskConfigMessageList.find(v =>{return v.Type==1})
      }
      // console.log(msg)
      return msg
    },
    sms(){
      let msg = {}
      if(this.step4ruform.AutoMarketingTaskConfigMessageList.some(v =>{return v.Type==2})){
        msg =  this.step4ruform.AutoMarketingTaskConfigMessageList.find(v =>{return v.Type==2})
      }
      // console.log(msg,666)
      return msg
    },
    wxapp(){
      let msg = {}
      // console.log(this.step4ruform.AutoMarketingTaskConfigMessageList,666)
        msg = this.step4ruform.AutoMarketingTaskConfigMessageList.find(v =>{return v.Type==3})
      // if(this.step4ruform.AutoMarketingTaskConfigMessageList.some(v =>{return v.Type==3})){
      // }

      return msg||{
        Type:3,
        WxAppSubscribeMessageAttachInfo:{
          FirstText:'商城优惠活动进行中',
          SecondText:this.getdatenow(),
          ThirdText:'赶紧进入商城看看吧',
          LinkUrl:'/pages/index/index',
          LinkName:'商城首页',
          Type:3
        }
      }
    }
  },
  mounted () {
    this.step4ruform = JSON.parse(JSON.stringify(this.ruform))
    this.getDataList()
  },
  methods: {
    confirmwxapp(e){
      let ishave = this.step4ruform.AutoMarketingTaskConfigMessageList.some(v =>{return v.Type==3})
      
      if(ishave){
        this.step4ruform.AutoMarketingTaskConfigMessageList = this.step4ruform.AutoMarketingTaskConfigMessageList.map((v)=>{
          if(v.Type==3){
            v.WxAppSubscribeMessageAttachInfo = e
          }
          return v
        })
      }else{
        this.step4ruform.AutoMarketingTaskConfigMessageList.push({
          WxAppSubscribeMessageAttachInfo:e,
          Type:3
        })
      }
      // console.log(this.step4ruform.AutoMarketingTaskConfigMessageList)
      
      this.wxappShow = false
      this.isusewsapp = true
    },
    getwsappInfo(){
      // this.step4ruform.WxAppSubscribeMessageAttachInfo = this.step4ruform.WxAppSubscribeMessageAttachInfo||
      // {
      //   FirstText:'商城优惠活动进行中',
      //   SecondText:this.getdatenow(),
      //   ThirdText:'赶紧进入商城看看吧',
      //   LinkUrl:'/pages/index/index',
      //   LinkName:'商城首页'
      // }
    },
    getdatenow(){
      let date = new Date()
      let year = date.getFullYear()
      let month = (date.getMonth()+1)>9?(date.getMonth()+1):('0'+(date.getMonth()+1))
      let day = date.getDate()>9?date.getDate():('0'+date.getDate())
      let hour = date.getHours()>9?date.getHours():('0'+date.getHours())
      let min = date.getMinutes()>9?date.getMinutes():('0'+date.getMinutes())
      return year+'-'+month+'-'+day+' '+hour+':'+min
    },
    async getDataList() {
      try {
        // this.loading = true
        let result = await apiList.mcroProgramList();
        if (!result.length) {
          // result = [{
          //   MicroProgramData: {
          //     Id: 0
          //   },
          //   MicroProgramType: 0
          // }, {
          //   MicroProgramData: {
          //     Id: 0
          //   },
          //   MicroProgramType: 1
          // }]
        } else if (result.length === 1) {
          if (result[0].MicroProgramType === 0) {
            result.push({
              MicroProgramData: {
                Id: 0
              },
              MicroProgramType: 1
            })
          } else {
            result = [{
              MicroProgramData: {
                Id: 0
              },
              MicroProgramType: 0
            }].concat(result)
          }
        }
        let program = result.find(v=>v.MicroProgramType==0)
        this.program = program||{}
        // console.log(this.program)

      } catch (e) {} finally {
        // this.loading = false
      }
    },
    Initialize(){
      this.step4ruform.couponslist = []
      this.step4ruform.activelist = []
      //优惠券列表
      if(this.step4ruform.IsOpenGiftCoupon){
        this.step4ruform.AutoMarketingTaskConfigGiftList.map((v)=>{
          if(v.Type==1){
            v.CouponName = v.CouponInfo.CouponName
            v.UseTypeValue = v.CouponInfo.UseTypeValue
            v.CouponContent = v.CouponInfo.CouponContent
            v.ActivityRangeTypeValue = v.CouponInfo.ActivityRangeTypeValue
            v.State = v.CouponInfo.State
            // console.log(v)
            this.step4ruform.couponslist.push(v)
          }
        })
        // console.log(this.step4ruform.couponslist)
      }
      //抽奖活动
      if(this.step4ruform.IsOpenGiftWheelSurf){
        this.step4ruform.AutoMarketingTaskConfigGiftList.map((v)=>{
          if(v.Type==2){
            v.Name = v.WheelSurfInfo.Name
            v.StartTime = v.WheelSurfInfo.StartTime
            v.EndTime = v.WheelSurfInfo.EndTime
            this.step4ruform.activelist.push(v)
          }
        })
        // console.log(this.step4ruform.activelist[0])
      }

      this.isuseqywx = this.step4ruform.AutoMarketingTaskConfigMessageList.some(v =>{return v.Type==1})
      this.isusesms = this.step4ruform.AutoMarketingTaskConfigMessageList.some(v =>{return v.Type==2})
      let wsapp = this.step4ruform.AutoMarketingTaskConfigMessageList.find(v =>{return v.Type==3})
      // console.log(wsapp)
      if(wsapp&&wsapp.Id){
        this.isusewsapp = true
      }
      console.log(wsapp,this.isusewsapp)
      // this.isuseqywx = this.step4ruform.AutoMarketingTaskConfigMessageList.some(v =>{return v.Type==1})
      // this.isusesms = this.step4ruform.AutoMarketingTaskConfigMessageList.some(v =>{return v.Type==2})
      // this.isusewsapp = this.step4ruform.AutoMarketingTaskConfigMessageList.some(v =>{return v.Type==3})
      // console.log(this.step4ruform.AutoMarketingTaskConfigMessageList)
      // console.log(this.$route.query)
    },
    async validation(){
      return new Promise((res,rej)=>{
        this.$refs['step4ruform'].validate((valid)=>{
          if(valid){
            let list = []
            if(this.step4ruform.IsOpenGiftCoupon){
              list.push(this.$refs.couponstable.validate())
            }
            if(this.step4ruform.IsOpenGiftWheelSurf){
              list.push(this.$refs.activityTable.validForm())
            }
            // console.log(list,'22')
            if(list&&list.length){
              Promise.all(list).then((suc)=>{
                let newlist = []
                if(this.step4ruform.IsOpenGiftCoupon){
                  suc.map((v)=>{
                    v.map((x)=>{
                      if(x.Type==1){
                        newlist.push(x)
                      } 
                    })
                  })
                }
                if(this.step4ruform.IsOpenGiftWheelSurf){
                  suc.map((v)=>{
                    v.map((x)=>{
                      if(x.Type==2){
                    // console.log(x,'v')
                        newlist.push(x)
                      } 
                    })
                  })
                }
                this.step4ruform.AutoMarketingTaskConfigGiftList = newlist
                console.log(newlist,'xx')
                this.getMessageList()
                // console.log(newlist)
                res(this.step4ruform)
                // console.log(res,'newres')
              }).catch(((err)=>{
                // console.log(err)
                rej('请先完善第4步任务基本信息的配置')
                // console.log(err,'newerr')
              }))
            }else{
              this.getMessageList()
              // console.log(1)
              res(this.step4ruform)
            }
          }else{
            // console.log(this.step4ruform.AutoMarketingTaskConfigGiftList,'err')
            rej('请先完善第4步任务基本信息的配置')
          }
        })
      })
    },
    wxappEdit(){
      if(this.$route.query.Id&&!this.$route.query.copy){
        // if(this.isusewsapp){
        //   this.wxappShow = true
        // }else
        {
          this.$message.error('内容配置失败，已保存的任务不支持修改推送渠道')
        }
      }else{
        this.wxappShow = true
      }
    },
    editqywx(){
      if(this.$route.query.Id&&!this.$route.query.copy){
        if(this.isuseqywx){
          this.qywxvisible = true
        }else{
          this.$message.error('内容配置失败，已保存的任务不支持修改推送渠道')
        }
      }else{
        this.qywxvisible = true
      }
    },
    editsms(){
      if(this.$route.query.Id&&!this.$route.query.copy){//编辑时
        if(this.isusesms){
          this.smsvisible = true
        }else{
          this.$message.error('内容配置失败，已保存的任务不支持修改推送渠道')
        }
      }else{
        this.smsvisible = true
      }
    },
    getMessageList(){
      let list = []
      this.step4ruform.AutoMarketingTaskConfigMessageList.map((v)=>{
        if(this.isuseqywx && v.Type == 1){
          list.push(v)
        }
        if(this.isusesms && v.Type == 2){
          list.push(v)
        }
        if(this.isusewsapp && v.Type == 3){
          list.push(v)
        }
      })
      this.step4ruform.AutoMarketingTaskConfigMessageList = list
      // console.log(list)
    },
    init() {
      this.$emit('refresh')
    },
    jumpother(e){
      let url = ''
      if(e==2){
        url = 'https://jusnn6k8al.feishu.cn/docs/doccnB5xPQXMEuHQHkYKhveQE7f'
      }else if(e==3){
        url = 'https://jusnn6k8al.feishu.cn/docs/doccnoYMB2rINQaBu3QI3mKHSzb'
      }else if(e==4){
        url = 'https://developers.weixin.qq.com/community/develop/doc/00008a8a7d8310b6bf4975b635a401'
      }
      window.open(url)
    },
    //获取选中的优惠券列表
		getcouponslist(list) {
      // console.log(list)
      if (list && list.length) {
          this.step4ruform.couponslist = list.map((v) => {
          // v.ActivityCouponId = v.Id
          v.RelationId = v.Id
          v.Type = 1
          v.GiftCount = v.GiftCount?v.GiftCount:1
          return v
        })
        this.couponsdialogshow = false
        // console.log(this.step4ruform.couponslist,'list')
      } else {
        this.$message.error('请选择优惠券')
      }
    },
    //删除优惠券
    delshop(list){
      this.step4ruform.couponslist = list
      // console.log(this.step4ruform.couponslist.map((v)=>{return v.ActivityCouponId}))
    },
    //获取选中的活动列表
		getActivetList(data) {
      // console.log(data,'123')
      if (!data) {
        this.$message.error('请选择活动')
      } else {
        let msg = JSON.parse(JSON.stringify(data))
        // console.log(msg,'1')
        msg.Type = 2,
        msg.RelationId = data.Id,
        msg.GiftCount = data.GiftCount?data.GiftCount:1,
        this.step4ruform.activelist = []
        this.step4ruform.activelist.push(msg)
        // console.log(msg.Id,'2')
        this.selectactiveDialog = false;
      }
    },
    //获取短信配置
    confirmsms(data) {
      // console.log(data,'123')
      // console.log(data)
      let ishave = this.step4ruform.AutoMarketingTaskConfigMessageList.some(v =>{return v.Type==2})
      
      if(ishave){
        this.step4ruform.AutoMarketingTaskConfigMessageList = this.step4ruform.AutoMarketingTaskConfigMessageList.map((v)=>{
          if(v.Type==2){
            v.HeadSign = data.HeadSign 
            v.Content = data.Welcome
            v.Tail = data.Tail 
          }
          return v
        })
      }else{
        let msg = {
          HeadSign:data.HeadSign,
          Content:data.Welcome,
          Tail:data.Tail,
          Type:2
        }
        // console.log(1)
        this.step4ruform.AutoMarketingTaskConfigMessageList.push(msg)
      }
      // console.log(this.step4ruform.AutoMarketingTaskConfigMessageList)
      this.smsvisible = false
      this.isusesms = true
    },
    async getMallConfig() {
				let res = await mallFreeVersionConfigInfo()
				this.mallConfigInfo = res.Result
					// console.log(this.mallConfigInfo)
					window.open(this.mallConfigInfo.WxAppAuthUrl)
			},
    //获取企业微信配置
    async confirmhtml(data) {
      if(data.fileList.some(v =>{return v.Type==3})){
        // console.log(data.fileList,11111)
        try{
          for(let item of data.fileList.filter(v=>v.Type==3)){
            // console.log(item)
            let sub = item.LinkUrl.indexOf('?')
            let data = {
              Page:sub>0?item.LinkUrl.substring(0,sub):item.LinkUrl,
              Scene:sub>0?item.LinkUrl.substring(sub+1,item.LinkUrl.length):'?',
              IsLimited: false,
              ProgramType: 0,
              noError: true
            }
            let res = await apiList.mallGetQrCode(data)
            // res = {
            //   IsSuccess:false,
            //   ErrorCode:85074
            // }
            if(!res.IsSuccess){
              if (res.ErrorCode == 1000063) { //未绑定商城小程序
                this.$store.dispatch('GetFreeMallWxAppAndPayInfo').then(() => {
                  // this.signLoding = false;
                  this.state = window.localStorage.getItem('mlmzFreePayState')
                  // this.state = 0
                  // console.log(window.localStorage.getItem('mlmzFreePayState'))
                  if (this.state == 0) {
                    this.$confirm('你的商城还未授权绑定微信小程序，小程序附件添加失败，请先完成小程序绑定', '提示', {
                      distinguishCancelAndClose: true,
                      confirmButtonText: '去绑定',
                      cancelButtonText: '关闭'
                    }).then(() => {
                      this.getMallConfig()
                    }).catch(action => {});
                    return
                  }
                  window.localStorage.setItem('canEditSetinfos', true);
                  window.localStorage.setItem('toPayResultFromPath', this.$route.path);
                }).catch(() => {})
              } else if (res.ErrorCode == 85074) { //小程序未发布
                this.$confirm('商城小程序还未发布上线，小程序附件添加失败，请先前往发布小程序', '', {
                  confirmButtonText: '去发布',
                  cancelButtonText: '关闭',
                }).then(() => {
                  let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#' : ''
                  let url = this.goUrls + headsUrls + '/setting/wxAppManage'
                  window.open(url)
                }).catch(() => {})
              } else if (res.ErrorCode == 41030) { //page页面不存在
                this.$confirm('小程序附件添加失败，请先将小程序版本更新成最新版本', '', {
                  confirmButtonText: '去更新',
                  cancelButtonText: '关闭',
                }).then(() => {
                  let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#' : ''
                  let url = this.goUrls + headsUrls + '/setting/wxAppManage'
                  window.open(url)
                }).catch(() => {})
              }
              return
            }
          }
        }finally{
          //
        }
      }
      let haveqywx = this.step4ruform.AutoMarketingTaskConfigMessageList.some(v =>{return v.Type==1})
      if(haveqywx){
        this.step4ruform.AutoMarketingTaskConfigMessageList=this.step4ruform.AutoMarketingTaskConfigMessageList.map((v)=>{
          if(v.Type==1){
            v.Content = data.innerhtml
            v.QyWeixinAttachmentInfoList = data.fileList
          }
          return v
        })
      }else{
        let msg = {
          Content:data.innerhtml,
          QyWeixinAttachmentInfoList:data.fileList,
          Type:1
        }
        this.step4ruform.AutoMarketingTaskConfigMessageList.push(msg)
      }
      this.qywxvisible = false
      this.isuseqywx = true
      // console.log(data)
    },
  }
}
</script>

<style lang ="less" scoped>
  .programmsg{
    font-size: 14px;
    color: #666666;
    line-height: 1.5;
    margin-top: 10px;
  }
  .programtitle{
    font-size: 14px;
    color: #303133;
    line-height: 1.5;
    font-weight: bold;
  }
  .pushnotification{
    display: flex;
    justify-content:space-between;
    align-items:flex-start;
    flex-wrap:wrap;
    gap:20px 0px
  }
  .grayfont {
		font-size: 12px;
		color: #999999;
		line-height: 1.5;
    white-space: normal;
	}
  .flexRow{
    display: flex;
    align-items: center;
  }
  .bluebeginning {
		width: 5px;
		height: 20px;
		background: #409eff;
		border-radius: 30px;
	}

	.graybox {
		border: 1px solid #DCDFE6;
		width: 49%;
		padding: 20px;
		display: flex;
		flex-direction: row;
		align-items: flex-start;
  
		.titfont {
			font-size: 16px;
			color: #303133;
			margin: 0px 10px
		}

		.configurationbox {
			border: 1px dashed #EBEEF5;
			margin: 15px 0px 0px 10px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 30px 0px;
			cursor: pointer;

			.iconsty {
				font-weight: bold;
				font-size: 30px;
				color: #409eff;
			}

			.iconfont {
				font-size: 16px;
				color: #606266;
				margin-top: 20px;
			}
		}

	}

	.haveqywxhtml {
		border: 1px solid #EBEEF5;
		margin: 15px 0px 0px 10px;
		background: #FBFDFF;
    .line{
      background: #EBEEF5;
      height: 1px;
      width: 100%;
      margin: 0px
    }
	}

	.vertical3 {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		overflow: hidden;
	}

	.confont {
		font-size: 14px;
		color: #303133;
    word-break: break-all;
	}

	.haveqywxlist {
		border-top: 1px solid #EBEEF5;
		background: #FBFDFF;
		padding: 10px 5px;
		font-size: 14px;
		color: #303133;
	}

	.confontsmshtml {
		border-top: 1px solid #EBEEF5;
		background: #FBFDFF;
		padding: 10px 5px;
		font-size: 14px;
		color: #303133;
    word-break: break-all;
	}
</style>