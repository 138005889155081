<template>
  <div>
    <el-form-item label-width="0px">
      <div class = "flexRow">
        <el-select :disabled="disabled" v-model="message.AutoMarketingTaskConfigPushRuleEvent.ConditionType" style="margin-left:10px;width:120px">
          <el-option v-for="v in contrastlist" :key="v.value" :value="v.value" :label="v.label"></el-option>
        </el-select>
        <div class = "flexRow" v-if="message.AutoMarketingTaskConfigPushRuleEvent.ConditionType!=6">
          <el-form-item label-width="0px" prop="AutoMarketingTaskConfigPushRuleEvent.Num" :rules="type==1?newrules.money:newrules.numberof">
            <el-input :disabled="disabled" v-model="message.AutoMarketingTaskConfigPushRuleEvent.Num" style="width:150px;margin:0px 10px"></el-input>
          </el-form-item>
          <span>{{type==1?'元':'次'}}</span>
        </div>
        <div class = "flexRow" v-else>
          <el-form-item label-width="0px" prop="AutoMarketingTaskConfigPushRuleEvent.Num" :rules="type==1?newrules.money:newrules.numberof">
            <el-input :disabled="disabled" v-model="message.AutoMarketingTaskConfigPushRuleEvent.Num" style="width:150px;margin:0px 10px"></el-input>
          </el-form-item>
          <span>{{type==1?'元':'次'}}(含) ~ </span>
          <el-form-item label-width="0px" prop="AutoMarketingTaskConfigPushRuleEvent.MaxNum" :rules="type==1?newrules.maxmoney:newrules.maxnumberof">
            <el-input :disabled="disabled" v-model="message.AutoMarketingTaskConfigPushRuleEvent.MaxNum" style="width:150px;margin:0px 10px"></el-input>
          </el-form-item>
          <span>{{type==1?'元':'次'}}(含)</span>
        </div>
      </div>
    </el-form-item>
  </div>
</template>

<script>
export default {
  props: {
    message:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    text:{
      type:String,
      default:'元'
    },
    rules:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    type:{
      type:[Number,String],
      default:1  
    },
    disabled:{
      type:Boolean,
      default:false,
    },
  },
  data () {
    var checkmoney = (rule, value, callback) => {
      let two = /^\d+(\.\d{1,2})?$/
      if (value < 0 || value > 9999 || !two.test(value)) {
        callback(new Error('金额请输入在0~9999之间，最多2位小数'))
      } else {
        callback()
      }
    };
    var checkmaxmoney = (rule, value, callback) => {
      let two = /^\d+(\.\d{1,2})?$/
      console.log(value)
      if (value < 0 || value > 9999 || !two.test(value)) {
        callback(new Error('金额请输入在0~9999之间，最多2位小数'))
      } else if (value < this.message.AutoMarketingTaskConfigPushRuleEvent.Num) {
        callback(new Error('请输入正确的区间'))
      } else {
        callback()
      }
    };
    var checknumberof = (rule, value, callback) => {
      let num = String(value)
      if (!num || num < 0 || num > 99 || num != parseInt(num)) {
        callback(new Error('次数请输入在0~99之间，整数 '))
      } else {
        callback()
      }
    };
    var checkmaxnumberof = (rule, value, callback) => {
      let num = String(value)
      if (!num || num < 0 || num > 99 || num != parseInt(num)) {
        callback(new Error('次数请输入在0~99之间，整数 '))
      } else if (value < this.message.AutoMarketingTaskConfigPushRuleEvent.Num) {
        callback(new Error('请输入正确的区间'))
      } else {
        callback()
      }
    };
    return {
      contrastlist:[
        {label: '大于', value:1},
        {label: '大于等于', value:2},
        {label: '小于', value:3},
        {label: '小于等于', value:4},
        {label: '等于', value:5},
        {label: '区间', value:6},
      ],
      newrules:{
        money: [{
						required: true,
						validator: checkmoney,
						trigger: 'blur'
					}],
					numberof: [{
						required: true,
						validator: checknumberof,
						trigger: 'blur'
					}],
					maxmoney: [{
						required: true,
						validator: checkmaxmoney,
						trigger: 'blur'
					}],
					maxnumberof: [{
						required: true,
						validator: checkmaxnumberof,
						trigger: 'blur'
					}],
      },
    }
  }
}
</script>

<style lang = "less" scoped>
  .flexRow{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
</style>