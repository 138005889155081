<template>
  <div>
    <el-dialog :visible="wxappShow" title="小程序订阅消息推送" @close="close" width="1000px">
      <div class="flex justify-content-between" style="">
        <div>
          <div class="flex flex-align-center" style="margin-bottom:20px">
            <div class="label">通知标题：</div>
            <div class="" style="flex:1">
              活动开始提醒
            </div>
          </div>
          <div class="flex flex-align-center" style="margin-bottom:20px">
            <div class="label label-star">活动名称：</div>
            <el-input v-model="message.FirstText" style="width:400px" maxlength="20" placeholder="请输入活动名称"></el-input>
          </div>
          <div class="flex flex-align-center" style="margin-bottom:20px">
            <div class="label label-star">开始时间：</div>
            <el-date-picker
              v-model="message.SecondText"
              value-format="yyyy-MM-dd HH:mm"
              format="yyyy-MM-dd HH:mm"
              style="width:400px"
              type="datetime"
              placeholder="选择日期">
            </el-date-picker>
          </div>
          <div class="flex flex-align-center" style="margin-bottom:20px">
            <div class="label label-star">温馨提示：</div>
            <el-input v-model="message.ThirdText" style="width:400px" maxlength="20" placeholder="请输入温馨提示"></el-input>
          </div>
          <div class="flex flex-align-center" style="margin-bottom:20px">
            <div class="label">通知链接：</div>
            <el-button type="text" @click="linkVisible=true">
              <div class="flex flex-align-center">
                <div v-if="message.LinkUrl" class="ellipsis" style="flex:1;max-width:350px">
                  <span >{{message.LinkName}}</span>
                </div>
                <span v-else>请选择链接页面</span>
                <i class="el-icon-close" v-if="message.LinkUrl" style="margin-left:5px" @click.stop="clear"></i>
                <i class="el-icon-arrow-down" style="margin-left:5px"></i>
              </div>
            </el-button>
          </div>
        </div>
        <div class="exhibition">
          <div class="content">
            <div class="top flex flex-align-center justify-content-between">
              <div>
                <img :src="program.MicroProgramData&&program.MicroProgramData.HeadUrl" alt="">
                {{program.MicroProgramData&&program.MicroProgramData.NickName}}
              </div>
              <div class="top-right">
                ···
              </div>
            </div>
            <div class="main">
              <div class="title">
                活动开始提醒
              </div>
              <div class="flex flex-align-center" style="margin-top:17px">
                <div class="main-label">活动名称</div>
                <div class="ellipsis" style="color:#303133">
                  {{message.FirstText}}
                </div>
              </div>
              <div class="flex flex-align-center" style="margin-top:7px">
                <div class="main-label">开始时间</div>
                <div class="ellipsis" style="color:#303133">
                  {{message.SecondText}}
                </div>
              </div>
              <div class="flex flex-align-center" style="margin-top:7px">
                <div class="main-label">温馨提示</div>
                <div class="ellipsis" style="color:#303133">
                  {{message.ThirdText}}
                </div>
              </div>
              <div class="bottom">
                <div class="title">
                  进入小程序查看
                </div>
                <i class="el-icon-arrow-right" style="font-size:16px"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="text-align:center">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </div>
    </el-dialog>

    <linkModal :unshowTab="[5]" :unfunctionalTab="[3,7,8,12,14,15]" :visible="linkVisible"
     @change="dataChange" @cancel="linkVisible=false">
    </linkModal>
  </div>
</template>

<script>
import linkModal from '@/views/components/linkModal';
export default {
  components: {
    linkModal
  },
  props: {
    wxappShow:{
      type:Boolean,
      default:false
    },
    msg:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    program:{
      type:Object,
      default:()=>{
        return {}
      }
    },
  },
  data () {
    return {
      message:{},
      linkVisible:false
    }
  },
  watch: {
    wxappShow(val){
      if(val){
        this.message = JSON.parse(JSON.stringify(this.msg)).WxAppSubscribeMessageAttachInfo
        console.log(this.program)
      }
    }
  },
  mounted () {
    this.message = JSON.parse(JSON.stringify(this.msg)).WxAppSubscribeMessageAttachInfo
  },
  methods: {
    confirm(){
      if(!this.message.FirstText){
        this.$message.error('请输入活动名称')
        return
      }
      if(!this.message.SecondText){
        this.$message.error('请设置开始时间')
        return
      }
      if(!this.message.ThirdText){
        this.$message.error('请输入温馨提示')
        return
      }
      this.$emit('confirm',this.message)
    },
    dataChange(e){
      this.message.LinkUrl = this.getLinkUrl(e)
      this.message.LinkName = e.text
    },
    clear(){
      this.message.LinkUrl = ''
      this.message.LinkName = ''
    },
    close(){
      this.$emit('close')
    },
    getLinkUrl(record) {
      if (record.type == "classify") {
        return 'pages/productGroup/productGroup?id=' + record.data.Id
      } else if (record.type == "functional") {
        // 全部商品
        if (record.data.id == 1) {
          return 'pages/index/index'
        } else if (record.data.id == 2) {
          return 'pages/allProduct/allProduct'
        } else if (record.data.id == 3) {
          // 个人中心
          return 'pages/personalCenter/mine/mine'
        } else if (record.data.id == 4) {
          // 购物车
          return 'pages/shoppingCart/shoppingCart'
        } else if (record.data.id == 5) {
          // 积分中心
          return 'pages/pointsCenter/pointsCenter'
        } else if (record.data.id == 6) {
          // 每日签到
          return 'pages/userSign/userSign'
        } else if (record.data.id == 7) {
          // 客服
          return 'pages/customerService/customerService'
        } else if (record.data.id == 8) {
          // 订单列表
          return 'pages/order/myOrder/myOrder'
        } else if (record.data.id == 9) {
          // 分销礼包
          return 'pages/invitation/invitationCard/invitationCard'
        } else if (record.data.id == 10) {
          // 成为会员
          return 'pageA/pages/openSuccess/openSuccess'
        } else if (record.data.id == 11) {
          // 助力直播列表
          return 'pageA/pages/live/live'
        } else if (record.data.id == 12) {
          // 扫码购
          return 'pageA/pages/scan/scan'
        } else if (record.data.id == 13) {
          // 充值中心
          return 'pageA/pages/balanceRecharge/balanceRecharge'
        } else if (record.data.id == 14) {
          // 群分享
          return 'pageA/pages/groupShare/groupShare'
        } else if (record.data.id == 15) {
          // 全部信息页
          return 'pageA/pages/information/information'
        }else if (record.data.id == 16) {
          // 短视频带货
          return 'pageC/pages/shortVideos/record'
        }else if (record.data.id == 17) {
          // 积分码
          return 'pageA/pages/receive-point/receive-point'
        }else if (record.data.id == 18) {
          // 舌诊
          return 'pageC/pages/tongueDiagnosis/home'
        }else if (record.data.id == 19) {
          // 企店即创主页
          return 'pageC/pages/AIGC/home'
        }
      } else if (record.type == "product") {
        return 'pages/detail/detail?id=' + record.data.Id
      } else if (record.type == "custom") {
        return 'pages/cusPage/cusPage?id=' + record.data.Id
      } else if (record.type == 'truntable') {
        return 'pageA/pages/activity/truntable/truntable?id=' + record.data.Id
      } else if (record.type == 'live') {
        return 'plugin-private://wx2b03c6e691cd7370/pages/live-player-plugin?room_id=' + record.data.RoomId
      } else if (record.type == 'coupon') {
        return 'pages/couponCenter/receive/receive?id=' + record.data.Id + '&uid=' + (record.data.uid || '')
      } else if (record.type == 'help') {
        return 'pages/helpDetail/helpDetail?id=' + record.data.Id + '&activityId=' + record.data.ActivityFullId
      } else if (record.type == 'group') {
        return 'pages/groupDetail/groupDetail?id=' + record.data.ProductId + '&fullId=' + record.data
          .ActivityFullId
      } else if (record.type == 'questionnaire') {
        return 'pageA/pages/questionnaire/questionnaire?&referer=5&id=' + record.data.Id;
      }else if (record.type == 'taketest') {
        return `pageC/pages/goodnessTest/home?id=` + record.data.Id
      }else if (record.type == 'contentgroup') {
        return `pageA/pages/information/informationGroup?id=` + record.data.Id;
      }else if (record.type == 'singlecontent') {
        return `pageA/pages/information/detail?id=` + record.data.Id;
      }else if (record.type == 'helpPro') {
        return `pageC/pages/helpPro/helpPro?id=` + record.data.ProductId + '&activityId=' + record.data.ActivityFullId
      }
      return ''
    },
  }
}
</script>

<style lang="less" scoped>
  .exhibition{
    width: 320px;
    height: 540px;
    // border:1px solid black;
    flex-shrink: 0;
    background: url('http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/images/melyshop/9cc1c036c3cb902f0efa00f1e5fef29.png');
    background-size: 320px 540px;
    .content{
      margin: 124px auto 0px auto;
      background: white;
      width: 288px;
      // height: 223px;
      border-radius: 4px;
      .top{
        border-bottom: 1px solid #DDDDDDFF;
        padding: 10px 15px;
        font-size: 13px;
        color: #303133;
        line-height: 24px;
        font-weight: 500;
        img{
          width: 21px;
          height: 21px;
          border-radius: 50%;
        }
        .top-right{
          font-size: 20px;
          color: #909399;
        }
      }
      .main{
        padding: 10px 15px;
        .title{
          font-weight: 400;
          color: #303133;
          line-height: 20px;
          font-size: 15px;
          margin-top:7px
        }
        .main-label{
          font-size: 14px;
          white-space: nowrap;
          flex-shrink: 0;
          color: #888888;
          margin-right: 15px;
          line-height: 1;
        }
        .bottom{
          border-top: 1px solid #DDDDDDFF;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top:18px;
          padding-top: 7px;
          .title{
            font-weight: 400;
            color: #303133;
            font-size: 13px;
          }
        }
      }
    }
  }
  .flex{
    display: flex;
  }
  .justify-content-between{
    justify-content: space-between;
  }
  .flex-align-center{
    align-items: center;
  }
  .label{
    font-size: 14px;
    color: #606266;
    white-space: nowrap;
    flex-shrink: 0;
    width: 100px;
    text-align: right;
    // border: 1px solid black;
    &.label-star::before{
      content: '*';
      margin-right:4px;
      color: #f56c6c;
    }
  }
</style>