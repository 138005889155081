<style scoped lang="less">
    .search-box{
        margin-bottom: 20px;
    }
    .table-box{
        margin-bottom: 15px;
    }
</style>
<template>
    <el-dialog
		v-loading="pageloading"
        :visible="show"
        width='1090px' 
        title="选择链接"
        @close="cancel">

        <el-tabs type="card" @tab-click="tabChange" :value="active">
            <el-tab-pane v-for="tab in list" :key="tab.id" :label="tab.name" :name="tab.key">
							<div style="max-height: 55vh;overflow: auto;">
								<classify-tab v-if="tab.id === 2" @change="record=>dataChange(record, tab.key)"></classify-tab>
								<functional-tab :unfunctionalTab="unfunctionalTab" v-if="tab.id === 3" :pagelist="pagelist"
								 @change="record=>dataChange(record, tab.key)"></functional-tab>
								<product-tab v-if="tab.id === 1" @change="record=>dataChange(record, tab.key)"></product-tab>
								<custom-tab v-if="tab.id === 4" @change="record=>dataChange(record, tab.key)"></custom-tab>
								<live-tab v-if="tab.id === 5 && showLiveTab" @change="record=>dataChange(record, tab.key)"></live-tab>
								<truntable v-if="tab.id === 6" @change="record=>dataChange(record, tab.key)"></truntable>
								<coupon v-if="tab.id === 7" @change="record=>dataChange(record, tab.key)"></coupon>
								<help v-if="tab.id === 8" @change="record=>dataChange(record, tab.key)"></help>
								<group v-if="tab.id === 9" @change="record=>dataChange(record, tab.key)"></group>
								<questionnaire v-if="tab.id === 10" @change="record=>dataChange(record, tab.key)"></questionnaire>
								<taketest v-if="tab.id === 11" @change="record=>dataChange(record, tab.key)"></taketest>
								<contentgroup v-if="tab.id === 12" @change="record=>dataChange(record, tab.key)"></contentgroup>
								<singlecontent v-if="tab.id === 13" @change="record=>dataChange(record, tab.key)"></singlecontent>
								<help-pro v-if="tab.id === 14" @change="record=>dataChange(record, tab.key)"></help-pro>
							</div>
            </el-tab-pane>
        </el-tabs>

        
        <div slot="footer">
            <el-button type="primary" @click="btnSure">确定</el-button>
        </div>

    </el-dialog>
</template>
<script>

const tabs = [
	{
	    id: 3,
	    name: '系统页面',
	    key: 'functional'
	},
	{
			id: 2,
			name: '商品分组',
			key: 'classify'
	},
	{
	    id: 1,
	    name: '商品',
	    key: 'product',
	},
	{
			id: 4,
			name: '自定义页面',
			key: 'custom'
	},
	{
		id: 6,
		name: '转盘抽奖',
		key: 'truntable'
	},
	{
		id: 7,
		name: '优惠券',
		key: 'coupon'
	},
	{
		id: 8,
		name: '助力领取商品',
		key: 'help'
	},
	{
		id: 9,
		name: '拼团商品',
		key: 'group'
	},
	{
		id: 10,
		name: '问卷调查',
		key: 'questionnaire'
	},
	{
		id: 11,
		name: '测一测',
		key: 'taketest',
		disposition:true
	},
	{
		id: 12,
		name: '内容分组',
		key: 'contentgroup'
	},
	{
		id: 13,
		name: '单篇内容',
		key: 'singlecontent'
	},
	{
		id: 14,
		name: '助力Pro活动',
		key: 'helpPro',
		disposition:true
	},
]
"@/views/"
import productTab from '@/views/components/components/product';
import classifyTab from '@/views/components/components/classify';
import functionalTab from '@/views/components/components/functional';
import customTab from '@/views/components/components/custom';
import liveTab from '@/views/components/components/live';
import truntable from '@/views/components/components/truntable';
import coupon from '@/views/components/components/coupon';
import help from '@/views/components/components/help';
import group from '@/views/components/components/group';
import questionnaire from '@/views/components/components/questionnaire';
import pointsCode from '@/views/components/components/pointsCode';
import taketest from '@/views/components/components/taketest';
import contentgroup from '@/views/components/components/contentgroup';
import singlecontent from '@/views/components/components/singlecontent';
import helpPro from '@/views/components/components/helpPro';
// import videoTab from './components/video';

import {
	wxappwxappgenerateurllink
} from "@/api/sv3.0.0"
import axios from "axios"
import config from "@/config"
import apiList from '@/api/other';
import {
	mallFreeVersionConfigInfo
} from "@/api/goods"

import {
	mallmallSpecialLimitManagerInit
} from "@/api/api"
export default {
    components: {
			productTab, //产品
			classifyTab, //营销活动
			functionalTab, //功能页面
			customTab, //自定义页面
			// videoTab, //视频模块
			liveTab,//直播模块
			truntable, //转盘抽奖
			coupon, //优惠券
			group,//拼团商品
			help, //助力领取商品
			questionnaire, //问卷调查
			pointsCode,
			taketest,//测一测
			contentgroup,//内容分组
			singlecontent,//单篇内容
			helpPro,
    },
    props: {
			visible: {
					type: Boolean,
					default: false
			},
			showLiveTab: {
				type: Boolean,
				default: false
					},
			showTab: {
				type: Array,
				default: ()=>{
					return []
				}
			},
			unshowTab: {
				type: Array,
				default: ()=>{
					return []
				}
			},
			unfunctionalTab: { //系统页面 需要展示的数据
				type: Array,
				default: ()=>{
					return []
				}
			},
    },
    data () {
			return {
				pageloading:false,
				show: false,
				list: tabs,
				data: {},
				active: 'functional',
				mallConfigInfo:{},
				goUrls: config.GO_URL,
				state:null,
				pagelist:[]
			};
    },
    methods: {
			cancel(){
					this.$emit('cancel');
			},
			tabChange(record){
				console.log(record)
					this.active = record.name;
			},
			dataChange(data, key){
					this.data[key] = data;
			},
			btnSure(){
					if (!this.data[this.active]){
						this.$message({showClose: true,
							type: 'error',
							message: '您未选择任何链接!'
						});
						return false;
					}
		
					// this.cancel();
					// console.log(this.data[this.active])
					// console.log(this.dataChange11(this.data[this.active]))
					// console.log(this.data[this.active],this.active)
					this.getlink()
					// this.$emit('change', this.dataChange11(this.data[this.active]))
			},
			async getMallConfig() {
				let res = await mallFreeVersionConfigInfo()
				this.mallConfigInfo = res.Result
					// console.log(this.mallConfigInfo)
					window.open(this.mallConfigInfo.WxAppAuthUrl)
			},
			async getlink(){
				this.pageloading=true
				try{
					let data = this.dataChange11(this.data[this.active])
					let newdata = {
						Page:data.path,
						Scene:data.query?data.query:'?',
						IsLimited: false,
						ProgramType: 0,
						noError: true
					}
					let res = await apiList.mallGetQrCode(newdata)
					if(!res.IsSuccess){
						if (res.ErrorCode == 1000063) { //未绑定商城小程序
							this.$store.dispatch('GetFreeMallWxAppAndPayInfo').then(() => {
								// this.signLoding = false;
								this.state = window.localStorage.getItem('mlmzFreePayState')
								// this.state = 0
								// console.log(window.localStorage.getItem('mlmzFreePayState'))
								if (this.state == 0) {
									this.$confirm('你的商城还未授权绑定微信小程序，商城页面链接生成失败，请先完成小程序绑定', '提示', {
										distinguishCancelAndClose: true,
										confirmButtonText: '去绑定',
										cancelButtonText: '关闭'
									}).then(() => {
										this.getMallConfig()
									}).catch(action => {});
									return
								}
								window.localStorage.setItem('canEditSetinfos', true);
								window.localStorage.setItem('toPayResultFromPath', this.$route.path);
							}).catch(() => {})
						} else if (res.ErrorCode == 85074) { //小程序未发布
							this.$confirm('商城小程序还未发布上线，商城页面链接生成失败，请先前往发布小程序', '', {
								confirmButtonText: '去发布',
								cancelButtonText: '关闭',
							}).then(() => {
								let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#' : ''
								let url = this.goUrls + headsUrls + '/setting/wxAppManage'
								window.open(url)
							}).catch(() => {})
						} else if (res.ErrorCode == 41030) { //page页面不存在
							this.$confirm('商城页面链接生成失败，请先将小程序版本更新成最新版本', '', {
								confirmButtonText: '去更新',
								cancelButtonText: '关闭',
							}).then(() => {
								let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#' : ''
								let url = this.goUrls + headsUrls + '/setting/wxAppManage'
								window.open(url)
							}).catch(() => {})
						}
						return
					}
					// let data = this.dataChange11(this.data[this.active])
					let secondParam = window.localStorage.getItem('mlmzMallKey')
					let url = ''
					if (process.env.NODE_ENV == 'production' && process.env.VUE_APP_CURRENTMODE != 'stage') {
						url =`http://wapapi.qidian.shop/jump.html?path=${encodeURIComponent(data.path)}&query=${encodeURIComponent(data.query)}&mallkey=` + secondParam
					} else {
						url =`http://wap.dkyapitest.cn/jump.html?path=${encodeURIComponent(data.path)}&query=${encodeURIComponent(data.query)}&mallkey=` + secondParam
					}
					// console.log(url,1111)
					// url = 'http://wap.dkyapitest.cn/jump.html?path=pages%2Findex%2Findex&query=&mallkey=xcs'
					let res2 = await axios.post('http://api.xsmore.com:80/short/link/',{Url:url,R:true})
						console.log(res2)
					if(res2.status==200){
						this.$emit('change', res2.data.Url)
					}else{
						this.$message.error(res2.data.Message)
					}
				}finally{
					this.pageloading=false
				}
			},
			dataChange11(record) {
				let data ={
					path:'',
					query:''
				}
				if (record.type == "classify") {
					data.path = 'pages/productGroup/productGroup'
					data.query = 'id=' + record.data.Id
				} else if (record.type == "functional") {
					// 全部商品
					if (record.data.id == 1) {
						data.path = 'pages/index/index'
					} else if (record.data.id == 2) {
						data.path = 'pages/allProduct/allProduct'
					} else if (record.data.id == 3) {
						// 个人中心
						data.path = 'pages/personalCenter/mine/mine'
					} else if (record.data.id == 4) {
						// 购物车
						data.path = 'pages/shoppingCart/shoppingCart'
					} else if (record.data.id == 5) {
						// 积分中心
						data.path = 'pages/pointsCenter/pointsCenter'
					} else if (record.data.id == 6) {
						// 每日签到
						data.path = 'pages/userSign/userSign'
					} else if (record.data.id == 7) {
						// 客服
						data.path = 'pages/customerService/customerService'
					} else if (record.data.id == 8) {
						// 订单列表
						data.path = 'pages/order/myOrder/myOrder'
					} else if (record.data.id == 9) {
						// 分销礼包
						data.path = 'pages/invitation/invitationCard/invitationCard'
					}else if (record.data.id == 10) {
						// 成为会员
						data.path = 'pageA/pages/openSuccess/openSuccess'
					}else if (record.data.id == 11) {
						// 助力直播列表
						data.path = 'pageA/pages/live/live'
					}else if (record.data.id == 12) {
						// 扫码购
						data.path = 'pageA/pages/scan/scan'
					}else if (record.data.id == 13) {
						// 充值中心
						data.path = 'pageA/pages/balanceRecharge/balanceRecharge'
					}else if (record.data.id == 14) {
						// 群分享
						data.path = 'pageA/pages/groupShare/groupShare'
					}else if (record.data.id == 15) {
						// 全部信息页
						data.path = 'pageA/pages/information/information'
					}else if (record.data.id == 16) {
						// 短视频带货
						data.path = 'pageC/pages/shortVideos/record'
					}else if (record.data.id == 17) {
						// 积分码
						data.path = 'pageA/pages/receive-point/receive-point'
					}else if (record.data.id == 18) {
						// 舌诊
						data.path = 'pageC/pages/tongueDiagnosis/home'
					}else if (record.data.id == 19) {
						// 企店即创主页
						data.path = 'pageC/pages/AIGC/home'
					}
					

				} else if (record.type == "product") {
					data.path = 'pages/detail/detail'
					data.query = 'id=' + record.data.Id

				} else if (record.type == "custom") {
					// this.nemuList[this.firstIndex].PageName = record.data.PageName
					// this.nemuList[this.firstIndex].MenuType = 4
					data.path = 'pages/cusPage/cusPage'
					data.query = 'id=' + record.data.Id
				}	else if (record.type == 'truntable'){
					// this.nemuList[this.firstIndex].PageName = record.data.Name
					// this.nemuList[this.firstIndex].MenuType = 6
					data.path = 'pageA/pages/activity/truntable/truntable'
					data.query = 'id=' + record.data.Id
				} else if (record.type == 'live'){
					data.path = 'plugin-private://wx2b03c6e691cd7370/pages/live-player-plugin'
					data.query = 'room_id=' + record.data.RoomId
				} else if (record.type == 'coupon'){
					data.path = 'pages/couponCenter/receive/receive'
					data.query = 'id=' +record.data.Id+'&uid='+(record.data.uid || '')
				}else if (record.type == 'help') {
					data.path = 'pages/helpDetail/helpDetail'
					data.query = 'id=' + record.data.Id + '&activityId=' + record.data.ActivityFullId
				}else if (record.type == 'group') {
					data.path = 'pages/groupDetail/groupDetail'
					data.query = 'id=' + record.data.ProductId + '&fullId=' + record.data.ActivityFullId
				}else if (record.type == 'questionnaire'){
					data.path = 'pageA/pages/questionnaire/questionnaire'
					data.query = '&referer=4&id=' + record.data.Id;
				}else if (record.type == 'taketest') {
					data.path = `pageC/pages/goodnessTest/home?id=` + record.data.Id
					data.query = ''
				}else if (record.type == 'contentgroup') {
					data.path = `pageA/pages/information/informationGroup`
					data.query = `id=${record.data.Id}`
				}else if (record.type == 'singlecontent') {
					data.path = `pageA/pages/information/detail`
					data.query = `id=${record.data.Id}`
				}else if (record.type == 'helpPro') {
					data.path = `pageC/pages/helpPro/helpPro`
					data.query = `id=${record.data.ProductId}&activityId=${record.data.ActivityFullId}`
				}
				return data
			},
			async getAuthority(){
					try{
						let res = await mallmallSpecialLimitManagerInit()
						if(res.IsSuccess){
							// console.log(res.Result)
							this.pagelist = res.Result.filter(v=>v.Type==1).map(v=>v.RelationId)
							let tablist = res.Result.filter(v=>v.Type==2).map(v=>v.RelationId)
							this.list = this.list.filter(v=>{
								return !v.disposition||(v.disposition&&tablist.some(x=>x==v.id))
							})
						}
					}finally{
						//
					}
				},
    },
    watch: {
        visible(value){
            this.show = value;
        },
    },
    mounted () {
		
			if (this.unshowTab && this.unshowTab.length){
				let list = tabs.filter(item=>{
					return this.unshowTab.indexOf(item.id) == -1
				})
				this.list = list;
			}else{
				var list=[], list1=[], list2=[];
				this.list.map(item=>{
					if (item.id == 5){
						list.push(item);
					}
					if (item.id == 8){
						list1.push(item);
					}
					if (item.id == 9){
						list2.push(item);
					}
				})
				
				if (this.showLiveTab){
					if (!list.length){
						this.list.splice(4, 0, {
							id: 5,
							name: '直播管理',
							key: 'live'
						})
					}
					if (!list1.length){
						this.list.push({
							id: 8,
							name: '助力领取商品',
							key: 'help'
						})
					}
					if (!list2.length){
						this.list.push({
							id: 9,
							name: '拼团商品',
							key: 'group'
						})
					}
				}else{
					// console.log(this.list)
					if (list.length){
						this.list.splice(4, 1)
					}
					if (list2.length){
						this.list.splice(this.list.length-1, 1)
					}
					if (list1.length){
						this.list.splice(this.list.length-1, 1)
					}
				}
			}
			this.getAuthority()
		
    },
    created () {
    },
    dispatch () {
        
    }
};
</script>
