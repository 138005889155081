<template>
  <div>
    <div class="normal-dialog">
			<el-dialog title="选择员工" :visible="employeeVisible" width="900px" @close="closestaff">
				<div class="list-box">
					<div class="list-left">
						<div class="flex0">
							<el-input placeholder="员工姓名、手机号"  @input="treeSearch" v-model="keywords" style="width: 250px;margin-bottom: 10px;margin-right: 10px;"></el-input>
							<!-- <el-button type="primary" @click="treeSearch" size="mini">搜索</el-button> -->
						</div>
						<div class="flex1" v-if="!keywords">
							<el-tree :data="list" node-key="Id" :props="options" :default-expanded-keys="defaultKey" :expand-on-click-node="true">
								<span class="custom-tree-node" slot-scope="{ node, data }">
									<span style="color: #333;" v-if="data.Type !== 3">
										<i class="el-icon-s-shop" style="color: #409eff;margin-right: 5px;font-size: 16px;"></i>
										{{ node.label }}
										({{data.EmployeeCount}})
									</span>
									<span style="color: #333;font-size: 13px;" v-else>
										<img v-if="data.HeadUrl" class="hd" :src="data.HeadUrl"></img>
										<span class="hd-normal" v-else>员</span>
										{{ node.label }}
										({{data.Phone}})
									</span>
									<!-- handleStop就是为了阻止冒泡， 没其它用途 -->
									<span @click.stop="handleStop">
										<el-checkbox v-model="data.checked" @change="(e)=>{checkChange(e, data)}"></el-checkbox>
									</span>
								</span>
							</el-tree>
						</div>
						<div class="flex1" v-else>
							<div class="select-list" v-if="searchList.length">
								<div class="select-item select-item1" style="margin-bottom: 10px;" v-for="(item, index) in searchList" :key="item.Id">
									<span style="color: #333;font-size: 13px;">
										<img v-if="item.HeadUrl" class="hd" :src="item.HeadUrl"></img>
										<span class="hd-normal" v-else>员</span>
										{{ item.Name }}
										({{item.Phone}})
									</span>
									<span @click.stop="handleStop">
										<el-checkbox v-model="item.checked" @change="(e)=>{checkChange(e, item)}"></el-checkbox>
									</span>
								</div>
							</div>
							<div v-else style="text-align: center;font-size: 13px;color: #999;padding-top: 100px;">搜索无结果，请确认输入的名字或手机号是否正确</div>
						</div>
					</div>
					<div class="list-right">
						<div class="title">已选择的归属门店或员工</div>
						<div class="select-list">
							<div class="select-item" v-for="(item, index) in chooselist" :key="item.Id">
								<span style="color: #333;" v-if="item.Type !== 3">
									<i class="el-icon-s-shop" style="color: #409eff;font-size: 20px;vertical-align: -2px;"></i>
									{{ item.Name }}
								</span>
								<span style="color: #333;font-size: 13px;" v-else>
									<img v-if="item.HeadUrl" class="hd" :src="item.HeadUrl"></img>
									<span class="hd-normal" v-else>员</span>
									{{ item.Name }}
									({{item.Phone}})
								</span>
								<i class="el-icon-close delete-btn" @click="handleDeleteSelect(index)"></i>
							</div>
						</div>
					</div>
				</div>
				
				<span slot="footer" class="dialog-footer">
					<el-button @click="closestaff">取 消</el-button>
					<el-button type="primary" @click="handleSureSelect">确 定</el-button>
				</span>
				
			</el-dialog>
		</div>
  </div>
</template>

<script>
import {
		chooseEmployee,
	} from '@/api/sv1.0.0.js';
export default {
  props: {
    employeeVisible:{
      type:Boolean,
      default:false,
    },
    selectedData:{
      type:Array,
      default:(()=>{
        return []
      })
    }
  },
  data () {
    return {
      options: {
					children: 'NextList',
					label: 'Name'
			},
      defaultKey:[],
      list: [],
      searchList: [],
			chooselist:[],
      keywords:'',
    }
  },
  watch: {
			employeeVisible() {
				if (this.employeeVisible) {
					this.chooselist = this.selectedData
					this.getList();
				}
			}
	},
  methods: {
    treeSearch(){
				var list = []
				if (this.keywords){
					this.list.map(item=>{
						item.NextList.map(item1=>{
							item1.NextList.map(item2=>{
								if (item2.Name.indexOf(this.keywords) > -1 || item2.Phone.indexOf(this.keywords) > -1){
									list.push(item2);
								}
							})
						})
					})
					this.searchList = list;
				}else{
					this.treeCheckChange();
				}
		},
		treeCheckChange(){
				var list = JSON.parse(JSON.stringify(this.list));
				var ids = this.chooselist.map(item=>{
					return item.Id
				})
				
				list.map(item=>{
					item.checked = ids.indexOf(item.Id) > -1;
					item.NextList.map(item1=>{
						item1.checked = ids.indexOf(item1.Id) > -1;
						
						item1.NextList.map(item2=>{
							item2.checked = ids.indexOf(item2.Id) > -1;
							return item2;
						})
						
						return item1;
					})
					
					return item;
				})
		},
    handleStop(){},
    checkChange(e, record){
				// console.log(e)
				var obj = JSON.parse(JSON.stringify(record));
				delete obj.NextList;
				if (e){
					this.chooselist.push(obj);
				}else{
					this.chooselist = this.chooselist.filter(item=>{
						return item.Id != obj.Id
					})
				}
		},
    handleDeleteSelect(index){
				this.chooselist.splice(index, 1);
				this.treeCheckChange();
		},
    handleSureSelect(){
				if (!this.chooselist.length){
					this.$message.error('请选择归属门店或员工');
					return;
				}
				let selectList = JSON.parse(JSON.stringify( this.chooselist));
				// console.log(selectList,'确定')
        this.$emit('handleSureSelect',selectList)
		},
    closestaff(){
			this.chooselist = []
			// console.log(this.chooselist,'关闭')
      this.$emit('closestaff')
    },
    async getList() {
				try {
					const res = await chooseEmployee();
					var list = [res.Result];
					var ids = this.chooselist.map(item=>{
						return item.Id
					})
					// console.log(ids)
					list.map((item,i)=>{
						item.checked = ids.indexOf(item.Id) > -1;
						item.NextList.map((item1,i1)=>{
							item1.checked = ids.indexOf(item1.Id) > -1;
							item1.NextList.map((item2,i2)=>{
								item2.checked = ids.indexOf(item2.Id) > -1;
								// if(item2.checked){
								// 	this.defaultKey.push(i,i1,i2)
								// }else{
								// 	this.defaultKey = [-1];
								// }
								return item2;
							})
							return item1;
						})
						return item;
					})
					
					
					if(ids&&ids.length){
						this.defaultKey=ids
					}else{
						this.defaultKey = [-1];
					}
					this.list = list;
          // console.log(this.list)
				} catch (e) {
					//TODO handle the exception
				}
		},
  }
}
</script>

<style lang = "less">
.list-box{
			display: flex;
			height: 60vh;
			.list-left{
				flex: 0 0 auto;
				width: 50%;
				height: 100%;
				overflow: hidden;
				border-right: 1px solid #ddd;
				box-sizing: border-box;
				
				display: flex;
				flex-direction: column;
				
				.flex0{
					flex: 0 0 auto;
				}
				.flex1{
					flex: 1 1 auto;
					overflow: auto;
				}
			}
			
			.list-right{
				flex: 0 0 auto;
				width: 50%;
				height: 100%;
				overflow: auto;
				padding-left: 20px;
				box-sizing: border-box;
				
				.title{
					margin-bottom: 15px;
				}
				
				.select-list{
					.select-item{
						position: relative;
						margin-bottom: 15px;
					}
					
					.el-icon-close{
						position: absolute;
						right: 20px;
						top: 0px;
						font-size: 18px;
						color: #999;
						cursor: pointer;
					}
				}
			}
}
.hd{
			width: 18px;
			height: 18px;
			border-radius: 2px;
			vertical-align: -3px;
		}
		.hd-normal{
			display: inline-block;
			width: 18px;
			height: 18px;
			line-height: 18px;
			text-align: center;
			font-size: 12px;
			color: #fff;
			border-radius: 2px;
			vertical-align: 1px;
			background-color: #409eff;
		}
    .custom-tree-node{
			flex: 1;
			display: flex;
			justify-content: space-between;
			padding-right: 10px;
		}
</style>