<template>
  <div>
    <el-dialog :title="title" :visible="choosestaffshow" width="70%"  @close="closestaff"  custom-class="staffpadding">
      <div class = "flexRow" style="flex-wrap:wrap">
        <div class=  "flexRow">
          <div class = "conditionsFont">关键字：</div>
          <el-input style="width:200px" v-model="staffkeywords" placeholder="群聊名称"></el-input>
        </div>
        <el-button type="primary" @click="querystaff" style="margin-left:10px">查询</el-button>
      </div>
      <div style="margin-top:10px">
        <el-table :data="stafftablelist" v-loading="stafftableloading" ref="stafftable"  :row-key="rowKeys"
         @selection-change = "selectionChange" max-height="500px">
          <el-table-column type="selection" width="55" :reserve-selection="true"></el-table-column>
          <el-table-column prop="GroupName" label="群名称" width="250px">
            <template slot-scope="scope">
              <div class = "flexRow">
                <img :src="groupImg" style="width:60px;height:60px;border-radius:3px" alt="">
                <div class = "flexRow" style="align-items:center;">
                  <div class = "vertical2 groupName">{{scope.row.GroupName}}</div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="EmployeeName" label="群主" width="200px">
            <template slot-scope="scope">
              <el-tag >
                <i class = "el-icon-user">{{scope.row.EmployeeName}}</i>
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="AddTime" label="建群时间"></el-table-column>
          <el-table-column prop="GroupUserCount" label="群内人数"></el-table-column>
        </el-table>
      </div>
      <div style="margin-top:10px" class="flexRow colbetween">
        <el-checkbox :value="staffallcheck" @change="allcheckchange" style="margin-left:15px">当前页全选</el-checkbox>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="sizepage"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
      <div style="text-align:center">
        已选中 <span style="color:rgb(245,108,108)">{{staffallchecklist.length}}</span> 个
        <el-button type="primary" style="width:90px;height:36px;margin-left:10px" @click="staffcomfire">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  qyweixinqyweixingroupfissionactivitychosegrouplist,
} from "@/api/sv1.0.0"

import {
		qyweixinrealgrouppagelist,
	} from "@/api/TurnTomySelf"
export default {
  props:{
    choosestaffshow:{
      type:Boolean,
      default:false,
    },
    selectlist:{
      type:Array,
      default:(()=>{
        return []
      })
    },
    title:{
      type:String,
      default:'选择群聊'
    }
  },
  computed:{
    staffallcheck(){
      // console.log(this.stafftablelist)
      if(this.stafftablelist&&this.stafftablelist.length){
        return this.stafftablelist.every((v)=>{
          return this.staffallchecklist.some((x)=>{
            return x.Id==v.RelationId || x.Id==v.Id
          })
        })
      }else{
        return false
      }
    },
  },
  watch:{
    choosestaffshow(){
      if(this.choosestaffshow){
        this.getqyweixinrealgrouppagelist()
        // console.log(this.selectlist)
      }
    },
  },
  data () {
    return {
      staffkeywords:'',
      staffallchecklist:[],
      stafftablelist:[],
      currentPage:1,
      sizepage:20,
      total:null,
      stafftableloading:false,
      groupImg: 'https://cdn.dkycn.cn/images/melyshop/grouplist.png',
    }
  },
  methods:{
    closestaff(){
      // this.choosestaffshow = false
      this.staffkeywords = ''
      this.stores = null
      this.currentPage = 1
      this.sizepage = 10
      this.staffmay = false
      this.$refs.stafftable.clearSelection()
      this.$emit('closegroup')
    },
    staffcomfire(){
      if(this.staffallchecklist.length<=0){
        this.$message.error('请选择群聊')
      }else{
        // this.ruForm.JoinGroupList = []
        // this.staffallchecklist = this.staffallchecklist.map((v,i)=>{
        //   v.QrCode = ''
        //   v.ExtensionState = ''
        //   v.QyWeixinRealGroupId = v.Id
        //   v.Sort = i
        //   return v
        // })
        // this.$forceUpdate()
        // //id倒序排列
        // this.ruForm.JoinGroupList.sort((a,b)=>{
        //   return b.QyWeixinRealGroupId-a.QyWeixinRealGroupId
        // })
        // console.log(this.ruForm.JoinGroupList.map((v)=>{return v.QyWeixinRealGroupId}))
        // this.choosestaffshow = false
        // this.staffallchecklist = []
        this.$emit('groupcomfire',this.staffallchecklist)
      }
    },
    rowKeys(row) {
      // console.log(row.Id)
				return row.Id
		},
    allcheckchange(e){
      this.$refs.stafftable.toggleAllSelection()
      // if(e.length>10){
      //   this.$message.error('每场活动最多选择10个群聊')
      // }
    },
    selectionChange(e){
      // console.log(e)
      this.staffallchecklist = e
      // if(e.length>10){
      //   this.$message.error('每场活动最多选择10个群聊')
      // }
    },
    handleSizeChange(e){
      this.currentPage = 1
      this.sizepage = e
      this.getqyweixinrealgrouppagelist()
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.getqyweixinrealgrouppagelist()
    },
    querystaff(){
      this.currentPage = 1
      this.getqyweixinrealgrouppagelist()
    },
    //群列表
    async getqyweixinrealgrouppagelist() {
				this.stafftableloading = true
        try{
          let data = {
            Keywords: this.staffkeywords,
            IsDissolution: null,
            IsQyWeixinRealGroupSelect: false,
            Skip: (this.currentPage - 1) * this.sizepage,
            Take: this.sizepage
          }
          // console.log(data)
          let result = await qyweixinrealgrouppagelist(data)
          if (result.IsSuccess) {
            // console.log(result.Result.Results)
            this.stafftablelist = result.Result.Results
            this.total = result.Result.Total
            let list = this.selectlist.map((v)=>{
              return v.RelationId
            })
            if(list&&list.length){
              this.$nextTick(()=>{
                this.stafftablelist.map((v,i)=>{
                  if(list.indexOf(v.Id)>-1){
                    this.$refs.stafftable.toggleRowSelection(v,true)
                  }
                })
              })
            }
          }
        }finally{
          this.stafftableloading = false
        }
			},
  }
}
</script>

<style lang = "less" scoped>
.flexRow{
    display: flex;
    flex-direction: row;
  }
  .staffpadding .el-dialog__body{
    padding:20px !important
  }
  .conditionsFont{
    font-size: 14px;
    line-height: 36px;
    font-weight: bold;
    color: #606266;
  }
  .groupName{
    width:150px;
    line-height: 1.3;
    margin-left:5px;
  }
  .vertical2{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  .colbetween{
    justify-content: space-between;
  }
</style>