<template>
  <div>
    <el-dialog :visible="visible" width="800px" title="选择员工活码" @close="closelivecode" v-loading="dialogloading">
      <div class = "flexRow">
        <div class = "flexRow">
          <div class = "keyfont">关键字：</div>
          <el-input v-model="keyword" placeholder="备注" style="width:180px"></el-input>
        </div>
        <el-button style="margin-left:10px" type="primary" @click="currentPage=1,gettablelist()">查询</el-button>
      </div>
      <div style="margin-top:10px">
        <el-table :data="tablelist" v-loading="tableloading" ref="table" @selection-change="handleSelectionChange" :row-key="getrowkey" max-height="500px">
          <el-table-column type="selection" width="55" reserve-selection></el-table-column>
          <el-table-column prop="Remark" label="备注"></el-table-column>
          <el-table-column prop="AddTime" label="添加时间"></el-table-column>
        </el-table>
      </div>
      <div style="margin-top:10px;text-align:right" v-if="total">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="sizepage"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
      <div style="margin-top:10px;text-align:right">
        <el-button @click="closelivecode">取消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
		qyWeixinContactMeListRequest,
	} from "@/api/sv1.0.0";
  import {qyWeixinContactMeQrCode} from '@/api/TurnTomySelf'
  import axios from 'axios'
export default {
  props: {
    visible:{
      type:Boolean,
      default:false,
    },
  },
  watch:{
    visible(val){
      if(val){
        this.gettablelist()
        // this.seltable = []
      }
    },
  },
  data () {
    return {
      dialogloading:false,
      keyword:'',
      tablelist:[],
      tableloading:false,
      seltable:[],
      currentPage:1,
      sizepage:10,
      total:null,
    }
  },
  methods: {
    async downloadCode() {
      this.dialogloading = true
      try{
        let data = {
          QyWeixinContactMeId:this.seltable[0].Id
        }
        let res = await qyWeixinContactMeQrCode(data)
        if (res.IsSuccess){
          // console.log(res.Result.QrCode,1)
          let result = await axios({url:
              'http://api.qidian.shop/short/link/?url='+res.Result.QrCode,
            method: "post",
            headers: {
            "Content-type": "multipart/form-data",
          },
          });
          // console.log(result,2)
          this.$emit('confirm',result.data.Url)
        }
      }finally{
        this.dialogloading = false
      }
		},
    confirm(){
      if(this.seltable&&this.seltable.length){
        this.downloadCode()
      }else{
        this.$message.error('请选择员工活码')
      }
      // this.$emit('confirm',this.seltable)
    },
    getrowkey(e){
      return e.Id
    },
    handleSelectionChange(e){
      // console.log(e)
      e.map((v)=>{
        if(e[e.length-1]==v){
          this.$refs.table.toggleRowSelection(v,true)
        }else{
          this.$refs.table.toggleRowSelection(v,false)
        }
      })
      this.seltable = [e[e.length-1]]
      // console.log(this.seltable)
    },
    closelivecode(){
      this.currentPage=1
      this.sizepage=10
      this.keyword=''
      this.$emit('closelivecode')
    },
    handleSizeChange(e){
      this.currentPage=1
      this.sizepage=e
      this.gettablelist()
    },
    handleCurrentChange(e){
      this.currentPage=e
      this.gettablelist()
    },
    async gettablelist(){
      this.tableloading=true
      try{
        let data = {
          KeyWords: this.keyword,
					Skip: (this.currentPage - 1) * this.sizepage,
					Take: this.sizepage
        }
        let result = await qyWeixinContactMeListRequest(data)
        if(result.IsSuccess){
          this.tablelist = result.Result.Results
          this.total = result.Result.Total
          // this.$nextTick(()=>{
          //   this.seltable.map((v)=>{
          //     this.$refs.table.toggleRowSelection(v,true)
          //   })
          // })
        }
      }finally{
        this.tableloading=false
      }
    }
  },
}
</script>

<style lang = "less" scoped>
  .flexRow{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .keyfont{
    font-size: 14px;
    color: #606266;
    font-weight: bold;
  }
</style>