<template>
  <div>
    <!-- <el-select v-model="ruform.AutoMarketingTaskConfigPushRuleEvent.PageRangeType" @change="choosepagetype" style="width:150px">
      <el-option :key="0" :value="0" label="商城所有页面"></el-option>
      <el-option :key="1" :value="1" :label="pagename.PagePath?pagename.PageName:'指定页面'"></el-option>
    </el-select> -->
    <el-popover :disabled="disabled" placement="bottom" v-model="popoverVisible" width="100" trigger="click">
      <div class="popover-wraper">
        <div :class="{popoverCurrent:ruleForm.PageRangeType === 0}" @click="rangeTypeChange(0)">商城所有页面</div>
        <div :class="{popoverCurrent:ruleForm.PageRangeType === 1}"  @click="rangeTypeChange(1)">指定商城页面</div>
      </div>
      <div slot="reference">
        <div class="page" v-if="!disabled">
          <span v-if="!ruleForm.PageName">商城所有页面</span>
          <span v-else>{{ruleForm.PageName}}</span>
          <i class="el-icon-arrow-down"></i>
        </div>
        <div class="page" v-else style="background-color:#F5F7FA;">
          <span style="color:#C0C4CC;" v-if="!ruleForm.PageName">商城所有页面</span>
          <span style="color:#C0C4CC;" v-else>{{ruleForm.PageName}}</span>
          <i class="el-icon-arrow-down"  style="color:#C0C4CC;"></i>
        </div>
      </div>
    </el-popover>


    <selectPage :visible="dialogVisible" @close="dialogVisible=false" @change="dataChange"></selectPage>
  </div>
</template>

<script>
import selectPage from '@/views/Customer/autoAddTag/components/components/selectPage.vue';
export default {
  props: {
    ruform:{
      type:Object,
      default:()=>{
        return {}
      },
    },
    disabled:{
      type:Boolean,
      default:false
    }
  },
  components: {
    selectPage
  },
  data () {
    return {
      popoverVisible:false,
      ruleForm:{
        PageRangeType:0,
        RelationId:0,
        PagePath:'',
        PageName:'',
      },
      dialogVisible:false,
    }
  },
  created () {
    this.ruleForm.PageRangeType = this.ruform.AutoMarketingTaskConfigPushRuleEvent.PageRangeType
    if(this.ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList[0]){
      this.ruleForm.PageName = this.ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList[0].PageName
      this.ruleForm.PagePath = this.ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList[0].PagePath
      this.ruleForm.RelationId = this.ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList[0].RelationId;
    }
    
    // console.log(this.ruleForm.PageRangeType,this.ruleForm.PageName)
    // if(this.ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList[0]){
    //   this.ruleForm = this.ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList[0]
    // }
    // console.log(this.ruleForm)
  },
  methods: {
    // choosepagetype(e){
    //   if(e==0){
    //     this.$emit('emptypage')
    //   }else{
    //     this.dialogVisible = true
    //   }
    // },
    dataChange(e){
      for (let item in e){
        this.ruleForm[item] = e[item];
      }
      this.ruleForm.PageName = e.pageName
      // console.log(this.ruleForm)
      this.$emit('getpagetype',this.ruleForm)
    },
    rangeTypeChange(index){
      if (index === 0){
        this.ruleForm.PagePath = '';
        this.ruleForm.RelationId = '';
        this.ruleForm.PageName = '';
        this.ruleForm.PageRangeType = 0;
        // console.log(1)
        this.$emit('getpagetype',this.ruleForm)
      }else{
        this.ruleForm.PageRangeType = 1
        this.handleShowPop();
      }
      this.popoverVisible = false;
    },
    handleShowPop(){
			this.dialogVisible = true;
		},
  }
}
</script>

<style lang = "less" scoped>
.popover-wraper{
		div{
			height: 36px;
			line-height: 36px;
			cursor: pointer;
		}
		.popoverCurrent{
			color: #409EFF;
		}
	}
	
	.customer-sex {
		padding: 15px 20px 1px;

		.title {
			font-size: 14px;
			margin-bottom: 7px;
		}

		.tip {
			font-size: 13px;
			color: #999;
			line-height: 18px;
		}
		
		.flex{
			display: flex;
			line-height: 36px;
			white-space: nowrap;
			flex-wrap: wrap;
		}
		.txt{
			height: 36px;
			line-height: 36px;
			white-space: nowrap;
		}
		.flex-box{
			flex-wrap: wrap;
			border-bottom: 1px dashed #ddd;
			margin-top: 15px;
		}
		.delete{
			height: 36px;
			line-height: 36px;
			font-size: 20px;
			margin-left: 30px;
			color: #999;
			cursor: pointer;
		}
		
		.add{
			margin: 15px 0;
		}
	}
	
	.page{
		width: 180px;
		height: 36px;
		padding: 0 10px;
		border: 1px solid #ddd;
		display: flex;
		align-items: center;
		border-radius: 4px;
		overflow: hidden;
		
		span{
			flex: 1 1 auto;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			margin-right: 10px;
      color: #606266;
		}
		i{
			flex: 0 0 auto;
		}
}
.grayfont{
  color: #666666;
}
</style>