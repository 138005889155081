<template>
  <div>
    <el-form :model="step1ruform" :rules="rules" label-width="150px" ref="step1form">
      <div>
        <el-form-item label="任务名称：" prop="Name">
          <el-input :disabled="disabled&&step1ruform.State!=1" v-model="step1ruform.Name" placeholder="任务名称最多20个字" maxlength="20" style="width:300px">
          </el-input>
          <div class="grayfont" style="margin:5px 0px">给本次自动营销任务设置一个名称，例如：近7日下单未支付客户转化任务</div>
        </el-form-item>
        <el-form-item label="转化跟踪：">
          <el-switch v-model="step1ruform.IsOpenTransform" :disabled="disabled"></el-switch>
          <div class="grayfont" style="margin:5px 0px;max-width:800px">
            在自动营销任务中开启转化跟踪，可以设置本次任务的主要转化目标（比如：客户收到推送内容后，在7天内完成会员身份激活），同时系统会根据设置的目标，在数据分析中自动生成一个转化漏斗并计算转化率。
          </div>
        </el-form-item>
        <el-form-item label="转化目标：" v-if="step1ruform.IsOpenTransform">
          <div class="flexRow">
            <span class="targetfont">客户收到推送内容后，在</span>
            <el-form-item label-width="0px" prop="TransformWithinDay">
              <el-input :disabled="disabled" v-model="step1ruform.TransformWithinDay" style="width:100px;margin:0px 5px">
              </el-input>
            </el-form-item>
            <span class="targetfont">天内，完成</span>
            <el-select :disabled="disabled" v-model="step1ruform.CompleteTargetType" placeholder="请选择一个目标" style="margin:0px 5px">
              <el-option v-for="v in CompleteTargetTypelist" :key="v.value" :value="v.value"
                :label="v.label"></el-option>
            </el-select>
          </div>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    ruform:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    rules:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    disabled:{
      type:Boolean,
      default:false,
    }
  },
  created () {
    this.step1ruform = JSON.parse(JSON.stringify(this.ruform))
  },
  watch:{
    ruform(){
      this.step1ruform = JSON.parse(JSON.stringify(this.ruform))
    },
  },
  data () {
    return {
      step1ruform:{},
      CompleteTargetTypelist: [{
						value: 1,
						label: '访问小程序商城'
					},
					{
						value: 2,
						label: '下单成功'
					},
					{
						value: 3,
						label: '支付成功'
					},
					{
						value: 4,
						label: '成为推手'
					},
					{
						value: 5,
						label: '成为会员'
					},
					{
						value: 6,
						label: '添加员工企业微信'
					},
					{
						value: 7,
						label: '加入企业微信客户群'
					},
				],
    }
  },
  methods: {
    validation(){
      return new Promise((res,rej)=>{
        this.$refs['step1form'].validate((valid) => {
          if (valid) {
            res(this.step1ruform)
          } else {
            rej('请先完善第1步任务基本信息的配置')
          }
        });
      })
      
    },
  }
}
</script>

<style lang = "less" scoped>
  .grayfont {
		font-size: 12px;
		color: #999999;
		line-height: 1.5;
	}
  .flexRow{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .targetfont {
		color: #606266;
	}
</style>