<template>
  <div>
    <el-dialog :visible="qywxvisible" title="企业微信客户群发" @close="closeqywx" width="1000px">
			<div class = "flexRow">
				<div style="flex:1;">
					<el-form :model="welruform" :rules="welrules" ref="welruform" label-width="100px">
						<el-form-item prop="Welcome" label="群发内容：">
							<div class="edit-wraper">
								<div class="edit-box" ref="editBox" @blur="editBlur" contenteditable="plaintext-only"	@input="checkLength"></div>
								<div class="num">{{length}}/1000</div>
								<div class="link-wraper">
					
									<div class="file-list" style="max-width: 600px;" v-if="fileList.length">
										<div class="file-item" v-for="(item, index) in fileList" :key="index">
											<div class="type">
												<span v-if="item.Type == 1">【图片】</span>
												<span v-if="item.Type == 2">【链接】</span>
												<span v-if="item.Type == 3">【小程序】</span>
											</div>
											<div class="txt">：{{item.LinkName}}</div>
											<div class="control">
												<div class="edit" v-if="item.Type != 1" @click="handleEditFile(item, index)">
													<i class="el-icon-edit"></i>
												</div>
												<el-upload :action="imgApi" v-else :before-upload="beforeUpload"
													:on-success="(res, file)=>{handleUploadSuccess(res, file, index)}"
													accept="image/png,image/jpeg,image/jpg,image/gif" :show-file-list="false">
													<div class="edit">
														<i class="el-icon-edit"></i>
													</div>
												</el-upload>
												<div class="close" @click="handleDeleteFile(index)">
													<i class="el-icon-close"></i>
												</div>
											</div>
										</div>
									</div>
					
									<el-popover placement="top" v-model="showPop" width="200" trigger="click" v-if="fileList.length < 9">
										<div class="control-wraper">
											<el-upload :action="imgApi" :before-upload="beforeUpload"
												:on-success="(res, file)=>{handleUploadSuccess(res, file, -1)}"
												accept="image/png,image/jpeg,image/jpg,image/gif" :show-file-list="false">
												<div class="item">
													<img src="@/assets/img/icon-tupian.png">
													<div>图片</div>
												</div>
											</el-upload>
											<div class="item" @click="handleAddLink">
												<img src="@/assets/img/icon-lianjie.png">
												<div>链接</div>
											</div>
											<div class="item" @click="handleAddMiniProgram">
												<img src="@/assets/img/icon-xiaochengxu.png">
												<div>小程序</div>
											</div>
										</div>
										<el-link type="primary" slot="reference">+添加图片/链接/小程序
										</el-link>
									</el-popover>
								</div>
							</div>
						</el-form-item>
						<div class = "flexRow" style="margin:30px 0px 0px 100px">
							<div class = "grayfont">
								提示：
							</div>
							<div>
								<div class = "grayfont">1.客户群发内容最多可发送1条文字消息和9个附件</div>
								<div class = "grayfont">2.文本内容不可为空；若添加多个附件（图片/链接/小程序），客户将收到多条消息</div>
							</div>
						</div>
					</el-form>
				</div>
				<div class = "imgbox">
					<img :src="imgurl+'/image/qywxconfig2.png'" style="width:100%;" alt="">
					<div class = "imgposition">
						<div class = "imghead">
							<div style="width:37px;height:37px">
								<img :src="imgurl+'/image/qywxhead.png'" alt="">
							</div>
							<!-- <div class="edit-box imgcontent" ref="editBox" style="white-space: pre-wrap;" contenteditable="plaintext-only"></div> -->
							<div class = "imgcontent" contenteditable ref="welshowfont" style="white-space: pre-wrap;">
							</div>
						</div>
						<div class = "imghead" v-if="fileList.some(v =>{return v.Type==1})"
							v-for="(m,n) in fileList.filter(res =>{return res.Type==1})" :key="'type1'+n">
							<div style="width:37px;height:37px">
								<img :src="imgurl+'/image/qywxhead.png'" alt="">
							</div>
							<div class = "setimg">
								<img :src="imgurl+m.ImgUrl" style="width:100%" alt="">
							</div>
						</div>
						<div class = "imghead" v-if="fileList.some(v =>{return v.Type==2})"
							v-for="(x,y) in fileList.filter(res =>{return res.Type==2})" :key="'type2'+y">
							<div style="width:37px;height:37px">
								<img :src="imgurl+'/image/qywxhead.png'" alt="">
							</div>
							<div class = "imgcontent" style="background:white;border:none;width:200px" >
								<div class = "link-top font-hidden1">{{x.LinkName}}</div>
								<div class = "linek-bottom" style="margin-top:5px">
									<div class ="linek-bottom-font font-hidden3" style="flex:1;margin-right:5px">
										{{x.Description}}
									</div>
									<img :src="imgurl+(x.ImgUrl?x.ImgUrl:'/image/defaultlink.png')" style="width:40px;height:40px" alt="">
								</div>
							</div>
						</div>
						<div class = "imghead" v-if="fileList.some(v =>{return v.Type==3})"
							v-for="(c,z) in fileList.filter(res =>{return res.Type==3})" :key="'type3'+z">
							<div style="width:37px;height:37px">
								<img :src="imgurl+'/image/qywxhead.png'" alt="">
							</div>
							<div class = "imgcontent pageshare">
								<div class ="pageshare-top">
									<img :src="imgurl+'/image/pagesharetoplogo.png'" style="width:20px;height:20px" alt="">
									<div class="grayfont" style="flex:1;margin-left:5px">小程序名称</div>
								</div>
								<div class = "shareDescription font-hidden1">{{c.Description}}</div>
								<img :src="imgurl+(c.ImgUrl?c.ImgUrl:'/image/shareimg.png')" style="width:150px;height:130px" alt="">
								<div class ="rowline"></div>
								<div class = "pageshare-bottom">
									<img :src="imgurl+'/image/pagesharebottom.png'" style="width:10px;height:10px;margin-left:10px" alt="">
									<div class="grayfont" style="flex:1;margin-left:5px">小程序</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div style="text-align:center">
				<el-button @click="closeqywx">取消</el-button>
				<el-button type="primary" @click="confirmhtml('welruform')">确定</el-button>
			</div>
    </el-dialog>

		<!-- 链接 -->
		<link-pop :visible="miniVisible" :defaultData="editData" @close="miniVisible=false" @change="miniChange"></link-pop>
			
		<!-- 小程序 -->
		<miniprogram-card :visible="cardVisible" :defaultData="editData" @close="cardVisible=false" @change="cardChange"></miniprogram-card>
  </div>
</template>

<script>
import config from "@/config"
import linkPop from './miniprogramLink.vue';
import miniprogramCard from './miniprogramCard.vue';
export default {
	components: {
		linkPop,
		miniprogramCard
	},
  props: {
    qywxvisible:{
      type:Boolean,
      default:false,
    },
    Welcome:{
      type:String,
      default:''
    },
		rule:{
			type:Array,
			default:()=>{
				return []
			}
		},
		List:{
			type:Array,
			default:()=>{
				return []
			}
		}
  },
  data () {
		var checkWelcome = (rule, value, callback) => {
      // console.log(value,123)
      let testnull = /^ [\s]*$/
      // console.log(!testnull.test(value))
      if(!this.filterlabel(value)||testnull.test(value)){
        callback(new Error('企业微信客户群发文本内容不可为空'))
      }else{
        callback()
      }
    }
    return {
			imgurl:config.IMG_BASE,
      imgApi: config.UPLOAD_IMG,
      showPop:false,
      length:0,
			miniVisible:false,
			cardVisible:false,
			editData: {},
			fileList:[],
			welruform:{
				fileList:[],
				Welcome:''
			},
			welrules:{
				Welcome:[
					{ required: true, message: '企业微信客户群发文本内容不可为空', trigger: 'blur' },
          { required: true, validator: checkWelcome, trigger: 'blur' },
				]
			}
    }
  },
	watch: {
		Welcome(){
			this.welruform.Welcome = this.Welcome
			document.querySelector('.edit-box').innerHTML = this.Welcome
			this.$refs.welshowfont.innerHTML = this.Welcome
			this.length = this.filterlabel(document.querySelector('.edit-box').innerHTML).length
		},
	},
	created () {
		this.$nextTick(()=>{
			this.welruform.Welcome = this.Welcome
			document.querySelector('.edit-box').innerHTML = this.Welcome
			this.$refs.welshowfont.innerHTML = this.Welcome
			this.length = this.filterlabel(document.querySelector('.edit-box').innerHTML).length
			this.fileList = this.List

		})
	},
  methods: {
		confirmhtml(formName){
			this.welruform.Welcome = document.querySelector('.edit-box').innerHTML
			// console.log(this.welruform.Welcome)
			this.$refs[formName].validate((valid) => {
          if (valid) {
						// console.log(valid)
            let data = {
							innerhtml:this.filterlabel(document.querySelector('.edit-box').innerHTML),
							fileList:this.fileList
						}
						// console.log(data)
						this.$emit('confirmhtml',data)
          } else {
            return false;
          }
      });
		},
    closeqywx(){
			this.welruform.Welcome = ""
			document.querySelector('.edit-box').innerHTML = ""
			this.fileList = []
			this.$refs['welruform'].clearValidate();
      this.$emit('closeqywx')
    },
		filterlabel(e){
			// console.log(e)
				let str  = JSON.parse(JSON.stringify(e))
				let newe = str.replace(/<[^<>]+>/g,'')
				// console.log(newe)
				return newe
    },
    checkLength() {
				var html = document.querySelector('.edit-box').innerHTML;
				// html = html.replace('#客户微信昵称#', '').replace('#员工姓名#', '');
				this.$refs.welshowfont.innerHTML = html
				this.length = this.filterlabel(html).length
				this.welruform.Welcome = document.querySelector('.edit-box').innerHTML
				// console.log(document.querySelector('.edit-box').innerHTML)
		},
    editBlur() {
				var html = document.querySelector('.edit-box').innerHTML;
				if (html.length > 1000) {
					document.querySelector('.edit-box').innerHTML = html.substr(0, 1000);
				}
				this.length = this.filterlabel(document.querySelector('.edit-box').innerHTML).length
				this.welruform.Welcome = document.querySelector('.edit-box').innerHTML
				this.$refs.welshowfont.innerHTML = html
				// this.$emit('gethtml',document.querySelector('.edit-box').innerHTML)
		},
    handleEditFile(record, index){
				this.editIndex = index;
				if (record.Type == 2){
					this.miniVisible = true;
					this.editData = record;
				}else if (record.Type == 3){
					this.cardVisible = true;
					this.editData = record;
				}
		},
    beforeUpload(file) {
				const isLt2M = file.size / 1024 / 1024 < 2;

				const testtype = ['image/bmp', 'image/png', 'image/tif', 'image/gif', 'image/jpeg', 'image/jpg',
					'image/webp'
				]
				if (testtype.indexOf(file.type) == -1) {
					this.$message.error('请选择图片上传');
					return false
				} else if (!isLt2M) {
					this.$message.error('图片大小请控制在2M以内')
					return false
				}
				return true;
		},
    handleUploadSuccess(res, file, index) {
				var obj = {
					Type: 1, //1 图片类型
					LinkName: file.name,
					ImgUrl: res[0]
				}
				
				if (index == -1){
					this.fileList.push(obj)
				}else{
					this.fileList.splice(index, 1, obj)
				}
				
				this.showPop = false;
			},
      handleDeleteFile(index) {
				this.fileList.splice(index, 1)
		},
    handleAddLink(){
				this.editIndex = -1;
				this.miniVisible = true;
				this.editData = {};
			},
			handleAddMiniProgram(){
				this.editIndex = -1;
				this.cardVisible = true;
				this.editData = {};
		},
		miniChange(record) {
				
				var obj = {
					Type: 2, //2 链接
					LinkName: record.name,
					ImgUrl: record.imgUrl,
					LinkUrl: record.url,
					Description: record.desc
				}
				
				if (this.editIndex == -1){
					this.fileList.push(obj)
				}else{
					this.fileList.splice(this.editIndex, 1, obj)
				}
				this.$emit('getfilelist',this.fileList)
		},
		cardChange(record) {
				
				var obj = {
					Type: 3, //3 小程序
					LinkName: record.name,
					LinkUrl: record.url,
					Description: record.desc,
					ImgUrl: record.imgUrl,
				}
				
				if (this.editIndex == -1){
					this.fileList.push(obj)
				}else{
					this.fileList.splice(this.editIndex, 1, obj)
				}
				this.$emit('getfilelist',this.fileList)
		},
  }
}
</script>

<style lang = "less" scoped>
.flexRow{
	display: flex;
	flex-direction: row;
	align-items: flex-start;
}
.grayfont{
	font-size: 12px;
  color: #999999;
  line-height: 1.5;
}
.tips-box {
			padding: 10px 15px;
			background-color: #fff;
			border: 1px solid #409EFF;
			background-color: #FBFDFF;
			font-size: 14px;
			line-height: 24px;
			color: #606266;

			display: flex;

			.tit {
				flex: 0 0 auto;
				width: 50px;
			}

			.cont {
				flex: 1 1 auto;
				overflow: hidden;
			}
		}
			.example{
				flex: 0 0 auto;
				
				img{
					display: block;
					width: 350px;
				}
			}

		.tips-box {
			padding: 10px 15px;
			background-color: #fff;
			border: 1px solid #409EFF;
			background-color: #FBFDFF;
			font-size: 14px;
			line-height: 24px;
			color: #606266;

			display: flex;

			.tit {
				flex: 0 0 auto;
				width: 50px;
			}

			.cont {
				flex: 1 1 auto;
				overflow: hidden;
			}
		}

		.employee-list {
			margin-left: 10px;
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			.employee-item {
				display: flex;
				align-items: center;
				height: 26px;
				margin-right: 10px;
				margin-bottom: 5px;
				margin-top: 5px;
				padding: 0 5px;
				font-size: 12px;
				border: 1px solid #ddd;
				border-radius: 3px;

				.e-hd {
					width: 18px;
					height: 18px;
					overflow: hidden;
					border-radius: 2px;
					margin-right: 5px;
					background-color: #F6F6F6;
				}

				.e-no-hd {
					display: inline-block;
					width: 18px;
					height: 18px;
					line-height: 18px;
					text-align: center;
					font-size: 12px;
					color: #fff;
					border-radius: 2px;
					vertical-align: 1px;
					background-color: #409eff;
					margin-right: 5px;
				}

				.e-delete {
					margin-left: 5px;
					font-size: 14px;
					cursor: pointer;
				}


			}

			.show-more-box {
				transform: rotate(-90deg);
				margin-top: 2px;
				cursor: pointer;
				margin-right: 4px;
			}

			.show-more {
				font-size: 16px;
				color: #999;

			}
		}

		.edit-wraper {
			border: 1px solid #ddd;
			background-color: #FBFDFF;
			line-height: 18px;
			border-radius: 4px;
			position: relative;

			.title {
				padding: 15px 15px;
				display: flex;
				border-bottom: 1px dashed #bbb;
			}

			.num {
				position: absolute;
				right: 10px;
				top: 274px;
				color: #999;
			}

			.link-wraper {
				padding: 15px;
				border-top: 1px solid #ddd;


			}

			.file-list {
				overflow: hidden;
				
				.file-item {
					display: flex;
					align-items: center;
					overflow: hidden;
					margin-bottom: 10px;
					// background: #cccbcb;
					// padding: 5px 10px;
					color: #666;
					font-size: 12px;

					>img {
						flex: 0 0 auto;
						width: 20px;
						margin-right: 5px;
					}
					
					.type{
						flex: 0 0 auto;
						width: 60px;
					}

					.txt {
						flex: 1 1 auto;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						// max-width: 80%;
					}
					.control{
						flex: 0 0 auto;
						margin-left: 10px;
						display: flex;
						
					}
					.edit {
						cursor: pointer;
						font-size: 16px;
					
						display: flex;
						align-items: center;
					}
					.close {
						margin-left: 5px;
						cursor: pointer;
						font-size: 16px;

						display: flex;
						align-items: center;
					}
				}
			}
		}

		.edit-box {
			height:304px;
			padding: 15px;
			box-sizing: border-box;
			overflow: auto;
		}

  .control-wraper {
		display: flex;

		.item {

			width: 60px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			font-size: 12px;
			cursor: pointer;

			img {
				width: 24px;
				margin-bottom: 4px;
			}
		}
	}
</style>
<style lang = "less" scoped>
.font-hidden1{
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.font-hidden3{
	display:-webkit-box;
	overflow:hidden;
	text-overflow:ellipsis;
	-webkit-line-clamp:3;
	-webkit-box-orient:vertical;
}
.imgbox{
	position: relative;
	left: 20px;
	padding-right: 20px;
	width:320px;
	.imgposition{
		position: absolute;
		right: 40px;
		top: 80px;
		/* border:1px solid black; */
		height: 435px;
		width:280px;
		overflow: hidden;
		overflow-y: auto;
		.imghead{
			display: flex;
			flex-direction: row-reverse;
			align-items: flex-start;
			margin-top: 20px;
		}
		.imgcontent{
			margin-right: 15px;
			background: #d0e6fd;
			padding: 7px 10px;
			font-size: 14px;
			color: #303133;
			line-height: 1.5;
			border-radius: 3px;
			border:1px solid #c4d8f0;
			min-height: 37px;
			min-width: 32px;
			max-width: 220px;
		}
		.imgcontent::after{
			content: '';
			position: absolute;
			width: 10px;
			height: 10px;
			right: 48px;
			top: 34px;
			background: #d0e6fd;
			transform: rotate(45deg);
		}
		.setimg{
			max-width: 120px;
			margin-right: 15px;
		}
		.link-top{
			font-size: 14px;
			color: #303133;
			text-align: left;
		}
		.linek-bottom{
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			.linek-bottom-font{
				font-size: 12px;
				color: #666666;
				line-height: 1.3;
			}
		}
		.pageshare{
			width:180px;
			background: white;
			border-color: white;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 5px 10px 2px 10px;
			.pageshare-top{
				display: flex;
				flex-direction: row;
				align-items: center;
				width: 100%;
			}
			.shareDescription{
				font-size: 14px;
				color: #303133;
				margin:5px 0px;
				width: 100%;
			}
			.rowline{
				width: 150px;
				height: 1px;
				background: #dddddd;
				border-radius: 50%;
				margin: 10px 0px;
			}
			.pageshare-bottom{
				display: flex;
				flex-direction: row;
				align-items: center;
				width: 100%;
			}
		}
	}
	

}
</style>