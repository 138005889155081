import { render, staticRenderFns } from "./productTable.vue?vue&type=template&id=29a7eec2&scoped=true"
import script from "./productTable.vue?vue&type=script&lang=js"
export * from "./productTable.vue?vue&type=script&lang=js"
import style0 from "./productTable.vue?vue&type=style&index=0&id=29a7eec2&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29a7eec2",
  null
  
)

export default component.exports