<template>
  <div>
    <el-dialog :visible="smsvisible" title="短信群发" @close="closesms" width="1000px">
			<div class = "flexRow">
				<div style="flex:1">
					<el-form :model="welruform" :rules="welrules" ref="welruform" label-width="100px">
						<el-form-item label="短信签名：">
							<div>
								<div class = "flexRow" style="align-items:center" v-if="!inputshow">
									<div>{{welruform.HeadSign}}</div>
									<i class = "el-icon-edit" style="margin-left:10px" @click="showinput"></i>
								</div>
								<div class = "flexRow" style="align-items:center" v-else>
									<el-input v-model.trim="inputmsg" style="width:150px" placeholder="请输入签名"></el-input>
									<el-button type="text" style="margin:0px 5px 0px 10px" @click="focuslabel">保存</el-button>
									<el-button type="text" style="color:#303133" @click="inputshow=false">取消</el-button>
								</div>
							</div>
						</el-form-item>
						<el-form-item prop="Welcome" label="短信内容：">
							<div class="edit-wraper">
								<div class="edit-box" ref="editBox" @blur="editBlur" contenteditable="plaintext-only"
									@input="checkLength"></div>
								<!-- <div class="edit-box"></div> -->
								<div class="num">{{length}}/500</div>
								<div class="link-wraper">
					
									<div class="file-list" style="align-items:flex-start" v-if="welruform.file.url">
										<div class="file-item">
											<div class="type">
												<span >【小程序】</span>
											</div>
											<div class="txt">：{{welruform.file.name}}</div>
										</div>
										<div class="control" >
											<div class="close" @click="handleDeleteFile">
												<i class="el-icon-close"></i>
											</div>
										</div>
									</div>
									<el-link type="primary" slot="reference" @click="linkVisible=true">插入商城链接</el-link>
									<el-link type="primary" slot="reference" style="margin-left:10px" @click="livecodeshow=true">插入员工活码</el-link>
								</div>
							</div>
						</el-form-item>
						<div class = "flexRow" style="margin:30px 0px 0px 100px">
							<!-- <div class = "grayfont">
								提示：
							</div> -->
							<div>
								<div class = "grayfont">包含签名和尾缀，已输入<span style="color:#409eff">
									{{length+welruform.HeadSign.length+welruform.Tail.length+1}}</span>个字，按
								<span style="color:#409eff">{{smsnum}}</span>条短信发送和计费
								</div>
							</div>
						</div>
					</el-form>
				</div>
				<div class = "imgbox">
					<img :src="imgurl+'/image/smsconfig2.png'" style="width:100%;" alt="">
					<div class = "imgposition">
						<div class = "contentbox" >
							{{welruform.HeadSign}}
							<span ref="smsfonttext" style="white-space: pre-wrap;">{{welruform.Welcome}}</span>
							{{welruform.Tail}}
						</div>
					</div>
				</div>
			</div>
			<div style="text-align:center">
				<el-button @click="closesms">取消</el-button>
				<el-button type="primary" @click="confirmhtml('welruform')">确定</el-button>
			</div>
    </el-dialog>

		<!-- 链接 -->
		<!-- <link-pop :visible="miniVisible" :defaultData="editData" @close="miniVisible=false" @change="miniChange"></link-pop> -->
			
		<!-- 小程序 -->
		<!-- <miniprogram-card :visible="cardVisible" :defaultData="editData" @close="cardVisible=false" @change="cardChange"></miniprogram-card> -->

		<!-- 小程序页面链接 -->
		<link-modal :unshowTab="[5]" :unfunctionalTab="[3,7,8,12,14,15]" :visible="linkVisible"
			@change="dataChange" @cancel="linkVisible=false"></link-modal>

		<!-- 活码 -->
		<livecode :visible="livecodeshow" @closelivecode="livecodeshow=false" @confirm="confirm"></livecode>
  </div>
</template>

<script>
import config from "@/config"
// import linkPop from './miniprogramLink.vue';
import miniprogramCard from './miniprogramCard.vue';
import linkModal from './linkModal';
import livecode from "../livecode"

export default {
	components: {
		// linkPop,
		miniprogramCard,
		linkModal,
		livecode
	},
  props: {
    smsvisible:{
      type:Boolean,
      default:false,
    },
    Welcome:{
      type:Object,
      default:()=>{
				return {}
			}
    },
		rule:{
			type:Array,
			default:()=>{
				return []
			}
		},
		// file:{
		// 	type:Object,
		// 	default:()=>{
		// 		return {}
		// 	}
		// }
  },
  data () {
		var checkWelcome = (rule, value, callback) => {
      // console.log(value,123)
      let testnull = /^ [\s]*$/
      // console.log(!testnull.test(value))
      if(!this.filterlabel(value)||testnull.test(value)){
        callback(new Error('短信内容不可为空'))
      }else{
        callback()
      }
    }
    return {
			imgurl:config.IMG_BASE,
      imgApi: config.UPLOAD_IMG,
      showPop:false,
      length:0,
			miniVisible:false,
			cardVisible:false,
			editData: {},
			fileList:[],
			welruform:{
				file:{
					url:'',
					name:'',
					desc:'',
					Type:3
				},
				link:'',
				pagelink:'',
				// file:[],
				Welcome:'',
				HeadSign:'',
				Tail:'',
				SMSContentAttachInfo:{
					MallLinkUrl:'',//商城链接,
					EmployeeActiveCodeUrl:'',// 员工活码链接
				}
			},
			welrules:{
				Welcome:[
					{ required: true, message: '短信内容不可为空', trigger: 'blur' },
          { required: true, validator: checkWelcome, trigger: 'blur' },
				]
			},
			linkVisible: false,

			inputshow:false,
			inputmsg:'',

			livecodeshow:false,
    }
  },
	inject:['configmsg'],
	computed:{
		smsnum(){
			let length = this.length+this.welruform.HeadSign.length+(this.welruform.Tail.length+1)
			let num = 1
			if(length <= 70){
				num = 1
			}else{
				num = Math.ceil(length/67)
			}
			// console.log(num)
			return num
		},
		allconfigmsg(){
      return this.configmsg()
    },
	},
	watch: {
		Welcome(){
			this.init()
		},
	},
	created () {
		this.$nextTick(()=>{
			this.init()
		})
	},
  methods: {
    init(){
			this.welruform.Welcome = this.Welcome.Content?this.Welcome.Content:''
			this.welruform.HeadSign = this.Welcome.HeadSign?this.Welcome.HeadSign:'【'+this.allconfigmsg.MallName+'】'
			this.welruform.Tail = this.Welcome.Tail?this.Welcome.Tail:'拒收请回复R'
			this.welruform.SMSContentAttachInfo = this.Welcome.SMSContentAttachInfo?this.Welcome.SMSContentAttachInfo:{
				MallLinkUrl:'',//商城链接,
				EmployeeActiveCodeUrl:'',// 员工活码链接
			}
			document.querySelector('.edit-box').innerHTML = this.Welcome.Content?this.Welcome.Content:''
			this.$refs.smsfonttext.innerHTML = document.querySelector('.edit-box').innerHTML
			let len = this.filterlabel(document.querySelector('.edit-box').innerHTML)
			this.length = len.length?len.length:0
		},
		//员工活码  确定
		confirm(url){
			// console.log(url)
			let html = document.querySelector('.edit-box').innerHTML
			let oldlink = html.indexOf(url)>-1
			if(!html){
				oldlink = false
			}
			// console.log(url,'url')
			// console.log(html,'html')
			// console.log(oldlink)
			if(oldlink){
				document.querySelector('.edit-box').innerHTML=document.querySelector('.edit-box').innerHTML.replace(oldlink,url)
				this.welruform.Welcome=this.welruform.Welcome.replace(oldlink,url)
			}else{
				this.welruform.Welcome += url
				document.querySelector('.edit-box').innerHTML += url
			}
			this.welruform.SMSContentAttachInfo.EmployeeActiveCodeUrl = url
			this.length = this.filterlabel(document.querySelector('.edit-box').innerHTML).length
			this.livecodeshow = false
		},
		showinput(){
			this.inputmsg = this.welruform.HeadSign.substring(1,this.welruform.HeadSign.length-1)
			this.inputshow = true
		},
		focuslabel(){
			if(this.inputmsg.length>=2 && this.inputmsg.length<=12){
				this.welruform.HeadSign = '【'+this.inputmsg+'】'
				this.inputshow = false
				this.inputmsg = ''
			}else{
				this.$message.error('签名字数请输入2~12个字之间')
			}
		},
		//页面链接 确定
		dataChange(url){
			let html = document.querySelector('.edit-box').innerHTML
			let oldlink = html.indexOf(url)>-1
			if(!html){
				oldlink = false
			}
			if(oldlink){
				document.querySelector('.edit-box').innerHTML=document.querySelector('.edit-box').innerHTML.replace(oldlink,url)
				this.welruform.Welcome=this.welruform.Welcome.replace(oldlink,url)
			}else{
				// console.log(document.querySelector('.edit-box').innerHTML)
				this.welruform.Welcome += url
				document.querySelector('.edit-box').innerHTML += url
				console.log(document.querySelector('.edit-box').innerHTML,url)
			}
			this.welruform.SMSContentAttachInfo.MallLinkUrl = url
			this.length = this.filterlabel(document.querySelector('.edit-box').innerHTML).length
			this.linkVisible = false
		},
		getLinkUrl(record) {
				if (record.type == "classify") {
					return 'pages/productGroup/productGroup?id=' + record.data.Id
				} else if (record.type == "functional") {
					// 全部商品
					if (record.data.id == 1) {
						return 'pages/index/index'
					} else if (record.data.id == 2) {
						return 'pages/allProduct/allProduct'
					} else if (record.data.id == 3) {
						// 个人中心
						return 'pages/personalCenter/mine/mine'
					} else if (record.data.id == 4) {
						// 购物车
						return 'pages/shoppingCart/shoppingCart'
					} else if (record.data.id == 5) {
						// 积分中心
						return 'pages/pointsCenter/pointsCenter'
					} else if (record.data.id == 6) {
						// 每日签到
						return 'pages/userSign/userSign'
					} else if (record.data.id == 7) {
						// 客服
						return 'pages/customerService/customerService'
					} else if (record.data.id == 8) {
						// 订单列表
						return 'pages/order/myOrder/myOrder'
					} else if (record.data.id == 9) {
						// 分销礼包
						return 'pages/invitation/invitationCard/invitationCard'
					} else if (record.data.id == 10) {
						// 成为会员
						return 'pageA/pages/openSuccess/openSuccess'
					} else if (record.data.id == 11) {
						// 助力直播列表
						return 'pageA/pages/live/live'
					} else if (record.data.id == 12) {
						// 扫码购
						return 'pageA/pages/scan/scan'
					} else if (record.data.id == 13) {
						// 充值中心
						return 'pageA/pages/balanceRecharge/balanceRecharge'
					} else if (record.data.id == 14) {
						// 群分享
						return 'pageA/pages/groupShare/groupShare'
					} else if (record.data.id == 15) {
						// 全部信息页
						return 'pageA/pages/information/information'
					}else if (record.data.id == 16) {
						// 短视频带货
						return 'pageC/pages/shortVideos/record'
					}else if (record.data.id == 17) {
						// 积分码
						return 'pageA/pages/receive-point/receive-point'
					}else if (record.data.id == 18) {
						// 舌诊
						return 'pageC/pages/tongueDiagnosis/home'
					}else if (record.data.id == 19) {
						// 企店即创主页
						return 'pageC/pages/AIGC/home'
					}
				} else if (record.type == "product") {
					return 'pages/detail/detail?id=' + record.data.Id
				} else if (record.type == "custom") {
					return 'pages/cusPage/cusPage?id=' + record.data.Id
				} else if (record.type == 'truntable') {
					return 'pageA/pages/activity/truntable/truntable?id=' + record.data.Id
				} else if (record.type == 'live') {
					return 'plugin-private://wx2b03c6e691cd7370/pages/live-player-plugin?room_id=' + record.data.RoomId
				} else if (record.type == 'coupon') {
					return 'pages/couponCenter/receive/receive?id=' + record.data.Id + '&uid=' + (record.data.uid || '')
				} else if (record.type == 'help') {
					return 'pages/helpDetail/helpDetail?id=' + record.data.Id + '&activityId=' + record.data.ActivityFullId
				} else if (record.type == 'group') {
					return 'pages/groupDetail/groupDetail?id=' + record.data.ProductId + '&fullId=' + record.data
						.ActivityFullId
				} else if (record.type == 'questionnaire') {
					return 'pageA/pages/questionnaire/questionnaire?&referer=5&id=' + record.data.Id;
				}else if (record.type == 'taketest') {
					return `pageC/pages/goodnessTest/home?id=` + record.data.Id
				}else if (record.type == 'contentgroup') {
					return `pageA/pages/information/informationGroup?id=` + record.data.Id;
				}else if (record.type == 'singlecontent') {
					return `pageA/pages/information/detail?id=` + record.data.Id;
				}else if (record.type == 'helpPro') {
					return `pageC/pages/helpPro/helpPro?id=` + record.data.ProductId + '&activityId=' + record.data.ActivityFullId
				}
				return ''
		},
		confirmhtml(formName){
			this.welruform.Welcome = this.filterlabel(document.querySelector('.edit-box').innerHTML)
			this.$refs[formName].validate((valid) => {
          if (valid) {
            let data = this.welruform
						this.$emit('confirmsms',data)
          } else {
            return false;
          }
      });
		},
    closesms(){
			this.welruform.Welcome = ""
			document.querySelector('.edit-box').innerHTML = ""
			this.welruform.file = {
					url:'',
					name:'',
					desc:'',
					Type:3
			}
			this.$refs['welruform'].clearValidate();
      this.$emit('closesms')
    },
		filterlabel(e){
			// console.log(e)
				let str  = JSON.parse(JSON.stringify(e))
				let newe = str.replace(/<[^<>]+>/g,'')
				// console.log(newe)
				return newe
    },
    insertWxNickName() {
				document.querySelector('.edit-box').innerHTML += ' <span style="color: #409EFF;">#客户微信昵称#</span> ';
				var html = document.querySelector('.edit-box').innerHTML;
				this.length = this.filterlabel(html).length
				// this.$emit('gethtml',html)
		},
		insertEmployeeName() {
				document.querySelector('.edit-box').innerHTML += ' <span style="color: #409EFF;">#员工姓名#</span> ';
				var html = document.querySelector('.edit-box').innerHTML;
				this.length = this.filterlabel(html).length
				// this.$emit('gethtml',html)
		},
    checkLength() {
				var html = document.querySelector('.edit-box').innerHTML;
				// html = html.replace('#客户微信昵称#', '').replace('#员工姓名#', '');
				this.length = this.filterlabel(html).length
				this.welruform.Welcome = document.querySelector('.edit-box').innerHTML
				this.$refs.smsfonttext.innerHTML = document.querySelector('.edit-box').innerHTML
				// console.log(document.querySelector('.edit-box').innerHTML)
		},
    editBlur() {
				var html = document.querySelector('.edit-box').innerHTML;
				if (html.length > 500) {
					document.querySelector('.edit-box').innerHTML = html.substr(0, 500);
				}
				this.length = this.filterlabel(document.querySelector('.edit-box').innerHTML).length
				this.welruform.Welcome = document.querySelector('.edit-box').innerHTML
				this.$refs.smsfonttext.innerHTML = document.querySelector('.edit-box').innerHTML
				// this.$emit('gethtml',document.querySelector('.edit-box').innerHTML)
		},
    beforeUpload(file) {
				const isLt2M = file.size / 1024 / 1024 < 2;

				const testtype = ['image/bmp', 'image/png', 'image/tif', 'image/gif', 'image/jpeg', 'image/jpg',
					'image/webp'
				]
				if (testtype.indexOf(file.type) == -1) {
					this.$message.error('请选择图片上传');
					return false
				} else if (!isLt2M) {
					this.$message.error('图片大小请控制在2M以内')
					return false
				}
				return true;
		},
    handleUploadSuccess(res, file, index) {
				var obj = {
					Type: 1, //1 图片类型
					LinkName: file.name,
					ImgUrl: res[0]
				}
				
				if (index == -1){
					this.fileList.push(obj)
				}else{
					this.fileList.splice(index, 1, obj)
				}
				
				this.showPop = false;
			},
      handleDeleteFile(index) {
				this.welruform.file = {
				url:'',
					name:'',
					desc:'',
					Type:3
			}
		},
    handleAddLink(){
				this.editIndex = -1;
				this.miniVisible = true;
				this.editData = {};
			},
			handleAddMiniProgram(){
				this.editIndex = -1;
				this.cardVisible = true;
				this.editData = {};
		},
		miniChange(record) {
				
				var obj = {
					Type: 2, //2 链接
					LinkName: record.name,
					ImgUrl: record.imgUrl,
					LinkUrl: record.url,
					Description: record.desc
				}
				
				if (this.editIndex == -1){
					this.fileList.push(obj)
				}else{
					this.fileList.splice(this.editIndex, 1, obj)
				}
				this.$emit('getfilelist',this.fileList)
		},
		cardChange(record) {
				
				var obj = {
					Type: 3, //3 小程序
					LinkName: record.name,
					LinkUrl: record.url,
					Description: record.desc,
					ImgUrl: record.imgUrl,
				}
				
				if (this.editIndex == -1){
					this.fileList.push(obj)
				}else{
					this.fileList.splice(this.editIndex, 1, obj)
				}
				this.$emit('getfilelist',this.fileList)
		},
  }
}
</script>

<style lang = "less" scoped>
.flexRow{
	display: flex;
	flex-direction: row;
	align-items: flex-start;
}
.grayfont{
	font-size: 12px;
  color: #999999;
  line-height: 1.5;
}
.tips-box {
			padding: 10px 15px;
			background-color: #fff;
			border: 1px solid #409EFF;
			background-color: #FBFDFF;
			font-size: 14px;
			line-height: 24px;
			color: #606266;

			display: flex;

			.tit {
				flex: 0 0 auto;
				width: 50px;
			}

			.cont {
				flex: 1 1 auto;
				overflow: hidden;
			}
		}
			.example{
				flex: 0 0 auto;
				
				img{
					display: block;
					width: 350px;
				}
			}

		.tips-box {
			padding: 10px 15px;
			background-color: #fff;
			border: 1px solid #409EFF;
			background-color: #FBFDFF;
			font-size: 14px;
			line-height: 24px;
			color: #606266;

			display: flex;

			.tit {
				flex: 0 0 auto;
				width: 50px;
			}

			.cont {
				flex: 1 1 auto;
				overflow: hidden;
			}
		}

		.employee-list {
			margin-left: 10px;
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			.employee-item {
				display: flex;
				align-items: center;
				height: 26px;
				margin-right: 10px;
				margin-bottom: 5px;
				margin-top: 5px;
				padding: 0 5px;
				font-size: 12px;
				border: 1px solid #ddd;
				border-radius: 3px;

				.e-hd {
					width: 18px;
					height: 18px;
					overflow: hidden;
					border-radius: 2px;
					margin-right: 5px;
					background-color: #F6F6F6;
				}

				.e-no-hd {
					display: inline-block;
					width: 18px;
					height: 18px;
					line-height: 18px;
					text-align: center;
					font-size: 12px;
					color: #fff;
					border-radius: 2px;
					vertical-align: 1px;
					background-color: #409eff;
					margin-right: 5px;
				}

				.e-delete {
					margin-left: 5px;
					font-size: 14px;
					cursor: pointer;
				}


			}

			.show-more-box {
				transform: rotate(-90deg);
				margin-top: 2px;
				cursor: pointer;
				margin-right: 4px;
			}

			.show-more {
				font-size: 16px;
				color: #999;

			}
		}

		.edit-wraper {
			border: 1px solid #ddd;
			background-color: #FBFDFF;
			line-height: 18px;
			border-radius: 4px;
			
			position: relative;

			.title {
				padding: 15px 15px;
				display: flex;
				border-bottom: 1px dashed #bbb;
			}

			.num {
				position: absolute;
				right: 10px;
				top: 274px;
				color: #999;
			}

			.link-wraper {
				padding: 15px;
				border-top: 1px solid #ddd;


			}

			.file-list {
				overflow: hidden;
				display: flex;
				justify-content: space-between;
				.file-item {
					display: flex;
					align-items: center;
					overflow: hidden;
					margin-bottom: 10px;
					// background: #cccbcb;
					// padding: 5px 10px;
					color: #666;
					font-size: 12px;

					>img {
						flex: 0 0 auto;
						width: 20px;
						margin-right: 5px;
					}
					
					.type{
						flex: 0 0 auto;
						width: 60px;
					}

					.txt {
						flex: 1 1 auto;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						// max-width: 80%;
					}
					.control{
						flex: 0 0 auto;
						margin-left: 10px;
						display: flex;
						
					}
					.edit {
						cursor: pointer;
						font-size: 16px;
					
						display: flex;
						align-items: center;
					}
					.close {
						margin-left: 5px;
						cursor: pointer;
						font-size: 16px;

						display: flex;
						align-items: center;
					}
				}
			}
		}

		.edit-box {
			height: 304px;
			padding: 15px;
			box-sizing: border-box;
			overflow: auto;
		}

  .control-wraper {
		display: flex;

		.item {

			width: 60px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			font-size: 12px;
			cursor: pointer;

			img {
				width: 24px;
				margin-bottom: 4px;
			}
		}
	}
</style>
<style lang = "less" scoped>
	.imgbox{
	position: relative;
	left: 20px;
	padding-right: 20px;
	width:320px;
	.imgposition{
		position: absolute;
		left: 0px;
		top: 110px;
		/* border:1px solid black; */
		height:390px;
		width:295px;
		overflow: hidden;
		overflow-y: auto;
		.contentbox{
			max-width: 250px;
			min-width: 50px;
			min-height: 40px;
			background: #E9E9EB;
			border-radius: 10px 10px 10px 10px;
			opacity: 1;
			margin:50px 0px 0px 20px;
			padding: 10px;
			font-size: 14px;
			color: #606266;
			line-height: 1.5;
		}
	}
	

}
</style>