<template>
	<el-dialog :visible="visible"  title="配置链接" width="640px" @close="handleClose">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
			<el-form-item label="链接地址:" prop="url">
				<el-input v-model="ruleForm.url" maxlength="1000" @blur="getUrlData" placeholder="请输入链接地址" style="width: 400px;">
				</el-input>
			</el-form-item>
			<el-form-item label="链接名称:" prop="name">
				<el-input v-model="ruleForm.name" maxlength="30" placeholder="请输入链接名称,最多30个字" style="width: 400px;">
				</el-input>
			</el-form-item>
			<el-form-item label="链接描述:" prop="desc">
				<el-input v-model="ruleForm.desc" maxlength="60" placeholder="请输入链接描述,最多60个字" style="width: 400px;">
				</el-input>
			</el-form-item>
			<el-form-item label="链接封面:" prop="imgUrl">
				<el-upload class="avatar-uploader" :action="imgApi" :before-upload="beforeUpload"
					:on-success="handleUploadSuccess" accept="image/png,image/jpeg,image/jpg,image/gif"
					:show-file-list="false">
					<img v-if="ruleForm.imgUrl" :src="imgUrl + ruleForm.imgUrl" class="avatar">
					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
				<div class="tip" style="font-size: 12px;color: #999;margin-top: -5px;line-height: 18px">图片大小请控制在1M以内</div>
			</el-form-item>
		</el-form>

		<span slot="footer" class="dialog-footer">
			<el-button @click="handleClose">取 消</el-button>
			<el-button type="primary" @click="handleSureSelect">确 定</el-button>
		</span>
	</el-dialog>
</template>

<script>
	import config from '@/config/index';
	import axios from 'axios';
	
	import {
		htmlinfobylink
	} from '@/api/sv1.0.0.js';
	
	export default {
		props: {
			visible: {
				type: Boolean,
				default: false
			},
			defaultData: {
				type: Object,
				default: ()=>{
					return {}
				}
			}
		},
		components: {},
		data() {

			return {
				imgApi: config.UPLOAD_IMG,
				imgUrl: config.IMG_BASE,
				ruleForm: {
					url: '',
					name: '',
					desc: '',
					imgUrl: ''
				},
				rules: {
					url: [{
						required: true,
						message: '请输入链接地址',
						trigger: 'blur'
					}],
					name: [{
						required: true,
						message: '请输入链接名称',
						trigger: 'blur'
					}]
				},

			}
		},
		created() {},
		beforeMount() {},
		mounted() {},
		watch: {
			visible(){
				if (this.visible){
					this.ruleForm.url = this.defaultData.LinkUrl;
					this.ruleForm.name = this.defaultData.LinkName;
					this.ruleForm.desc = this.defaultData.Description;
					this.ruleForm.imgUrl = this.defaultData.ImgUrl;
				}
			}
		},
		methods: {
			beforeUpload(file) {
				const isLt1M = file.size / 1024 / 1024 < 1;

				const testtype = ['image/bmp', 'image/png', 'image/tif', 'image/gif', 'image/jpeg', 'image/jpg',
					'image/webp'
				]
				if (testtype.indexOf(file.type) == -1) {
					this.$message.error('请选择图片上传');
					return false
				} else if (!isLt1M) {
					this.$message.error('封面图大小请控制在1M以内')
					return false
				}
				return true;
			},
			handleUploadSuccess(res, file) {
				this.ruleForm.imgUrl = res[0]
			},
			handleClose() {
				this.ruleForm.url = '',
				this.ruleForm.name = ''
				this.ruleForm.desc = ''
				this.ruleForm.imgUrl = ''
				this.$refs.ruleForm.resetFields()
				this.$emit('close')
			},
			handleSureSelect() {
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						var obj = JSON.parse(JSON.stringify(this.ruleForm));
						this.$emit('change', obj);
						this.$refs['ruleForm'].resetFields();
						this.handleClose();
					}
				});
				
			},
			async getUrlData(){
				try{
					const res = await htmlinfobylink({
						Link: this.ruleForm.url
					});
					
					var html = res.Result.HtmlInfo;
	
					let domparser = new DOMParser();
					let doc = domparser.parseFromString(html, 'text/html')

					this.ruleForm.name = doc.querySelector('head title').innerHTML;
					
					var metaList = doc.querySelectorAll('meta');
					for (var i=0; i<metaList.length; i++){
						if (metaList[i].getAttribute('name') && metaList[i].getAttribute('name').toLowerCase() == 'description'){
							this.ruleForm.desc = metaList[i].getAttribute('content')
						}
					}

				}catch(e){
					//TODO handle the exception
				}
			}
		}
	}
</script>
<style scoped>
.el-form-item .el-form-item{
	margin-bottom: 22px;
}
</style>
<style lang="less">
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 138px;
		height: 138px;
		line-height: 138px;
		text-align: center;
	}

	.avatar {
		width: 138px;
		height: 138px;
		display: block;
	}
</style>
