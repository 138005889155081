<template>
  <div>
    <el-form :model="step3ruform" :rules="rules" ref="step3ruform" label-width="150px">
      <div>
        <el-form-item label="推送规则：" style="margin:0px 0px 10px 0px">
          <el-radio-group :disabled="disabled" v-model="step3ruform.PushRuleType" @change="changePushRuleType">
            <el-radio :label="1">定时仅推送一次</el-radio>
            <el-radio :label="2">设置周期循环推送</el-radio>
            <el-radio v-if="step3ruform.TargetMemberType!=6&&allconfigmsg.AutoMarketingTaskIsOpenMemberEvent" :label="3">客户行为触发 <span
                style="color:#999999;font-size:14px">(当客户做过某事)</span>
              <el-tooltip class="item" effect="light" content="" placement="top">
                <div slot="content">
                  例如：给在双十一期间添加了员工企业微信的客户推送返场活动信息
                  <!-- <el-link href="https://jusnn6k8al.feishu.cn/docs/doccnXFGFeA0f0YCVmqiOOcuZmc"
                    type="primary" target="_blank" :underline="false">
                    查看更多配置
                  </el-link> -->
                </div>
                <i class="el-icon-question" style="font-size:16px;margin-left:10px"></i>
              </el-tooltip>
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- validthird -->
        <div v-if="step3ruform.PushRuleType==1">
          <el-form-item label="选择时间：">
            <div class="flexRow">
              <el-select :disabled="disabled" v-model="step3ruform.AutoMarketingTaskConfigPushRuleOnce.PushTimeType"
                style="width:120px" @change="changePushTimeType">
                <el-option v-for="v in onetimelist" :key="v.value" :value="v.value"
                  :label="v.label"></el-option>
              </el-select>
              <el-form-item style="margin:0px 10px" label-width="0px"
                v-if="step3ruform.AutoMarketingTaskConfigPushRuleOnce.PushTimeType==2"
                prop="StartTime"
                :rules="newrules.PushTimeType1starttime">
                <!-- 刷新之后 选择立即推送 再选回定时推送  时间无法选择 强制更新之后 自定义验证无法获取value -->
                <!-- 所以选择时赋值给step3ruform.StartTime 验证时直接验证这个字段 -->
                <el-date-picker :disabled="disabled" v-model="step3ruform.StartTime" @input="dateinput" popper-class="timeclass"
                  :picker-options="pickerOptions2" type="datetime" placeholder="请选择日期时间" format="yyyy-MM-dd HH:mm" key="timingtimeselect"
                  value-format="yyyy-MM-dd HH:mm" :clearable="false">
                </el-date-picker>
              </el-form-item>
            </div>
          </el-form-item>
        </div>
        <div v-if="step3ruform.PushRuleType==2">
          <el-form-item label="设置周期：">
            <div class="flexRow">
              <span style="color:#606266">从</span>
              <el-form-item label-width="0px" prop="StartTime"
                :rules="newrules.daterange">
                <el-date-picker :disabled="disabled" v-model="daterange" type="daterange" style="margin:0px 10px"  :picker-options="pickerOptions1"
                  range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" key="cycletimeselect"
                  end-placeholder="结束日期" @change="changedaterange" :clearable="false">
                </el-date-picker>
              </el-form-item>
              <span style="color:#606266">时间范围内，</span>
              <el-select :disabled="disabled" v-model="step3ruform.AutoMarketingTaskConfigPushRuleLoop.PeriodType"
                style="width:150px" @change="changecyclestate">
                <el-option v-for="v in cyclestatelist" :key="v.value" :value="v.value"
                  :label="v.label"></el-option>
              </el-select>
              <div style="margin-left:10px">
                <div v-if="step3ruform.AutoMarketingTaskConfigPushRuleLoop.PeriodType==1">
                  <el-select :disabled="disabled" v-model="step3ruform.AutoMarketingTaskConfigPushRuleLoop.PeriodNum" style="width:150px">
                    <el-option v-for="(v,i) in cycledaylistweek" :key="'cycledaylistweek'+i"
                      :value="v.value" :label="v.label"></el-option>
                  </el-select>
                </div>
                <div v-if="step3ruform.AutoMarketingTaskConfigPushRuleLoop.PeriodType==2">
                  <el-select :disabled="disabled" v-model="step3ruform.AutoMarketingTaskConfigPushRuleLoop.PeriodNum" style="width:150px">
                    <el-option v-for="(v,i) in cycledaylistmonth" :key="'cycledaylistmonth'+i"
                      :value="v.value" :label="v.label"></el-option>
                  </el-select>
                </div>
              </div>
              <div style="margin-left:10px">
                <el-form-item label-width="0px"
                  prop="AutoMarketingTaskConfigPushRuleLoop.PeriodTimeHour"
                  :rules="rules.PeriodTimeHour">
                  <el-time-picker :disabled="disabled" v-model="PeriodTime" placeholder="请选择时间" value-format="HH:mm" format="HH:mm"
                    style="width:150px" :picker-options="pickerOptionstime1" key="specifictimeselect"
                    @change="changePeriodTime">
                  </el-time-picker>
                </el-form-item>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="推送次数：">
            <div class="flexRow">
              <el-radio :disabled="disabled" v-model="pushnumbertype" :label="1" @change="changepushnumbertype">
                每个目标客户，在周期内，最多收到</el-radio>
              <el-form-item prop="AutoMarketingTaskConfigPushRuleLoop.PushCount"
                :rules="newrules.PushCount">
                <el-input :disabled="disabled" v-model="step3ruform.AutoMarketingTaskConfigPushRuleLoop.PushCount"
                  style="margin:0px 10px 0px 0px;width:100px"></el-input>
              </el-form-item>
              <span style="color:#606266">次推送</span>
            </div>
            <div class="flexRow" style="margin-top:20px">
              <el-radio :disabled="disabled" v-model="pushnumbertype" :label="2" @change="changepushnumbertype">不限制
              </el-radio>
            </div>
          </el-form-item>
          <div class="flexRow" style="margin-left:70px;align-items:flex-start">
            <div class="grayfont">推送次数说明：</div>
            <div>
              <div class="grayfont">1.若开启了转化追踪，当目标推送客户完成设置的目标后，在周期内将不再推送</div>
              <div class="grayfont">2.若未开启转化追踪，在周期内重复推送，直到您设置的次数</div>
            </div>
          </div>
        </div>
        <div v-if="step3ruform.PushRuleType==3&&step3ruform.TargetMemberType!=6">
          <el-form-item label="设置事件：">
            <div class="flexRow" style="margin:0px 0px 20px 0px;">
              <span style="color:#606266">在</span>
              <el-form-item label-width="0px" prop="StartTime"
                :rules="newrules.daterange">
                <el-date-picker :disabled="disabled" v-model="daterange" type="daterange" style="margin:0px 10px" :picker-options="pickerOptions1"
                  range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" key="eventtimeselect"
                  end-placeholder="结束日期" @change="changedaterange" :clearable="false">
                </el-date-picker>
              </el-form-item>
              <span style="color:#606266">时间范围内，</span>
            </div>
            <div class="flexRow" style="margin:10px 0px">
              <span style="color:#606266">当客户做过</span>
              <el-select :disabled="disabled" v-model="step3ruform.AutoMarketingTaskConfigPushRuleEvent.Type"
                style="margin-left:10px" @change="changetype">
                <el-option v-for="v in somethinglist" :key="v.value" :value="v.value"
                  :label="v.label"></el-option>
              </el-select>
            </div>
            <div class="flexRow" style="margin:10px 0px 0px 0px">
              <div v-if="step3ruform.AutoMarketingTaskConfigPushRuleEvent.Type==1||step3ruform.AutoMarketingTaskConfigPushRuleEvent.Type==2">
                <div class="flexRow" style="margin:0px 0px 15px 0px">
                  <span style="color:#606266">并且，累计{{step3ruform.AutoMarketingTaskConfigPushRuleEvent.Type==1?'支付':'订单'}}金额</span>
                  <interval :disabled="disabled" :message="step3ruform" :rules="rules" :type="1"></interval>
                </div>
              </div>
              <div v-if="step3ruform.AutoMarketingTaskConfigPushRuleEvent.Type==3||step3ruform.AutoMarketingTaskConfigPushRuleEvent.Type==4">
                <div class="flexRow" style="margin:0px 0px 20px 0px">
                  <span
                    style="color:#606266">并且，以下任一商品，{{step3ruform.AutoMarketingTaskConfigPushRuleEvent.Type==3?'购买次数':'加购次数'}}</span>
                  <interval :disabled="disabled" :message="step3ruform" :rules="rules" :type="2"></interval>
                </div>
                <div>
                  <div class="flexRow" style="margin:10px 0px 0px 0px">
                    <span style="color:#606266">选择</span>
                    <el-select :disabled="disabled" @change="changeProductRangeType"
                      v-model="step3ruform.AutoMarketingTaskConfigPushRuleEvent.ProductRangeType"
                      style="margin:0px 10px;width:150px">
                      <el-option v-for="v in somethingchooseoptlist" :key="v.value" 
                        :value="v.value" :label="v.label"></el-option>
                    </el-select>
                    <el-form-item label-width="0px" prop="AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigDetailRelationIdList"
                    :rules="newrules.chooseshoplist" v-if="step3ruform.AutoMarketingTaskConfigPushRuleEvent.ProductRangeType==0" style="width:80px">
                      <el-button type="text" @click="chooseshopshow=true" v-if="!disabled">选择商品</el-button>
                    </el-form-item>
                    <el-form-item label-width="0px" prop="AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigDetailRelationIdList"
                    :rules="newrules.chooseshoplist" v-if="step3ruform.AutoMarketingTaskConfigPushRuleEvent.ProductRangeType==1">
                      <el-select :disabled="disabled" v-model="chooseshoplist" @change="changechooseshoplist">
                        <el-option v-for="(v,i) in allgroupList" :key="i" :value="v.Id" :label="v.GroupName"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label-width="0px"  prop="AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigDetailRelationIdList"
                    :rules="newrules.chooseshoplist" v-if="step3ruform.AutoMarketingTaskConfigPushRuleEvent.ProductRangeType==2">
                      <el-select :disabled="disabled" v-model="choosebrand" @change="changechoosebrand">
                        <el-option v-for="(v,i) in allbrandList" :key="i" :value="v.Id" :label="v.BrandName"></el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div style="margin-top:20px;width:700px">
                    <!-- :disabled="disabled" -->
                    <product-table ref="giftTable"  :disabled="disabled"
                      v-if="step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList.length && step3ruform.AutoMarketingTaskConfigPushRuleEvent.ProductRangeType===0"
                      :gifts="step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList"
                      @refresh="handleSelect"></product-table>
                  </div>
                </div>
              </div>
              <div v-if="step3ruform.AutoMarketingTaskConfigPushRuleEvent.Type==6">
                <!-- 未绑定公众号 -->
                <div style="margin-left:80px" v-if="!allconfigmsg.IsBindWxPublicPlatform">
                  <div class = "grayfont">商城还未绑定公众号，该事件无法正常使用。</div>
                  <div style="margin-top:20px">
                    <el-link href="https://jusnn6k8al.feishu.cn/docs/doccnRpRrrnWv1pumx3wj6j7nRb" type="primary"
                     target="_blank" style="line-height:1.5;" :underline="false">1.如何绑定公众号？</el-link>
                  </div>
                  <div style="margin-top:-15px">
                    <el-button type="text" @click="jump(1)">2.前往绑定公众号>>></el-button>
                  </div>
                </div>
              </div>
              <div v-if="step3ruform.AutoMarketingTaskConfigPushRuleEvent.Type==7||step3ruform.AutoMarketingTaskConfigPushRuleEvent.Type==8">
                <span
                  style="color:#606266">并且，{{step3ruform.AutoMarketingTaskConfigPushRuleEvent.Type==7?'会员':'推手'}}级别是以下选中的任一级别：</span>
                <div style="margin:10px 0px 0px 0px"
                  v-if="step3ruform.AutoMarketingTaskConfigPushRuleEvent.Type==7">
                  <el-form-item label-width="0px"
                    prop="AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList"
                    :rules="rules.typemember">
                    <el-checkbox-group :disabled="disabled" v-model="typechoosememberlist"
                      @change="changetypemember">
                      <el-checkbox v-for="v in allmemberlist" :key="v.Id" :label="v.Id">
                        {{v.VipRoleName}}</el-checkbox>
                    </el-checkbox-group>
                  </el-form-item>
                </div>
                <div style="margin:10px 0px 0px 0px"
                  v-if="step3ruform.AutoMarketingTaskConfigPushRuleEvent.Type==8">
                  <el-form-item label-width="0px"
                    prop="AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList"
                    :rules="rules.typedriver">
                    <el-checkbox-group :disabled="disabled" v-model="typechoosedriverlist"
                      @change="changetypedriver">
                      <el-checkbox v-for="v in alldriverlist" :key="v.Id" :label="v.Id">
                        {{v.DistributRoleName}}</el-checkbox>
                    </el-checkbox-group>
                  </el-form-item>
                </div>
              </div>
              <div v-if="step3ruform.AutoMarketingTaskConfigPushRuleEvent.Type==9||step3ruform.AutoMarketingTaskConfigPushRuleEvent.Type==10">
                <div v-if="!allconfigmsg.IsInstallQidianHelper">
                  <div style="margin-left:80px">
                    <div class="grayfont">商城还未安装企店助手，该事件无法正常使用。</div>
                    <div style="margin-top:20px">
                      <el-link href="https://jusnn6k8al.feishu.cn/docs/doccnNEfNiX3HufF1R7f8NLEAvh" type="primary" target="_blank"
                        style="line-height:1.5;" :underline="false">1.什么是企店助手？如何安装？
                      </el-link>
                    </div>
                    <div style="margin-top:-15px">
                      <el-link type="primary" @click="jump(2)"
                        style="line-height:1.5" :underline="false">2.前往安装企店助手>>>
                      </el-link>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div v-if="step3ruform.AutoMarketingTaskConfigPushRuleEvent.Type==9">
                    <el-form-item label-width="0px" prop="AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList"
                     :rules="rules.choosestaff">
                      <div class="flexRow">
                        <span style="color:#606266">并且，添加已选中的任一员工：</span>
                        <el-button v-if="!disabled" type="text" @click="importVisible=true">选择员工</el-button>
                      </div>
                    </el-form-item>
                    <div class="flexRow" style="flex-wrap:wrap;max-width:70%">
                      <!-- <el-tag @close="closeemployeestag(i)" :key="v.Id" style="margin:0px 5px 5px 0px" :closable = "!disabled"
                      v-for="(v,i) in step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList">
                      {{v.Name}}</el-tag> -->
                      <div class="employee-item" v-for="(item, index) in step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList" :key="index">
                        <img class="e-hd" v-if="item.Type===3 && item.HeadUrl" :src="item.HeadUrl" />
                        <span class="e-no-hd" v-if="item.Type===3 && !item.HeadUrl">员</span>
                        <i class="el-icon-s-shop e-shop" v-if="item.Type!==3" style="color: #409eff;font-size: 20px;vertical-align: -2px;"></i>
                        {{item.Name}}
                        <i class="el-icon-close e-delete" v-if="!disabled" @click="closeemployeestag(index)"></i>
                      </div>
                    </div>
                  </div>
                  <div v-if="step3ruform.AutoMarketingTaskConfigPushRuleEvent.Type==10">
                    <div>
                      <el-form-item label-width="0px" prop="AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList"
                     :rules="rules.choosegroup">
                        <div class="flexRow">
                          <span style="color:#606266">并且，加入已选中的任一群聊：</span>
                          <el-button v-if="!disabled" type="text" @click="choosestaffshow=true">选择群聊</el-button>
                        </div>
                      </el-form-item>
                      <div class="flexRow" style="flex-wrap:wrap;max-width:70%">
                        <!-- <el-tag
                          v-for="(v,i) in step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList"
                          :key="v.Id" style="margin:0px 5px 5px 0px" :closable="!disabled"
                          @close="closegrouptag(i)">{{v.RelationValue}}</el-tag> -->
                          <div class="employee-item" v-for="(item, index) in step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList" :key="index">
                            <img class="e-hd" src="@/assets/img/50px.png" />
                            <!-- <span class="e-no-hd" v-if="item.Type===3 && !item.HeadUrl">员</span>
                            <i class="el-icon-s-shop e-shop" v-if="item.Type!==3" style="color: #409eff;font-size: 20px;vertical-align: -2px;"></i> -->
                            <span style="font-size:12px;color:#606266">{{item.RelationValue}}</span>
                            <i class="el-icon-close e-delete" v-if="!disabled" @click="closegrouptag(index)"></i>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="step3ruform.AutoMarketingTaskConfigPushRuleEvent.Type==11">
                <div class = "flexRow">
                  <span style="color:#606266">并且，</span>
                  <choosepage :disabled="disabled" :ruform="step3ruform" @getpagetype="getpagetype"></choosepage>
                  <span style="color:#606266">（总）浏览次数</span>
                </div>
                <div style="margin:10px 0px 15px 0px">
                  <interval :disabled="disabled" :message="step3ruform" :rules="rules" :type="2"></interval>
                </div>
              </div>
              <div v-if="step3ruform.AutoMarketingTaskConfigPushRuleEvent.Type==12">
                <div class = "flexRow" style="margin:0px 0px 10px 0px">
                  <span style="color:#606266">并且，以下任一商品，浏览次数</span>
                  <interval :disabled="disabled" :message="step3ruform" :rules="rules" :type="2"></interval>
                </div>
                <div style="margin:20px 0px 0px 45px">
                  <div>
                    <div class="flexRow" style="margin:10px 0px 0px 0px">
                      <span style="color:#606266">选择</span>
                      <el-select :disabled="disabled" @change="changeProductRangeType"
                        v-model="step3ruform.AutoMarketingTaskConfigPushRuleEvent.ProductRangeType"
                        style="margin:0px 10px;width:150px">
                        <el-option v-for="v in somethingchooseoptlist" :key="v.value"
                          :value="v.value" :label="v.label"></el-option>
                      </el-select>
                      <el-form-item label-width="0px" prop="AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigDetailRelationIdList"
                      :rules="newrules.chooseshoplist" v-if="step3ruform.AutoMarketingTaskConfigPushRuleEvent.ProductRangeType==0" style="width:80px">
                        <el-button v-if="!disabled" type="text" @click="chooseshopshow=true">选择商品</el-button>
                      </el-form-item>
                      <el-form-item label-width="0px" prop="AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigDetailRelationIdList"
                      :rules="newrules.chooseshoplist" v-if="step3ruform.AutoMarketingTaskConfigPushRuleEvent.ProductRangeType==1">
                        <el-select :disabled="disabled" v-model="chooseshoplist" @change="changechooseshoplist">
                          <el-option v-for="(v,i) in allgroupList" :key="i" :value="v.Id" :label="v.GroupName"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label-width="0px"  prop="AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigDetailRelationIdList"
                      :rules="newrules.chooseshoplist" v-if="step3ruform.AutoMarketingTaskConfigPushRuleEvent.ProductRangeType==2">
                        <el-select :disabled="disabled" v-model="choosebrand" @change="changechoosebrand">
                          <el-option v-for="(v,i) in allbrandList" :key="i" :value="v.Id" :label="v.BrandName"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div style="margin-top:20px;width:700px">
                      <!-- :disabled="disabled" -->
                      <product-table ref="giftTable" :disabled="disabled"
                        v-if="step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList.length && step3ruform.AutoMarketingTaskConfigPushRuleEvent.ProductRangeType===0"
                        :gifts="step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList"
                        @refresh="handleSelect"></product-table>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="step3ruform.AutoMarketingTaskConfigPushRuleEvent.Type==13">
                <div class = "flexRow">
                  <span style="color:#606266">并且，</span>
                  <choosepage :disabled="disabled" :ruform="step3ruform" @getpagetype="getpagetype"></choosepage>
                  <span style="color:#606266">（总）分享次数</span>
                </div>
                <div style="margin:10px 0px">
                  <interval :disabled="disabled" :message="step3ruform" :rules="rules" :type="2"></interval>
                </div>
              </div>
              <div v-if="step3ruform.AutoMarketingTaskConfigPushRuleEvent.Type==14">
                <div class = "flexRow">
                  <div class = "flexRow">
                    <span style="color:#606266">并且，</span>
                    <!-- <choosepage :disabled="disabled" :ruform="step3ruform" @getpagetype="getpagetype"></choosepage> -->
                    <span style="color:#606266">以下任一商品，分享次数</span>
                  </div>
                  <div style="margin:10px 0px">
                    <interval :disabled="disabled" :message="step3ruform" :rules="rules" :type="2"></interval>
                  </div>
                </div>
                <div>
                  <div class="flexRow" style="margin:10px 0px 0px 0px">
                    <span style="color:#606266">选择</span>
                    <el-select :disabled="disabled" @change="changeProductRangeType"
                      v-model="step3ruform.AutoMarketingTaskConfigPushRuleEvent.ProductRangeType"
                      style="margin:0px 10px;width:150px">
                      <el-option v-for="v in somethingchooseoptlist" :key="v.value"
                        :value="v.value" :label="v.label"></el-option>
                    </el-select>
                    <el-form-item label-width="0px" prop="AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigDetailRelationIdList"
                    :rules="newrules.chooseshoplist" v-if="step3ruform.AutoMarketingTaskConfigPushRuleEvent.ProductRangeType==0" style="width:80px">
                      <el-button v-if="!disabled" type="text" @click="chooseshopshow=true">选择商品</el-button>
                    </el-form-item>
                    <el-form-item label-width="0px" prop="AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigDetailRelationIdList"
                    :rules="newrules.chooseshoplist" v-if="step3ruform.AutoMarketingTaskConfigPushRuleEvent.ProductRangeType==1">
                      <el-select :disabled="disabled" v-model="chooseshoplist" @change="changechooseshoplist">
                        <el-option v-for="(v,i) in allgroupList" :key="i" :value="v.Id" :label="v.GroupName"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label-width="0px"  prop="AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigDetailRelationIdList"
                    :rules="newrules.chooseshoplist" v-if="step3ruform.AutoMarketingTaskConfigPushRuleEvent.ProductRangeType==2">
                      <el-select :disabled="disabled" v-model="choosebrand" @change="changechoosebrand">
                        <el-option v-for="(v,i) in allbrandList" :key="i" :value="v.Id" :label="v.BrandName"></el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div style="margin-top:20px;width:700px">
                    <!-- :disabled="disabled" -->
                    <product-table ref="giftTable" :disabled="disabled"
                      v-if="step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList.length && step3ruform.AutoMarketingTaskConfigPushRuleEvent.ProductRangeType===0"
                      :gifts="step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList"
                      @refresh="handleSelect"></product-table>
                  </div>
                </div>
              </div>
              <div v-if="step3ruform.AutoMarketingTaskConfigPushRuleEvent.Type==15">
                <div class = "flexRow" style="margin:10px 0px">
                  <span style="color:#606266">并且，以下任意一个关键词，搜索次数</span>
                  <interval :disabled="disabled" :message="step3ruform" :rules="rules" :type="2"></interval>
                </div>
                <div style="margin:25px 0px 0px 45px;align-items:flex-end">
                  <el-form-item label-width="0px" prop="AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList"
                    :rules="rules.keyword">
                    <div class="flexRow">
                      <div v-for="(v,i) in step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList" :key="i">
                        <el-tag type="info" @close="closesearchshoptag(i)" :closable="!disabled"
                          style="margin:0px 10px 5px 0px;display:inline-block">{{v.RelationValue}}
                        </el-tag>
                      </div>
                      <div v-if="step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList.length<5&&!disabled">
                        <el-button v-if="!NewTagNameshow" type="text" @click="NewTagNameshow=true">
                          添加关键字</el-button>
                        <el-input v-else v-model.trim="NewTagName" ref="newnameinput"
                          style="width:200px" placeholder="输入关键字，回车添加"
                          @keyup.enter.native="focuslabel" @blur="focuslabel" maxlength="10">
                        </el-input>
                      </div>
                    </div>
                  </el-form-item>
                </div>
              </div>
            </div>
            <div style="width:300px">
              <el-divider content-position="center">时</el-divider>
            </div>
          </el-form-item>
          <el-form-item label="推送时间：">
            <div class="flexRow" style="margin:0px 0px 10px 0px">
              <span style="color:#606266">等待</span>
              <el-form-item label-width="0px" prop="AutoMarketingTaskConfigPushRuleEvent.PushDelayDay"
                :rules="rules.PushDelayDay">
                <el-input :disabled="disabled" v-model="step3ruform.AutoMarketingTaskConfigPushRuleEvent.PushDelayDay"
                  style="width:120px;margin:0px 10px"></el-input>
              </el-form-item>
              <span style="color:#606266">天后推送</span>
            </div>
          </el-form-item>
          <div class="flexRow" style="margin-left:70px;align-items:flex-start">
            <div class="grayfont">推送次数说明：</div>
            <div>
              <div class="grayfont">客户行为触发规则下，仅推送一次</div>
            </div>
          </div>
        </div>
      </div>
    </el-form>

    <!-- 选择商品 -->
		<el-dialog :visible.sync="chooseshopshow" title="选择商品" width="1200px" custom-class="dialogpad">
			<chooseshoptable v-if="chooseshopshow" @select="handleSelect"
				:selectedData="step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList">
			</chooseshoptable>
		</el-dialog>

    <!-- 选择员工 -->
		<choosestaff :employeeVisible="importVisible" @closestaff="importVisible=false"
			@handleSureSelect="handleSureSelect"
			:selectedData="step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList">
		</choosestaff>

		<!-- 选择群聊 -->
		<choosegroup :choosestaffshow="choosestaffshow" :selectlist="step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList"
     @closegroup="closegroup"	@groupcomfire="groupcomfire"></choosegroup>
  </div>
</template>

<script>
import interval from "../interval"
import chooseshoptable from "@/views/Customer/autoAddTag/components/components/selectProduct"
import productTable from '@/views/Customer/autoAddTag/components/productTable/productTable.vue';
import choosestaff from '../choosestaff';
import choosegroup from '../choosegroup'
import choosepage from "../choosepage"
import productMod from '../chooseshop/product.vue';
export default {
  components: {
    interval,
    chooseshoptable,
    productTable,
    choosestaff,
    choosegroup,
    choosepage,
    productMod
  },
  inject:['group','brand','memberlist','driverlist','configmsg'],
  props: {
    ruform:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    rules:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    disabled:{
      type:Boolean,
      default:false,
    },
    thedaterange:{
      type:Array,
      default:()=>{
        return []
      }
    },
  },
  created () {
    this.step3ruform = JSON.parse(JSON.stringify(this.ruform))
      // console.log(this.step3ruform,'123',this.$route.query.templateId)
    this.Initialize()
  },
  watch:{
    ruform(){
      this.step3ruform = JSON.parse(JSON.stringify(this.ruform))
      
      // this.changecyclestate(this.step3ruform.AutoMarketingTaskConfigPushRuleLoop.PeriodType)
      // this.step3ruform.AutoMarketingTaskConfigPushRuleLoop.PeriodType
      // if(this.$route.query.Id){
        this.Initialize()
      // }
    },
    'ruform.TargetMemberType'(val){//第二步选择满足指定客户时 不展示客户行为触发的选项
      // console.log(val,'666')
      this.step3ruform.TargetMemberType = val
    },
    thedaterange(val){
      // console.log(val,'456')
      this.daterange = val
    },
  },
  computed: {
    allgroupList(){
      return this.group()
    },
    allbrandList(){
      return this.brand()
    },
    allmemberlist(){
      return this.memberlist()
    },
    alldriverlist(){
      return this.driverlist()
    },
    allconfigmsg(){
      return this.configmsg()
    }
  },
  data () {
    var checkcouponslist = (rule, value, callback) => {
      // console.log(rule)
      let val = this.step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList
      let type = this.step3ruform.AutoMarketingTaskConfigPushRuleEvent.ProductRangeType
      // console.log(val,type)
      if(!val||!val.length){
        if(type==0){
          callback(new Error('请选择商品'))
        }else if(type==1){
          callback(new Error('请选择商品分组'))
        }else if(type==2){
          callback(new Error('请选择商品品牌'))
        }
      }else{
        callback()
      }
		};
    var checkdaterange = (rule, value, callback) => {
      // console.log(rule)
      if(!this.daterange||!this.daterange.length){
        callback(new Error('请选择推送时间'))
      }else{
        callback()
      }
		};
    var checkPushCount = (rule, value, callback) => {
      // console.log(value)
      if(this.pushnumbertype==1){
        if(value <1 || value >5 || value != parseInt(value)){
          callback(new Error('次数请设置在1-5之间，整数'))
        }else{
          callback()
        }
      }else{
        callback()
      }
      // if(!this.daterange||!this.daterange.length){
      //   callback(new Error('请选择推送时间'))
      // }else{
      //   callback()
      // }
		};
    var checkPushTimeType1starttime = (rule, value, callback) => {
      // console.log(value,this.step3ruform.StartTime,'fsda')
      let time = new Date(this.step3ruform.StartTime)
      // console.log(this.step3ruform.StartTime)
      // let seven = new Date(this.step3ruform.StartTime.substring(0,10)+' '+'07:00:00')
      let now = new Date()
      // let night = new Date(this.step3ruform.StartTime.substring(0,10)+' '+'21:00:00').getTime()
      // console.log(time,now)
      // console.log(time.getTime() < seven.getTime())
      if(!this.step3ruform.StartTime){
        callback(new Error('请选择推送时间'))
      }else if(time.getTime() < now.getTime()){
        callback(new Error('请选择正确的推送时间'))
      }else{
        callback()
      }
		};
    return {
      // TargetMemberType:1,
      step3ruform:{},
      PeriodTime: '',
      pushnumbertype: 1,
      PeriodTimeHour:'',
      chooseshoplist:null,
      choosebrand:null,
      daterange:[],
      
      typechoosememberlist: [],
      typechoosedriverlist: [],

      onetimelist: [{
						value: 2,
						label: '定时推送'
					},
					{
						value: 1,
						label: '立即推送'
					}
			],
      pickerOptions1: {
        disabledDate: (val) => {
          return val.getTime() < Date.now() - 8.64e7
        },
        selectableRange:this.selectableRange(),
        format:'HH:mm',
      },
      pickerOptions2:{
        disabledDate: (val) => {
          // console.log(Date.now(),'val')
          return val.getTime() < Date.now() - 8.64e7
        },
        selectableRange:'07:00:00 - 21:00:00',
        format:'HH:mm',
      },
      pickerOptionstime1: {
        selectableRange: '07:00:00 - 21:00:00',
        format:'HH:mm',
      },
      cyclestatelist: [{
          value: 1,
          label: '每周(自然周)'
        },
        {
          value: 2,
          label: '每月(自然月)'
        },
      ],
      cycledaylistweek: [],
			cycledaylistmonth: [],
      somethinglist: [{
						value: 1,
						label: '成功支付订单'
					},
					{
						value: 2,
						label: '成功提交订单'
					},
					{
						value: 3,
						label: '购买商品'
					},
					{
						value: 4,
						label: '加入购物车'
					},
					{
						value: 5,
						label: '当天签到成功'
					},
					{
						value: 6,
						label: '和公众号有交互'
					},
					{
						value: 7,
						label: '成为会员'
					},
					{
						value: 8,
						label: '成为推手'
					},
					{
						value: 9,
						label: '添加员工企业微信'
					},
					{
						value: 10,
						label: '加入企业微信客户群聊'
					},
					{
						value: 11,
						label: '浏览商城页面'
					},
					{
						value: 12,
						label: '浏览商品详情页'
					},
					{
						value: 13,
						label: '分享商城页面'
					},
					{
						value: 14,
						label: '分享商品'
					},
					{
						value: 15,
						label: '搜索商品'
					},
			],
      somethingchooseoptlist: [{
						value: 0,
						label: '指定商品'
					},
					{
						value: 1,
						label: '指定商品分组'
					},
					{
						value: 2,
						label: '指定商品品牌'
					},
			],
      chooseshopshow:false,
      importVisible:false,
      choosestaffshow:false,
      grouplist:[],

      NewTagNameshow:false,
      NewTagName:'',

      newrules:{
        chooseshoplist:[
          {required: true,
						validator: checkcouponslist,
						trigger: 'blur'}
        ],
        daterange:[
          {required: true,
						validator: checkdaterange,
						trigger: 'blur'}
        ],
        PushCount:[
          {required: true,
						validator: checkPushCount,
						trigger: 'blur'}
        ],
        PushTimeType1starttime:[{
						required: true,
						validator: checkPushTimeType1starttime,
						// message: '请选择推送时间',
						trigger: 'blur'
					}, ],
      }
    }
  },
  methods: {
    Initialize(){
      //这里将周几每月几号存起来 最后再重新赋值  不然会被changecyclestate函数给恢复默认值  在保存编辑在查看  都会被默认为周一或者一号
      let num 
      if(this.step3ruform.AutoMarketingTaskConfigPushRuleLoop.PeriodNum){
        num = JSON.parse(JSON.stringify(this.step3ruform.AutoMarketingTaskConfigPushRuleLoop.PeriodNum))
      }

      if(this.$route.query.templateId){
        if(this.step3ruform.AutoMarketingTaskConfigPushRuleLoop.PeriodType){
          this.changecyclestate(this.step3ruform.AutoMarketingTaskConfigPushRuleLoop.PeriodType)
        }else{
          this.changecyclestate(1)
        }
      }else{
        this.changecyclestate(1)
      }
      this.daterange = this.thedaterange

      let type = this.step3ruform.AutoMarketingTaskConfigPushRuleEvent.Type
      if(this.step3ruform.PushRuleType==2){//周期循环推送 设置具体时间段
        if(this.step3ruform.AutoMarketingTaskConfigPushRuleLoop.PeriodType){
          this.changecyclestate(this.step3ruform.AutoMarketingTaskConfigPushRuleLoop.PeriodType)
        }
        let hour = this.step3ruform.AutoMarketingTaskConfigPushRuleLoop.PeriodTimeHour
        let min = this.step3ruform.AutoMarketingTaskConfigPushRuleLoop.PeriodTimeMinute
        this.PeriodTime = (hour>=10?hour:('0'+hour))+':'+(min==0?'00':min)
      }else if(this.step3ruform.PushRuleType==3){//客户行为触发 设置商品相关
        if(type==3||type==4||type==12||type==14){
          let protype = this.step3ruform.AutoMarketingTaskConfigPushRuleEvent.ProductRangeType
          if(protype == 0){
            
      console.log(this.step3ruform.AutoMarketingTaskConfigPushRuleEvent)
            this.step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList = 
            this.step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList.map((v)=>{
              v.ProductId = v.RelationId
              v.ImgUrl = v.ProductImgUrl
              v.Name = v.ProductName
              return v
            })
          }else if(protype==1){
            if(this.$route.query.Id){
              this.chooseshoplist = this.step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList[0].RelationId
            }else{
              this.chooseshoplist = ''
            }
            // console.log(this.chooseshoplist)
          }else if(protype==2){
            if(this.$route.query.Id){
              this.choosebrand = this.step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList[0].RelationId
            }else{
              this.choosebrand = ''
            }
          }
        }else if(type==7){//会员
          this.typechoosememberlist = this.step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList.map((v)=>{
            
            return v.RelationId
          })
        }else if(type==8){//推手
          this.typechoosedriverlist = this.step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList.map((v)=>{
            
            return v.RelationId
          })
        }else if(type==9){//企业员工
          this.step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList = 
            this.step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList.map((v)=>{
              v.Id = v.RelationId
              v.Name = v.RelationValue
              return v
            })
        }else if(type==10){//群聊
          this.step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList = 
            this.step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList.map((v)=>{
              v.Id = v.RelationId
              v.RelationValue = v.RelationValue
              return v
            })
        }
      }
      // console.log(this.step3ruform.AutoMarketingTaskConfigPushRuleLoop)
      if(this.step3ruform.AutoMarketingTaskConfigPushRuleLoop.PushCount>0){
        this.pushnumbertype = 1
      }else{
        this.pushnumbertype = 2
      }

      
      this.step3ruform.AutoMarketingTaskConfigPushRuleLoop.PeriodNum = num
    },
    changetime(e){
      // console.log(this.step3ruform.AutoMarketingTaskConfigDetailList[0].StartTime)
    },
    validation(){
      return new Promise((res,rej)=>{
        this.$refs['step3ruform'].validate((valid)=>{
          if(valid){
            if(this.pushnumbertype==2){
              this.step3ruform.AutoMarketingTaskConfigPushRuleLoop.PushCount = 0
            }
            res(this.step3ruform)
          }else{
            rej('请先完善第3步任务基本信息的配置')
          }
        })
      })
    },
    selectableRange(){
      let day = new Date()
      let hour = day.getHours()>=10?day.getHours():('0'+day.getHours())
      let min = day.getMinutes()>=10?day.getMinutes():('0'+day.getMinutes())
      // console.log(`${hour}:${min}:00 - 21:00:00`)
      return `${hour}:${min}:00 - 21:00:00`
    },
    selectableRange2(date){
      // console.log(this.step3ruform.StartTime)
      
    },
    //切换推送规则
    changePushRuleType(e) {
      // console.log('这里切换了第三步的推送规则')
      this.daterange = []
      this.step3ruform.StartTime = ''
      if(e==1){
        this.step3ruform.AutoMarketingTaskConfigPushRuleOnce = {
          AutoMarketingTaskConfigId:this.step3ruform.AutoMarketingTaskConfigPushRuleOnce.AutoMarketingTaskConfigId,
          PushTimeType:2,
        }
        // this.step3ruform.AutoMarketingTaskConfigDetailList[0].StartTime = '';
      }else if(e==2){
        this.step3ruform.AutoMarketingTaskConfigPushRuleLoop = {
          AutoMarketingTaskConfigId:this.step3ruform.AutoMarketingTaskConfigPushRuleLoop.AutoMarketingTaskConfigId,
          PeriodType:1,
          PeriodNum:1,
          PeriodTimeHour:'',
          PeriodTimeMinute:'',
          PushCount:1,
          Id:this.step3ruform.AutoMarketingTaskConfigPushRuleLoop.Id,
        }
        this.PeriodTime = ''
      }else if(e==3){
        this.step3ruform.AutoMarketingTaskConfigPushRuleEvent = {
          AutoMarketingTaskConfigId:this.step3ruform.AutoMarketingTaskConfigPushRuleLoop.AutoMarketingTaskConfigId,
          Type:1,
          ConditionType:1,
          Num:'',
          MaxNum:'',
          ProductRangeType:0,
          PageRangeType:0,
          PushDelayDay:7,
          AutoMarketingTaskConfigPushRuleEventRelationIdList:[]
        }
      }
      // this.step3ruform.AutoMarketingTaskConfigDetailList[0].StartTime = this.daterange[0];
      // this.step3ruform.AutoMarketingTaskConfigDetailList[0].EndTime = '';
      this.$emit('changePushRuleType',this.step3ruform.PushRuleType)
      this.$emit('getdaterange',this.daterange)
    },
    //周期循环推送具体时间
    changePeriodTime(e) {
      let time = String(e)

      // console.log(e)
      this.step3ruform.AutoMarketingTaskConfigPushRuleLoop.PeriodTimeHour = time.substring(0, 2)
      this.step3ruform.AutoMarketingTaskConfigPushRuleLoop.PeriodTimeMinute = time.substring(3, 6)
    },
    //推送规则 周期循环 选择时间范围
    changedaterange(e) {
      this.step3ruform.StartTime = e[0]
      this.step3ruform.EndTime = e[1]
      // console.log(this.step3ruform)
      this.$emit('getdaterange',e)
    },
    dateinput(val){
      this.step3ruform.StartTime = val
      this.$forceUpdate()
      // console.log(val)
    },
    //推送规则推送一次 时间切换
    changePushTimeType() {
      this.step3ruform.StartTime = ""
      // console.log(this.step3ruform)
    },
    changecyclestate(e) {
      // console.log(e)
      this.step3ruform.cycleday = '';
      this.step3ruform.cycledetailtime = '';
      this.step3ruform.AutoMarketingTaskConfigPushRuleLoop.PeriodNum = 1;
      this.cycledaylistweek = []
      this.cycledaylistmonth = []
      if (e == 1) {
        let arr = ['一', '二', '三', '四', '五', '六', '日']
        for (let i = 1; i <= 7; i++) {
          let data = {
            value: i,
            label: '周' + arr[i - 1]
          }
          this.cycledaylistweek.push(data)
        }
      } else if (e == 2) {
        for (let i = 1; i <= 28; i++) {
          let data = {
            value: i,
            label: i + '号'
          }
          this.cycledaylistmonth.push(data)
        }
      }
    },
    changepushnumbertype(e) {
      // console.log(this.pushnumbertype)
      // this.$refs.step3ruform.validateField('AutoMarketingTaskConfigPushRuleLoop.PushCount',(res)=>{
      //   console.log(res)
      // })
      // if(e==2){
      //   this.step3ruform.AutoMarketingTaskConfigPushRuleLoop.PushCount = 0
      // }else{
      //   this.step3ruform.AutoMarketingTaskConfigPushRuleLoop.PushCount = 1
      // }
    },
    jumpother(e){
      let url = ''
      if(e==2){
        url = 'https://jusnn6k8al.feishu.cn/docs/doccnB5xPQXMEuHQHkYKhveQE7f'
      }else if(e==3){
        url = 'https://jusnn6k8al.feishu.cn/docs/doccnoYMB2rINQaBu3QI3mKHSzb'
      }
      window.open(url)
    },
    jump(e){
      let path = ''
      if(e == 1){
        path = '/setting/setOfficialAccount'
      }else if(e == 2){
        path = '/saleChannel/qyWechat'
      }
      this.$router.push({
        path:path
      })
    },
    //选择商品
    getshop(msg){
      this.step3ruform.AutoMarketingTaskConfigPushRuleEvent.ProductRangeType = msg.ProductRangeType
      if(msg.ProductRangeType==0){
        this.step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList = msg.list.map((v)=>{
          v.RelationId = v.ProductId
          v.ProductName = v.Name
          v.ProductImgUrl = v.ImgUrl
          return v
        })
      }else if(msg.ProductRangeType==1){
        this.step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList.push({
            RelationId:msg.groupId
        })
      }else if(msg.ProductRangeType==2){
        this.step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList.push({
            RelationId:msg.groupId
        })
      }
      // console.log(this.ruform.AutoMarketingTaskConfigPushRuleEvent)
    },
    //选择浏览页面
    getpagetype(msg){
      this.step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList = []
      if(msg.PageRangeType==0){
        this.step3ruform.AutoMarketingTaskConfigPushRuleEvent.PageRangeType = 0
      }else{
        this.step3ruform.AutoMarketingTaskConfigPushRuleEvent.PageRangeType = 1
        let data = {
          PagePath:msg.PagePath,
          PageName:msg.pageName,//获取到页面信息是是小写的pageName
          RelationId:msg.RelationId,
        }
        this.step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList.push(data)
      }
      // console.log(this.ruform.AutoMarketingTaskConfigPushRuleEvent,11)
    },
    //客户行为 选择推手
    changetypedriver(val) {
      this.step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList = val.map((v)=>{
        let data = {
          RelationId:v
        }
        return data
      })
    },
    //客户行为 选择会员
    changetypemember(val) {
      this.step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList = val.map((v)=>{
        let data = {
          RelationId:v
        }
        return data
      })
    },
    //切换客户行为
    changetype() {
      this.typechoosememberlist = []
      this.typechoosedriverlist = []
      this.chooseshoplist = ''
      this.choosebrand = ''
      this.step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList = []
      this.$refs.step3ruform.clearValidate()
    },
    //选择商品 确定
    handleSelect(list) {
      // console.log(list)
      this.step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList = list.map((v) => {
          v.RelationId = v.ProductId
          return v
        })
      // console.log(this.step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList, 1)
      this.chooseshopshow = false
    },
    changechooseshoplist(e){
      let msg = this.allgroupList.find((v)=>{return v.Id == e})
      let data = {
        RelationId:msg.Id,
        RelationValue:msg.GroupName
      }
      this.step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList = []
      this.step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList.push(data)
      // console.log(this.step3ruform)
    },
    changechoosebrand(e){
      let msg = this.allbrandList.find((v)=>{return v.Id == e})
      let data = {
        RelationId:msg.Id,
        RelationValue:msg.BrandName
      }
      this.step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList = []
      this.step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList.push(data)
    },
    changeProductRangeType(){
      this.step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList = []
    },
    closesearchshoptag(i){
      this.step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList.splice(i, 1)
    },
    //群聊 确定
    groupcomfire(list) {
      this.step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList = list
      .map((v) => {
        v.RelationId = v.Id
        v.RelationValue = v.GroupName
        return v
      })

      this.choosestaffshow = false
      this.$refs['step3ruform'].validate()
    },
    closegrouptag(i){
      this.step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList.splice(i, 1)
    },
    closegroup() {
      this.choosestaffshow = false
    },
    //删除员工tag
    closeemployeestag(i) {
      this.step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList.splice(i, 1)
    },
    //选择员工 确定
    handleSureSelect(list) {
      this.step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList = list
        .map((v) => {
          v.RelationId = v.Id
          v.RelationType = v.Type
          v.RelationValue = v.Name
          return v
        })
      // console.log(this.ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList)
      this.importVisible = false
      this.$refs['step3ruform'].validate()
    },
    focuslabel() {
      if (this.NewTagName) {
        let data = {
          RelationId: 0,
          RelationValue: this.NewTagName,
        }
        this.step3ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList.push(data)
      }
      // console.log()
      this.NewTagNameshow = false
      this.NewTagName = ''
    },

  }
}
</script>

<style lang ="less" scoped>
  .grayfont {
		font-size: 12px;
		color: #999999;
		line-height: 1.5;
	}
  .flexRow{
    display: flex;
    align-items: center;
  }
  .employee-item {
					display: flex;
					align-items: center;
					height: 26px;
					margin-right: 10px;
					margin-bottom: 5px;
					padding: 0 5px;
					font-size: 12px;
					border: 1px solid #ddd;
					border-radius: 3px;

					.e-hd {
						width: 18px;
						height: 18px;
						overflow: hidden;
						border-radius: 2px;
						margin-right: 5px;
						background-color: #F6F6F6;
					}
					.e-no-hd{
						display: inline-block;
						width: 18px;
						height: 18px;
						line-height: 18px;
						text-align: center;
						font-size: 12px;
						color: #fff;
						border-radius: 2px;
						vertical-align: 1px;
						background-color: #409eff;
						margin-right: 5px;
					}

					.e-delete{
						margin-left: 5px;
						font-size: 14px;
						cursor: pointer;
					}
				}
</style>
<style>

  .timeclass .el-picker-panel__footer .el-button--text{
    display: none !important;
  }
  .dialogpad .el-dialog__body{
    padding:10px 20px 30px 20px
  }
</style>