<template>
	<div style="background:white" v-loading="pageloading">
		<el-card>
			<div class="flexRow">
				<div class="toproundBox" @click="step=1" :class="{stepnow:step==1}">1.任务基本信息 <span>设置任务名称等信息</span>
				</div>
				<div class="flexRow linearrowbox">
					<div class="dottedline"></div>
					<div class="rigntarrow"></div>
				</div>
				<div class="toproundBox" @click="step=2" :class="{stepnow:step==2}">2.目标推送客户 <span>给哪些客户推送</span> </div>
				<div class="flexRow linearrowbox">
					<div class="dottedline"></div>
					<div class="rigntarrow"></div>
				</div>
				<div class="toproundBox" @click="step=3" :class="{stepnow:step==3}">3.推送时间 <span>在什么时候给客户推送</span>
				</div>
				<div class="flexRow linearrowbox">
					<div class="dottedline"></div>
					<div class="rigntarrow"></div>
				</div>
				<div class="toproundBox" @click="step=4" :class="{stepnow:step==4}">4.推送渠道和内容
					<span>通过哪个渠道推送，推送什么内容</span> </div>
			</div>
		</el-card>
		<div style="padding:30px 10px;margin-bottom:80px;" v-if="pageshow">
			<el-form :model="ruform" :rules="rules" ref="ruform" label-width="150px">
				<step1 v-show="step==1" :ruform="ruform" :rules="rules" ref="step1" :disabled="disabled"></step1>
				<step2 v-show="step==2" :ruform="ruform" :rules="rules" ref="step2" :disabled="disabled" @changeTargetMemberType="changeTargetMemberType"></step2>
				<step3 v-show="step==3" :ruform="ruform" :rules="rules" ref="step3" :disabled="disabled" @changePushRuleType = "changePushRuleType"
				 :thedaterange="daterange" @getdaterange="getdaterange"></step3>
				<step4 v-show="step==4" :ruform="ruform" :rules="rules" ref="step4" :disabled="disabled" @refresh="init"></step4>
			</el-form>
		</div>
		<el-card class="bottomBtn" style="z-index:999">
			<div class="flexRow" style="justify-content: space-between;">
				<div>
					<el-button v-if="step<4" class = "nextbtn" @click="step+=1">下一步</el-button>
					<el-button v-if="step>1" type="primary" class = "lastbtn" @click="step-=1">上一步</el-button>
				</div>
				<el-button type="primary" @click="saveruform('ruform')" :disabled="disabled&&ruform.State!=1">保存</el-button>
			</div>
		</el-card>
	
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'
	import {getTemplateParam} from '@/api/sv1.0.0.js';
	import step1 from "../components/step/step1"
	import step2 from "../components/step/step2"
	import step3 from "../components/step/step3"
	import step4 from "../components/step/step4"

	import {
		autoMarketingTaskConfiginit,
		autoMarketingTaskConfigedit,
		autoMarketingTaskConfiginfo
	} from "@/api/sv3.0.0"
	import {
		productGroupList,
		productBrandList
	} from '@/api/goods.js';
	import marketcompon from '../marketcompon'
	export default {
		components: {
			step1,
			step2,
			step3,
			step4,
		},
		data() {
			var checkTransformWithinDay = (rule, value, callback) => {
				if (!value) {
					callback(new Error('请设置转化周期'))
				} else if (value != parseInt(value) || value < 1 || value > 31) {
					callback(new Error('转化周期请输入在1~31天之间，整数'))
				} else {
					callback()
				}
			};
			var checkPushCount = (rule, value, callback) => {
				console.log(value)
				if (value != parseInt(value) || value < 1 || value > 5) {
					callback(new Error('次数请设置在1-5之间，整数'))
				} else {
					callback()
				}
			};
			var checkPushDelayDay = (rule, value, callback) => {
				if (value != parseInt(value) || value < 1 || value > 31) {
					callback(new Error('请输入1~31之间的整数'))
				} else {
					callback()
				}
			};
			var checkmoney = (rule, value, callback) => {
				let two = /^\d+(\.\d{1,2})?$/
				if (value < 0 || value > 9999 || !two.test(value)) {
					callback(new Error('金额请输入在0~9999之间，最多2位小数'))
				} else {
					callback()
				}
			};
			var checkmaxmoney = (rule, value, callback) => {
				let two = /^\d+(\.\d{1,2})?$/
				console.log(value)
				if (value < 0 || value > 9999 || !two.test(value)) {
					callback(new Error('金额请输入在0~9999之间，最多2位小数'))
				} else if (value < this.ruform.AutoMarketingTaskConfigPushRuleEvent.Num) {
					callback(new Error('请输入正确的区间'))
				} else {
					callback()
				}
			};
			var checknumberof = (rule, value, callback) => {
				let num = String(value)
				if (!num || num < 0 || num > 99 || num != parseInt(num)) {
					callback(new Error('次数请输入在0~99之间，整数 '))
				} else {
					callback()
				}
			};
			var checkmaxnumberof = (rule, value, callback) => {
				let num = String(value)
				if (!num || num < 0 || num > 99 || num != parseInt(num)) {
					callback(new Error('次数请输入在0~99之间，整数 '))
				} else if (value < this.ruform.AutoMarketingTaskConfigPushRuleEvent.Num) {
					callback(new Error('请输入正确的区间'))
				} else {
					callback()
				}
			};
			var checkcouponslist = (rule, value, callback) => {
				// console.log(value,11)
				if(this.ruform.IsOpenGiftCoupon){
					if(!value || !value.length){
						callback(new Error('请选择赠送的优惠券'))
					}else{
						callback()
					}
				}else{
					callback()
				}
			};
			var checkcactivelist = (rule, value, callback) => {
				// console.log(value,11)
				if(this.ruform.IsOpenGiftWheelSurf){
					if(!value || !value.length){
						callback(new Error('请选择抽奖活动'))
					}else{
						callback()
					}
				}else{
					callback()
				}
			};
			var checkselected111 = (rule, value, callback) => {
				console.log(value,11)
				// if(this.ruform.IsOpenGiftWheelSurf){
				// 	if(!value || !value.length){
				// 		callback(new Error('请选择抽奖活动'))
				// 	}else{
				// 		callback()
				// 	}
				// }else{
				// 	callback()
				// }
			};

			return {
				disabled:false,
				pageloading: false,
				pageshow:false,
				step: 1,
				choosememberlist: [],
				ismember: true,
				choosedriverlist: [],
				isdriver: true,
				isnew: true,
				selected: [],
				list: [],
				// isalltag:false,
				daterange: [],
				PeriodTime: '',
				pushnumbertype: 1,
				typechoosememberlist: [],
				typechoosedriverlist: [],

				ruform: {
					Name: '',
					IsOpenTransform: false,
					TransformWithinDay: 7,
					CompleteTargetType: 1,
					TargetMemberType: 1,
					PushRuleType: 1,
					IsOpenGiftCoupon:false,
					GiftCouponWay:1,
					IsOpenGiftWheelSurf:false,
					AutoMarketingTaskConfigDetailList: [{
						Type: '',
						IsMatchAll: false,
						StartTime: '',
						EndTime: '',
						AutoMarketingTaskConfigDetailRelationIdList: []
					}],
					AutoMarketingTaskConfigPushRuleOnce: {
						AutoMarketingTaskConfigId: 0,
						PushTimeType: 2,
					},
					AutoMarketingTaskConfigPushRuleLoop: {
						AutoMarketingTaskConfigId: 0,
						PeriodType: 1,
						PeriodNum: 1,
						PeriodTimeHour: null,
						PeriodTimeMinute: null,
						PushCount: 1,
					},
					AutoMarketingTaskConfigPushRuleEvent: {
						AutoMarketingTaskConfigId: 0,
						TimeType: 1,
						Type: 1,
						ConditionType: 1,
						Num: '',
						MaxNum: '',
						ProductRangeType: 0,
						PageRangeType: 0,
						PushDelayDay: 7,
						AutoMarketingTaskConfigPushRuleEventRelationIdList: []
					},
					AutoMarketingTaskConfigGiftList:[],
					AutoMarketingTaskConfigMessageList:[
						{
							Type:3,
							WxAppSubscribeMessageAttachInfo:{
								FirstText:'商城优惠活动进行中',
								SecondText:this.getdatenow(),
								ThirdText:'赶紧进入商城看看吧',
								LinkUrl:'/pages/index/index',
								LinkName:'商城首页',
								Type:3
							}
						}
					],


					couponslist:[],
					activelist:[],
				},
				rules: {
					nocheck:[
						{required: false,}
					],
					Name: [{
						required: true,
						message: '请输入任务名称',
						trigger: 'blur'
					}, ],
					TransformWithinDay: [{
						required: true,
						validator: checkTransformWithinDay,
						trigger: 'blur'
					}],
					selected: [{
						required: true,
						validator: checkselected111,
						message: '请选择标签',
						trigger: 'blur'
					}, ],
					PushTimeType1starttime: [{
						required: true,
						message: '请选择推送时间',
						trigger: 'blur'
					}, ],
					StartTime: [{
						required: true,
						message: '请设置时间范围',
						trigger: 'blur'
					}, ],
					PeriodTimeHour: [{
						required: true,
						message: '请选择推送时间',
						trigger: 'blur'
					}, ],
					PushCount: [{
						required: true,
						validator: checkPushCount,
						trigger: 'blur'
					}],
					PushDelayDay: [{
						required: true,
						validator: checkPushDelayDay,
						trigger: 'blur'
					}],
					money: [{
						required: true,
						validator: checkmoney,
						trigger: 'blur'
					}],
					numberof: [{
						required: true,
						validator: checknumberof,
						trigger: 'blur'
					}],
					maxmoney: [{
						required: true,
						validator: checkmaxmoney,
						trigger: 'blur'
					}],
					maxnumberof: [{
						required: true,
						validator: checkmaxnumberof,
						trigger: 'blur'
					}],
					typemember: [{
						required: true,
						message: '请选择会员级别',
						trigger: 'blur'
					}, ],
					typedriver: [{
						required: true,
						message: '请选择推手级别',
						trigger: 'blur'
					}, ],
					keyword:[{
						required: true,
						message: '请添加关键字',
						trigger: 'blur'
					},],
					couponslist:[{
						required: true,
						validator: checkcouponslist,
						trigger: 'blur'
					},],
					activelist:[{
						required: true,
						validator: checkcactivelist,
						trigger: 'blur'
					},],
					chooseshoplist:[{
						required: true,
						// validator: checkStartTime,
						message: '请选择商品分组',
						trigger: 'blur'
					}],
					choosebrand:[{
						required: true,
						message: '请选择商品品牌',
						trigger: 'blur'
					}],
					chooseshop:[{
						required: true,
						message: '请选择商品',
						trigger: 'blur'
					}],
					choosestaff:[{
						required: true,
						message: '请选择员工',
						trigger: 'blur'
					}],
					choosegroup:[{
						required: true,
						message: '请选择群聊',
						trigger: 'blur'
					}],
					channel:[{
						required: true,
						message: '请配置推送渠道和内容',
						trigger: 'blur'
					}],
				},
				
				configmsg:{},
				groupList:[],
				brandList:[],
				driverlist:[],
				memberlist:[]
			}
		},
		computed: {
			...mapGetters([
				'routerList'
			]),
			qywx(){
				let msg = {}
				if(this.ruform.AutoMarketingTaskConfigMessageList.some(v =>{return v.Type==1})){
					msg =  this.ruform.AutoMarketingTaskConfigMessageList.find(v =>{return v.Type==1})
				}
				// console.log(msg)
				return msg
			},
			sms(){
				let msg = {}
				if(this.ruform.AutoMarketingTaskConfigMessageList.some(v =>{return v.Type==2})){
					msg =  this.ruform.AutoMarketingTaskConfigMessageList.find(v =>{return v.Type==2})
				}
				// console.log(msg,666)
				return msg
			},
		},
		watch: {
			//客户行为 选择会员列表
			typechoosememberlist(val) {
				this.ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList = val
					.map((v) => {
						let data = {
							RelationId: v
						}
						return data
					})
			},
			//客户行为 选择推手列表
			typechoosedriverlist(val) {
				this.ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList = val
					.map((v) => {
						let data = {
							RelationId: v
						}
						return data
					})
			},
		},
		beforeMount() {
			// this.pageshow = false
			// console.log(this.$route.params)
			this.init().then(()=>{
				if(this.$route.query.templateId){
					this.gettemplateIdmsg(this.$route.query.templateId)
				}else if(this.$route.query.Id){
					this.getinfo()
				}else if(this.$route.params.TagDetailId){
					this.step = 2
					this.ruform.TargetMemberType = 5
					this.ruform.AutoMarketingTaskConfigDetailList = []
					this.ruform.AutoMarketingTaskConfigDetailList.push({
						AutoMarketingTaskConfigId:0,
						IsMatchAll:false,
						AutoMarketingTaskConfigDetailRelationIdList:[
							{
								AutoMarketingTaskConfigId:0,
								AutoMarketingTaskConfigDetailId:0,
								RelationId:this.$route.params.TagDetailId,
								RelationValue:this.$route.params.Name,
							}
						]
					})
					// console.log(this.ruform)
				}
				this.pageshow = true
				// console.log(this.pageshow)
			})
		},
		provide() {
			return {
				group: () => {
					return this.groupList
				},
				brand: () => {
					return this.brandList
				},
				memberlist:()=>{
					return this.memberlist
				},
				driverlist:()=>{
					return this.driverlist
				},
				configmsg:()=>{
					return this.configmsg
				},
			}
		},
		created() {
			this.getGroupList();
			this.getBrandList();
		},
		methods: {
			
			getdatenow(){
				let date = new Date()
				let year = date.getFullYear()
				let month = (date.getMonth()+1)>9?(date.getMonth()+1):('0'+(date.getMonth()+1))
				let day = date.getDate()>9?date.getDate():('0'+date.getDate())
				let hour = date.getHours()>9?date.getHours():('0'+date.getHours())
				let min = date.getMinutes()>9?date.getMinutes():('0'+date.getMinutes())
				return year+'-'+month+'-'+day+' '+hour+':'+min
			},
			changePushRuleType(val){
				this.ruform.PushRuleType = val
			},
			changeTargetMemberType(val){
				// console.log(val,'2323')
				this.ruform.TargetMemberType = val
			},
			getdaterange(val){
				this.daterange = val
			},

			//保存
			async saveruform() {
				try{
					let s1 = await this.$refs.step1.validation()
					let s2 = await this.$refs.step2.validation()
					let s3 = await this.$refs.step3.validation()
					let s4 = await this.$refs.step4.validation()
					
					// console.log(1)
					this.tosave(this.getSaveData(s1,s2,s3,s4))
				}catch(err){
					console.log(err,'err123')
					this.$message.error(err)
					// console.log(err)
				}
			},
			async tosave(msg){
				this.pageloading = true
				try{
					let data = {AutoMarketingTaskConfig:await msg}
					// console.log(data,'data')
					let res = await autoMarketingTaskConfigedit(data)
					if(res.IsSuccess){
						// console.log('保存成功')
						this.$message.success('保存成功')
						this.$router.go(-1)
					}
				}finally{
					this.pageloading = false
				}
			},
			getSaveData(s1,s2,s3,s4){
				// console.log(s4)
				// console.log(s2.AutoMarketingTaskConfigDetailList,'AutoMarketingTaskConfigDetailList')
				let params = {
					Id:this.ruform.Id?this.ruform.Id:0,

					Name: s1.Name,
					IsOpenTransform: s1.IsOpenTransform,
					TransformWithinDay: s1.TransformWithinDay,
					CompleteTargetType:s1.CompleteTargetType,

					TargetMemberType:s2.TargetMemberType,
					AutoMarketingTaskConfigDetailList: s2.AutoMarketingTaskConfigDetailList,

					PushRuleType: s3.PushRuleType,
					AutoMarketingTaskConfigPushRuleOnce: s3.AutoMarketingTaskConfigPushRuleOnce,
					AutoMarketingTaskConfigPushRuleLoop: s3.AutoMarketingTaskConfigPushRuleLoop,
					AutoMarketingTaskConfigPushRuleEvent: s3.AutoMarketingTaskConfigPushRuleEvent,

					IsOpenGiftCoupon:s4.IsOpenGiftCoupon,
					GiftCouponWay:s4.GiftCouponWay,
					IsOpenGiftWheelSurf:s4.IsOpenGiftWheelSurf,
					AutoMarketingTaskConfigGiftList:s4.AutoMarketingTaskConfigGiftList,
					AutoMarketingTaskConfigMessageList:s4.AutoMarketingTaskConfigMessageList,

					StartTime:'',
					EndTime:'',

				}
				// console.log(params,123)
				params.StartTime = this.getStartTime(s3)
				params.EndTime = this.getEndTime(params)
				
				if(params.TargetMemberType==1){
					params.AutoMarketingTaskConfigDetailList = undefined
				}
				if(params.PushRuleType==1){
					params.AutoMarketingTaskConfigPushRuleLoop = undefined
					params.AutoMarketingTaskConfigPushRuleEvent = undefined
				}
				if(params.PushRuleType==2){
					params.AutoMarketingTaskConfigPushRuleOnce = undefined
					params.AutoMarketingTaskConfigPushRuleEvent = undefined
				}
				if(params.PushRuleType==3){
					params.AutoMarketingTaskConfigPushRuleOnce = undefined
					params.AutoMarketingTaskConfigPushRuleLoop = undefined
				}
				// console.log(params)
				return params
			},

			getLoopData(){
				var obj = JSON.parse(JSON.stringify(this.ruform.AutoMarketingTaskConfigPushRuleLoop));
				if (this.pushnumbertype === 2){
					obj.PushCount = 0;
				}
				return obj;
			},
			getAutoMarketingTaskConfigDetailList(ruform){
				
				if (this.ruform.TargetMemberType === 1) return [];
				
				//按会员身份
				if (this.ruform.TargetMemberType === 2){
					
					var list = []
					if (this.ismember){
						list = this.choosememberlist.map(item=>{
							return {
								RelationId: item
							}
						});
						if(!list.length){
							list = [{RelationId: -1}]
						}
					}else{
						list = [{RelationId: 0}];
					}
					
					return [{
						AutoMarketingTaskConfigId: 0,
						Type: 2,
						AutoMarketingTaskConfigDetailRelationIdList: list
					}]
				}
				
				//按推手身份
				if (this.ruform.TargetMemberType === 3){
					
					var list = []
					if (this.isdriver){
						list = this.choosedriverlist.map(item=>{
							return {
								RelationId: item
							}
						});
						if(!list.length){
							list = [{RelationId: -1}]
						}
					}else{
						list = [{RelationId: 0}];
					}
					
					return [{
						AutoMarketingTaskConfigId: 0,
						Type: 3,
						AutoMarketingTaskConfigDetailRelationIdList: list
					}]
				}
				
				//按新老客户
				if (this.ruform.TargetMemberType === 4){
					
					var list = []
					if (this.isnew){
						list = [{RelationId: 0}];
					}else{
						list = [{RelationId: 1}];
					}
					
					return [{
						AutoMarketingTaskConfigId: 0,
						Type: 100,
						AutoMarketingTaskConfigDetailRelationIdList: list
					}]
				}
				
				//按客户标签
				if (this.ruform.TargetMemberType === 5){
					
					var list = this.selected.map(item=>{
						return {
							RelationId: item.TagDetailId
						}
					})
					
					return [{
						AutoMarketingTaskConfigId: 0,
						Type: 600,
						IsMatchAll: this.ruform.IsMatchAll,
						AutoMarketingTaskConfigDetailRelationIdList: list
					}]
				}
				
				//自选条件
				if (this.ruform.TargetMemberType === 6){
					return this.saveList.map(item=>{
						item.AutoMarketingTaskConfigId = item.TagRuleConfigId;
						item.AutoMarketingTaskConfigDetailRelationIdList = item.TagRuleConfigDetailRelationIdList;
						return item;
					})
				}
			},
			getStartTime(params){
				//推送规则是：定时仅推送一次； 并且时间类型是：定时推送
					// console.log(params.PushRuleType,'params')
				if (params.PushRuleType === 1 && params.AutoMarketingTaskConfigPushRuleOnce.PushTimeType === 2){
					return params.StartTime;
				}
				if (params.PushRuleType === 2 || params.PushRuleType === 3){
					// console.log(this.daterange,'this.daterange')
					return this.daterange[0]
				}
				
				return ''
			},
			getEndTime(params){
				var time = ''
				if (params.PushRuleType === 2 || params.PushRuleType === 3){
					time = this.daterange[1];
				}
				if (time){
					return this.formatNextDate(time.split(' ')[0])
				}
				return ''
			},
			init() {
				return new Promise(async (res)=>{
					this.pageloading = true
					try {
						let res = await autoMarketingTaskConfiginit()
						this.configmsg = res
						// this.configmsg.SMSCount =5
						// this.smsnum = res.SMSCount

						this.memberlist = res.MemberVipRoleList.filter((v) => {
							return v.Id != 0
						}) //会员列表
						// console.log(window.localStorage.getItem('mlmzDistributionModel'),'123')
						if(window.localStorage.getItem('mlmzDistributionModel')==1){//代理列表
							let list = res.AgentRoleList.filter((v) => {
								v.DistributRoleName = v.AgentRoleName
								return v.Id != 0
							}) 
							this.driverlist = list.sort((a,b)=>{
								return b.AgentRoleGradeNum-a.AgentRoleGradeNum
							})
							// console.log(this.driverlist,966)
						}else{
							let list = res.MemberDistributorRoleList.filter((v) => { //推手列表
								return v.Id != 0
							})
							this.driverlist = list.sort((a,b)=>{
								return a.Id-b.Id
							})

						}
						// console.log(this.driverlist.map((v)=>{return v.Id}),window.localStorage.getItem('mlmzDistributionModel'))
					} finally {
						this.pageloading = false
					res('')
					}
				})
			},
			async getGroupList() {
				try {
					const res = await productGroupList();
					this.groupList = res.Result;
					// console.log(this.groupList)
				} catch (e) {}
			},
			async getBrandList() {
				try {
					const res = await productBrandList();
					this.brandList = res.Result;
					// console.log(this.brandList)
				} catch (e) {}
			},



			async getinfo(){
				this.pageloading=true
				try{
					let data = {
						AutoMarketingTaskConfigId:this.$route.query.Id
					}
					let res = await autoMarketingTaskConfiginfo(data)
					let ruform = res.AutoMarketingTaskConfig
					// console.log(ruform)
					if(ruform.TargetMemberType==1){
						ruform.AutoMarketingTaskConfigDetailList = [{
							Type: '',
							IsMatchAll: false,
							StartTime:ruform.StartTime,
							EndTime: '',
							AutoMarketingTaskConfigDetailRelationIdList: [{
								AutoMarketingTaskConfigId: 0,
								AutoMarketingTaskConfigDetailId: 0,
								RelationId: '',
								RelationValue: '',
							}]
						}]
					}else if(ruform.TargetMemberType==6){
						// console.log(ruform.AutoMarketingTaskConfigDetailList)
						ruform.AutoMarketingTaskConfigDetailList = ruform.AutoMarketingTaskConfigDetailList.map((v)=>{
							if(v.EndTime){
								v.EndTime = (v.EndTime)
								// console.log(v.EndTime)
							}
							return v
						})
						
					}
					if(ruform.PushRuleType==1){
						ruform.AutoMarketingTaskConfigPushRuleLoop = {}
						ruform.AutoMarketingTaskConfigPushRuleEvent = {}
					}
					if(ruform.PushRuleType==2){
						ruform.AutoMarketingTaskConfigPushRuleOnce = {}
						ruform.AutoMarketingTaskConfigPushRuleEvent = {}
					}
					if(ruform.PushRuleType==3){
						// this.daterange = [ruform.StartTime,ruform.EndTime]
						ruform.AutoMarketingTaskConfigPushRuleOnce = {}
						ruform.AutoMarketingTaskConfigPushRuleLoop = {}
					}


					let daterange = []
					if(this.$route.query.copy){
						ruform.Id = 0
						ruform.StartTime = ""
						ruform.EndTime = ""
						ruform.LastPushTime = ""
						ruform.AddTime = ""
						ruform.UpdateTime = ""
						if(ruform.AutoMarketingTaskConfigDetailList &&ruform.AutoMarketingTaskConfigDetailList .length){
							ruform.AutoMarketingTaskConfigDetailList  = ruform.AutoMarketingTaskConfigDetailList .map((v)=>{
								v.AddTime = ''
								v.UpdateTime = ''
								if(v.AutoMarketingTaskConfigDetailRelationIdList&&v.AutoMarketingTaskConfigDetailRelationIdList.length){
									v.AutoMarketingTaskConfigDetailRelationIdList = v.AutoMarketingTaskConfigDetailRelationIdList.map((x)=>{
										x.AddTime = ''
										x.UpdateTime = ''
										return x
									})
								}
								return v
							})
						}
						if(ruform.AutoMarketingTaskConfigGiftList&&ruform.AutoMarketingTaskConfigGiftList.length){
							ruform.AutoMarketingTaskConfigGiftList = ruform.AutoMarketingTaskConfigGiftList.map((v)=>{
								v.AddTime = ''
								v.UpdateTime = ''
								return v
							})
						}
						if(ruform.AutoMarketingTaskConfigMessageList&&ruform.AutoMarketingTaskConfigMessageList.length){
							ruform.AutoMarketingTaskConfigMessageList = ruform.AutoMarketingTaskConfigMessageList.map((v)=>{
								v.AddTime = ''
								v.UpdateTime = ''
								return v
							})
						}
						if(ruform.AutoMarketingTaskConfigPushRuleEvent){
							ruform.AutoMarketingTaskConfigPushRuleEvent.AddTime = ''
							ruform.AutoMarketingTaskConfigPushRuleEvent.UpdateTime = ''
							let EventRelationIdList = ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList
							if(EventRelationIdList&&EventRelationIdList.length){
								ruform.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList = EventRelationIdList.map((v)=>{
									v.AddTime = ''
									v.UpdateTime = ''
									return v
								})
							}
						}
						this.disabled = false
					}else{
						if(ruform.State == 1 || ruform.State == 2){//进行 结束无法编辑
							this.disabled = true
						}else{
							this.disabled = false
						}
					}
					ruform.EndTime = this.reduceendtime(ruform.EndTime)
					daterange = [ruform.StartTime,ruform.EndTime]
					// console.log(ruform.EndTime)
					this.daterange = daterange

					this.ruform = ruform
					// console.log(this.ruform.AutoMarketingTaskConfigDetailList)
					// console.log(this.daterange,'infodaterange')
					// console.log(this.ruform,'info')
				}finally{
					this.pageloading=false
				}
			},
			reduceendtime(time){
				if(time){
					let newtime = new Date(new Date(time).getTime()-1000*60*60*24)
					return this.changetimetype(newtime)
				}else{
					return ''
				}
			},
			getoday(type){
				let num = 0
				if(type==1){
					num = 31
				}else if(type==2){
					num=60
				}


				if(this.$route.query.templateId==21){
					num=60
				}
				// console.log(type,num)
				// console.log(num)
				let date = new Date()
				let StartTime = this.changetimetype(date)
				// console.log(new Date(date.getTime()+num*60*60*24*1000))
				let EndTime = this.changetimetype(new Date(date.getTime()+num*60*60*24*1000))
				return {
					StartTime,
					EndTime
				}
			},

			changetimetype(t){
				if(t){
					let time = new Date(t)
					let year = time.getFullYear()
					let month = time.getMonth()+1>=10?time.getMonth()+1:('0'+(time.getMonth()+1))
					let day = time.getDate()>=10?time.getDate():('0'+time.getDate())
					let hour = time.getHours()>=10?time.getHours():('0'+time.getHours())
					let min = time.getMinutes()>=10?time.getMinutes():('0'+time.getMinutes())
					let sec = time.getSeconds()>=10?time.getSeconds():('0'+time.getSeconds())
					// console.log(year+'-'+month+'-'+day)
					return year+'-'+month+'-'+day +' '+hour+':'+min+':'+sec
				}
			},

			gettemplateIdmsg(e){
				let detail = marketcompon.find((v)=>{
					return v.templateId == e
				})
				let msg = detail.data
				// console.log(msg,'123')
				msg.StartTime = ''
				msg.EndTime = ''
				msg.Id = 0
				if(msg.TargetMemberType==1){
					msg.AutoMarketingTaskConfigDetailList = [{
						Type: '',
						IsMatchAll: false,
						StartTime:'',
						EndTime: '',
						AutoMarketingTaskConfigDetailRelationIdList: [{
							AutoMarketingTaskConfigId: 0,
							AutoMarketingTaskConfigDetailId: 0,
							RelationId: '',
							RelationValue: '',
						}]
					}]
					msg = this.changemsg(msg)
					this.ruform = msg
				}else if(msg.TargetMemberType==6){
					if(msg.AutoMarketingTaskConfigDetailList&&msg.AutoMarketingTaskConfigDetailList.length){
						msg.AutoMarketingTaskConfigDetailList = msg.AutoMarketingTaskConfigDetailList.map((v)=>{
							v.TagRuleConfigId = v.AutoMarketingTaskConfigId
              v.TagRuleConfigDetailRelationIdList = v.AutoMarketingTaskConfigDetailRelationIdList
							// console.log(v.TagRuleConfigDetailRelationIdList)
							
							if([15,201,301,303].includes(v.Type)){  //第二步关于商品的子内容清空
								v.TagRuleConfigDetailRelationIdList = v.TagRuleConfigDetailRelationIdList.map((x)=>{
									x.RelationId = ''
									return x
								})
							}
							return v
						})
					}
					if(this.$route.query.templateId==10||this.$route.query.templateId==11){//选择模板会员
						msg.AutoMarketingTaskConfigDetailList.map((v)=>{
							if(v.Type==2){
								v.AutoMarketingTaskConfigDetailRelationIdList = this.memberlist.filter((x)=>{
									if(x.Id!=0){
										x.RelationId = x.Id
									}
									return x
								})
							}
							v.TagRuleConfigId = v.AutoMarketingTaskConfigId
							v.TagRuleConfigDetailRelationIdList = v.AutoMarketingTaskConfigDetailRelationIdList
							return v
						})
					}else if(this.$route.query.templateId==17||this.$route.query.templateId==18){//选择模板推手||代理
						let idlist = this.driverlist.map((v)=>{
							return v.Id
						})
						let screening = idlist.filter((x)=>{
							if(this.$route.query.templateId==17){
								if(window.localStorage.getItem('mlmzDistributionModel')==1){
									return x!=0 && x!=Math.min(...idlist)
								}else{
									return x!=0 && x!=Math.max(...idlist)
								}
							}else{
								return x!=0
							}
						})
						msg.AutoMarketingTaskConfigDetailList.map((v)=>{
							if(v.Type==3){
								v.AutoMarketingTaskConfigDetailRelationIdList = screening.map((x)=>{
									let data = {
										RelationId:x
									}
									return data
								})
							}
							v.TagRuleConfigId = v.AutoMarketingTaskConfigId
							v.TagRuleConfigDetailRelationIdList = v.AutoMarketingTaskConfigDetailRelationIdList
							// console.log(v.TagRuleConfigDetailRelationIdList,'55')
							return v
						})
						
					}
					this.getgetTemplateParam().then((res)=>{
						if(this.$route.query.templateId == 7){
							var num = res.PayOrderMemberCount ? +(Math.floor(res.PayOrderCount / res.PayOrderMemberCount)) : 0;
							var num1 = res.PayOrderMemberCount ? +(res.PayOrderMoney / res.PayOrderMemberCount).toFixed(2) : 0;
							num = num===0?'0':num;
							num1 = num1===0?'0':num1;
							msg.AutoMarketingTaskConfigDetailList[2].Num = num;
							msg.AutoMarketingTaskConfigDetailList[1].Num = num1;
						}else if(this.$route.query.templateId == 9){
							var num = res.GrowthValue === 0 ? '0':res.GrowthValue;
							msg.AutoMarketingTaskConfigDetailList[1].Num = res.GrowthValue;

						}else if(this.$route.query.templateId == 17){
							var num = res.CommissionMemberCount ? +(res.CommissionAwardMoney / res.CommissionMemberCount).toFixed(2) : 0;
							num = num===0?'0':num;
							msg.AutoMarketingTaskConfigDetailList[1].Num = num;
						}

						msg = this.changemsg(msg)
						
						this.ruform = msg
						// console.log(this.ruform,'1')
					})
				}else{
					msg = this.changemsg(msg)
					this.ruform = msg
				}
				// this.ruform = msg
			},

			changemsg(msg){
				let daterange = []
				let once = {
					AutoMarketingTaskConfigId:0,
					PushTimeType:2,
				}
				let loop = {
					AutoMarketingTaskConfigId:0,
					PeriodType:1,
					PeriodNum:1,
					PeriodTimeHour:'',
					PeriodTimeMinute:'',
					PushCount:1,
				}
				let event = {
					AutoMarketingTaskConfigId:0,
					TimeType:0,
					Type:1,
					ConditionType:1,
					Num:'',
					MaxNum:'',
					ProductRangeType:0,
					PageRangeType:0,
					PushDelayDay:7,
					AutoMarketingTaskConfigPushRuleEventRelationIdList:[]
				}
				if(msg.PushRuleType==1){
					msg.AutoMarketingTaskConfigPushRuleLoop = loop
					msg.AutoMarketingTaskConfigPushRuleEvent = event
				}
				if(msg.PushRuleType==2){
					msg.AutoMarketingTaskConfigPushRuleOnce = once
					msg.AutoMarketingTaskConfigPushRuleEvent = event
				}
				if(msg.PushRuleType==3){
					msg.AutoMarketingTaskConfigPushRuleOnce = once
					msg.AutoMarketingTaskConfigPushRuleLoop = loop
					if([3,4,12,14].includes(msg.AutoMarketingTaskConfigPushRuleEvent.Type)){//第三步关于商品的子内容清空
						msg.AutoMarketingTaskConfigPushRuleEvent.AutoMarketingTaskConfigPushRuleEventRelationIdList = []
					}
				}
				msg.AutoMarketingTaskConfigGiftList = []
				
				msg.StartTime = this.getoday(msg.AutoMarketingTaskConfigPushRuleLoop.PeriodType).StartTime
				msg.EndTime = this.getoday(msg.AutoMarketingTaskConfigPushRuleLoop.PeriodType).EndTime
				// console.log(msg.AutoMarketingTaskConfigPushRuleLoop.PeriodType)
				daterange = [msg.StartTime,msg.EndTime]
				this.daterange = daterange


				return msg
			},
			getgetTemplateParam(){
				return new Promise(async (res,rej)=>{
					try{
						let type = 0
						if(this.$route.query.templateId == 7){
							type = 1
						}else if(this.$route.query.templateId == 9){
							type = 2
						}else if(this.$route.query.templateId == 17){
							type = 3
						}
						// console.log(type)
						let result = await getTemplateParam({
							TemplateType:type
						})
						res(result)
					}catch{
						rej()
					}
					
				})  
			},
		}
	}
</script>

<style lang = "less" scoped>
	/* .nextbtn{
		border-color: #DCDFE6;
		color: #606266;
		background: #FFF;
	}
	.lastbtn{
		border-color: #409eeF;
		background: #409eeF;
		color:#FFF;
	} */
</style>
<style lang="less" scoped>
	.flexRow {
		display: flex;
		flex-direction: row;
		align-items: center;
		/* justify-content: space-between; */
	}

	.flexCol {
		display: flex;
		flex-direction: column;
		/* align-items: center; */
		/* justify-content: center; */
	}

	.toproundBox {
		/* border-color: #409eff; */
		/* color: #409eff; */
		line-height: 1;
		white-space: nowrap;
		background: #FFF;
		border: 1px solid #DCDFE6;
		color: #303133;
		padding: 10px 20px;
		font-size: 14px;
		cursor: pointer;
		border-radius: 35px;

		span {
			font-size: 12px;
			color: #999999;
			margin-left: 10px
		}
	}

	.stepnow {
		color: #409eff;
		border-color: #409eff;
	}

	.linearrowbox {
		flex: 1;
		margin: 0px 5px;

		.dottedline {
			border: 1px dashed #999999;
			border-top: none;
			border-left: none;
			border-right: none;
			width: 100%;
		}

		.rigntarrow {
			border: 5px solid transparent;
			border-left-color: #999999;
			border-right: none;
			transform: scaleX(2);
			margin: 0px 2px
		}
	}

	.grayfont {
		font-size: 12px;
		color: #999999;
		line-height: 1.5;
	}

	.targetfont {
		color: #606266;
	}

	.bottomBtn {
		position: fixed;
		left: 220px;
		right: 0px;
		bottom: 0px;
		height: 80px;
	}

	@media screen and (max-width:1280px) {
		.bottomBtn {
			left: 140px
		}
	}

	.bluebeginning {
		width: 5px;
		height: 20px;
		background: #409eff;
		border-radius: 30px;
	}

	.graybox {
		border: 1px solid #DCDFE6;
		width: 49%;
		padding: 20px;
		display: flex;
		flex-direction: row;
		align-items: flex-start;

		.titfont {
			font-size: 16px;
			color: #303133;
			margin: 0px 10px
		}

		.configurationbox {
			border: 1px dashed #EBEEF5;
			margin: 15px 0px 0px 10px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 30px 0px;
			cursor: pointer;

			.iconsty {
				font-weight: bold;
				font-size: 30px;
				color: #409eff;
			}

			.iconfont {
				font-size: 16px;
				color: #606266;
				margin-top: 20px;
			}
		}

	}

	.haveqywxhtml {
		border: 1px solid #EBEEF5;
		margin: 15px 0px 0px 10px;
		background: #FBFDFF;
	}

	.vertical3 {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		overflow: hidden;
	}

	.confont {
		font-size: 14px;
		color: #303133;
	}

	.haveqywxlist {
		border-top: 1px solid #EBEEF5;
		background: #FBFDFF;
		padding: 10px 5px;
		font-size: 14px;
		color: #303133;
	}

	.confontsmshtml {
		border-top: 1px solid #EBEEF5;
		background: #FBFDFF;
		padding: 10px 5px;
		font-size: 14px;
		color: #303133;
	}

	.tag-wraper {
		height: 600px;
		display: flex;
		margin-left: 70px;
	}
</style>
